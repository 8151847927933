/* crit:start */
.u {
    // Background image reset
    // no-repeat, centered, and cover size
    &-bgimg {
		@include bgimg-with-ratio;
		page-break-inside: avoid;

		&__backup {
			@media print {
				opacity: 1;
			}
		}
    }

    // Same embed responsive as Boostrap's embed-responsive, but with BEM style syntax
    &-embed {
        position: relative;
        display: block;
        height: 0;
        padding: 0;
        overflow: hidden;
        padding-bottom: 100%; //by default, it's a square

        &__item,
          iframe,
          embed,
          object,
          video {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border: 0;
        }

        &--16by9{
            padding-bottom: 56.25%;
        }
        &--4by3{
            padding-bottom: 75%;
        }
	}

	// {{ .u-table-responsive }}
    &-table-responsive {
    	@each $breakpoint in map-keys($grid-breakpoints) {
    		$next: breakpoint-next($breakpoint, $grid-breakpoints);
    		$infix: breakpoint-infix($next, $grid-breakpoints);
    		&#{$infix} {
    			@include media-breakpoint-down($breakpoint) {
    				display: block;
    				width: 100%;
    				overflow-x: auto;
    				-webkit-overflow-scrolling: touch;
    				-ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
    				// Prevent double border on horizontal scroll due to use of `display: block;`
    				>.table-bordered {
    					border: 0;
    				}
    			}
    		}
    	}
    }

	&-vertical-center {
        @include vertical-center;
        /* crit */
    }
    &-absolute-center {
        @include absolute-center;
        /* crit */
    }
    &-horizontal-center {
        @include horizontal-center;
        /* crit */
    }

    // Ellipsis
    &-ellipsis {
        @include ellipsis;
    }

    // Collapse
    // This will show the collapsed element if it's collapsed from Boostrap class
    &-collapse {
        &-xsmax {
            @include mq(sm) {
                display: block;
                &-inline-block {
                    display: inline-block;
                }
            }
        }
        &-smmax {
            @include mq(md) {
                display: block;
                &-inline-block {
                    display: inline-block;
                }
            }
        }
        &-mdmax {
            @include mq(lg) {
                display: block;
                &-inline-block {
                    display: inline-block;
                }
            }
        }
    }


    &-b2{
        border:2px solid $grey-800;
        &-orange{
            border:2px solid $orange !important;
        }
    }
    &-bt2{
        border-top:2px solid $grey-800;
        &-orange{
            border-top:2px solid $orange !important;
        }
    }
}
/* crit:end */
