//baseline (from grid line height)
$baseline: 6px; 

$green: #99c81e;
$darkblue: #0d2c54;
$orange: #f47f15;
$red: #e4102a;

$black:#2c2c2c;
$text : #444444;

//grey
$grey-900: #2c2c2c;
$grey-800: #444444;
$grey-500: #737373;
$grey-300: #cacaca;
$grey-200: #dedede;
$grey-150: #eeeeee;
$grey-100: #f4f4f4;
$grey-50: #fafafa;


$primary: $green;
$secondary: $orange;
$white: #ffffff;
$border: #eeeeee;
