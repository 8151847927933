/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

*:focus{
	outline: 0;
}

/* crit:start */
*,
*:before,
*:after {
	flex: 0 1 auto;
}

html{
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: scrollbar;
}

body{
	min-height: 100vh;
	color: $black;
	font-family: "Roboto", Arial, sans-serif;
}

fieldset{
	margin: 0;
	padding: 0;
	border: 0;
}

input{
	@include fzlh(15px, 24px);
	border-radius: 0;
	/* crit */

	&[type=search],
	&[type=text],
	&[type=password],
	&[type=tel],
	&[type=number],
	&[type=email] {
		box-sizing: border-box;
		width: 100%;
		padding: 5px 10px;
		border: 1px solid $grey-200;
		min-height: 36px;
	}

	&:invalid {
		// remove red border on invalid input on mozilla
		box-shadow: none;
		/* crit */
	}

	&::placeholder {
		color: rgba($text, .8);
		/* crit */
	}

	&.error {
		border-color: red;
	}

	&::-ms-clear {
		display: none;
	}
}

table {
	width: 100%;

	td, th{
		padding: 15px;

		&:first-child{
			padding-left: 0;
		}
	}

	th{
		color: $text;
		text-align: left;
	}

	tr {
		border-bottom: 1px solid $border;
	}

	thead {
		tr{
			border-bottom: 3px solid $border;
		}
	}
}



select, textarea{
	// Prevent ios default box shadow and radius
	appearance: none;
	border-radius: 0;
	display: block;
	padding: 11px 18px;
	width: 100%;
	background-color: #fff;
	border: 1px solid $border;

	&:disabled{
		opacity: .5;
	}

	&:focus{
		box-shadow: 0 0 10px 0 rgba(204, 218, 225, 0.7);
		background-color: #fff;
	}
}

label{
	//@include fssmall;
	font-weight: 700;
}

select{
	border-radius: 0;
	background: #fff url('/www_shared/assets/img/chev-down.svg') calc(100% - 5px) 50% no-repeat;
	background-size: 24px auto;
	padding:5px 10px;
	border-color: #dedede;
	&::-ms-expand {
		display: none;
	}
}

textarea{
	overflow: auto;
	resize: none;
	resize: vertical;
}

img{
	max-width: 100%;
	height: auto;
}

main{
	padding-top: 50px;
	padding-bottom: 50px;
}
/* crit:end */
