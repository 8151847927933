.o-btn {
	position: relative;
	border-radius: 2px;
	padding: .375rem 1rem;
	font-weight: 700;
	/* crit */
	transition: .3s;

	&:hover,
	&:focus {
		color: #fff;
		&:before {
			transform: none;
			opacity: 1;
		}
	}
	+ .o-btn{
		margin-left: 8px;
	}
	
	[class*=" g-icon-"], 
	[class^=g-icon-]{
		margin-right: 5px;
		vertical-align: middle;
		font-size: 18px;
		margin-top: -2px;
	}

	&--green {
		background-color: $green;
		color: $black;
		border:0;
		&:before {
			background-color: darken($green, 10%);
		}
		&:hover,
		&:focus {
			background-color: darken($green, 10%);
			color: $black;
		}
	}
	&--white{
		background-color: $white;
		color: $black;
		border: 1px solid $grey-200;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: $primary;
			text-decoration: none;
			border-color: $primary;
			color: $black;
		}	
	}

	&--blue{
		background-color: $darkblue;
		color: $white;
		border:0;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: darken($darkblue, 10%);
			text-decoration: none;
			border-color: $primary;
			color: $white;
		}	
	}

	&--orange {
		background-color: $orange;
		color: $black;
		border:0;
		&:before {
			background-color: darken($orange, 10%);
		}
		&:hover,
		&:focus {
			background-color: darken($orange, 10%);
			color: $black;
		}
	}

	&--red{
		background-color: $red;
		color: $white;
		border:0;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: darken($red, 10%);
			text-decoration: none; 
			color: $white;
		}	
	}

	&--black{
		background-color: $black;
		color: $white;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: $primary;
			text-decoration: none;
			color: $white;
		}	
	}

	&--grey{
		background-color: $grey-100;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: darken($grey-100, 10%);
			text-decoration: none;
			color: $black;
		}	
	}

	&--flat{
		background-color: $white;
		color: $grey-500;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: $primary;
			text-decoration: none;
			color: $grey-500;
		}	
	}
	&--border{
		background-color: transparent;
		color: $black;
		border: 1px solid $grey-200;
		border-radius: 2px;
		&:hover,
		&:focus {
			background-color: $primary;
			text-decoration: none;
			border-color: $primary;
			color: $black;
		}	
	}

	&--icon{
		width: 40px;
		height: 40px;
		font-size: 20px;
		padding:0;
		color: $grey-500;
		span{ line-height:40px; }
		[class*=" g-icon-"], 
		[class^=g-icon-]{
			margin-right: 0;
		}
		&small{
			width: 32px;
			height: 32px;
			font-size: 18px;
			padding:0;
			color: $grey-500;
			span{ line-height:32px; }
			[class*=" g-icon-"], 
			[class^=g-icon-]{
				margin-right: 0;
			}
		}
		
	}

	&--small{
		padding: 0 4px; 
		span{
			font-size: 20px;
			line-height:0;
		}
	}
	&--circle{
		border-radius: 100px;

	}



}
