/* crit:start */

.u {
	// Border
	@mixin u-bd($abbrev, $property) {
		&-#{$abbrev}{
			#{$property}: 1px solid $border;
			&0 {
				#{$property}: 0;
			}
		}
	}
	@each $prop,$abbrev  in (border:bd, border-top:bdt, border-left:bdl, border-right:bdr, border-bottom:bdb){
		@include u-bd($abbrev, $prop);
	}

	// Border Radius
	&-bdra {
		&0 {
			border-radius: 0;
		}
		&50p {
			border-radius: 50%;
		}
	}

	// Link Text Decoration None on Hover and Focus
	&-link-tdn {
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	// {{ .u-link-colorstay }}
	&-link-colorstay {
		color: inherit;
		&:hover,
		&:focus {
			color: inherit;
		}
	}

	// Margin and Padding
	@each $breakpoint in map-keys($grid-breakpoints) {
		@include mq-up($breakpoint) {
			$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
			@each $prop, $abbrev in (margin: m, padding: p) {
				@each $size, $length in $spacers {
					@if($length == 0){
						&-#{$abbrev}#{$size}#{$infix} {
							#{$prop}: $length;
						}
					}
					&-#{$abbrev}tb#{$size}#{$infix}{
						#{$prop}-top: $length;
					}
					&-#{$abbrev}yb#{$size}#{$infix} {
						#{$prop}-top: $length;
					}
					&-#{$abbrev}rb#{$size}#{$infix}{
						#{$prop}-right: $length;
					}
					&-#{$abbrev}xb#{$size}#{$infix} {
						#{$prop}-right: $length;
					}
					&-#{$abbrev}bb#{$size}#{$infix}{
						#{$prop}-bottom: $length;
					}
					&-#{$abbrev}yb#{$size}#{$infix} {
						#{$prop}-bottom: $length;
					}
					&-#{$abbrev}lb#{$size}#{$infix}{
						#{$prop}-left: $length;
					}
					&-#{$abbrev}xb#{$size}#{$infix} {
						#{$prop}-left: $length;
					}
				}
			}

			// Some special margin utils
			// &-ma#{$infix} {
			// 	margin: auto;
			// }
			// &-mta#{$infix}{
			// 	margin-top: auto;
			// }
			// &-mya#{$infix} {
			// 	margin-top: auto;
			// }
			&-mra#{$infix}{
				margin-right: auto;
			}
			&-mxa#{$infix} {
				margin-right: auto;
			}
			// &-mba#{$infix}{
			// 	margin-bottom: auto;
			// }
			// &-mya#{$infix} {
			// 	margin-bottom: auto;
			// }
			&-mla#{$infix}{
				margin-left: auto;
			}
			&-mxa#{$infix} {
				margin-left: auto;
			}
		}
	}

	// Height
	&-h100 {
		&p {
			height: 100%;
		}
		&vh {
			height: 100vh;
		}
	} // Width 100%

	&-w100 {
		&p {
			width: 100%;
		}
	}

	&-maw100 {
		&p{
			max-width: 100%;
		}
	}

	&-mah100 {
		&p {
			max-height: 100%;
		}
		&vh {
			max-height: 100vh;
		}
	}

	&-mih100{
		&vh{
			min-height: 100vh;
		}
	}

	// Background color
	&-bgc {
		&-t {
			background-color: transparent;
		}
		&-orange{
			background-color: lighten($orange, 42%);
		}
	}

	// Overflow
	&-ov {
		&h {
			overflow: hidden;
		}
		&a {
			overflow: auto;
		}
	}

	// Display
	&-d {
		&tb {
			&--fixed {
				width: 100%;
				table-layout: fixed;
			}
		}

		@each $breakpoint in map-keys($grid-breakpoints) {
			@include mq-up($breakpoint) {
				$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
				&n#{$infix} {
					display: none;
				}
				&i#{$infix} {
					display: inline;
				}
				&ib#{$infix} {
					display: inline-block;
				}
				&b#{$infix} {
					display: block;
				}
				&tb#{$infix} {
					display: table;
				}
				&tr#{$infix} {
					display: table-row;
				}
				&tc#{$infix} {
					display: table-cell;
				}
				&f#{$infix} {
					display: flex;
				}
				&if#{$infix} {
					display: inline-flex;
				}
				&g#{$infix} {
					display: grid;
				}
			}
		}
	}

	// Vertical Align
	&-va {
		&t {
			vertical-align: top;
		}
		&m {
			vertical-align: middle;
		}
		&b {
			vertical-align: bottom;
		}
	}

	// FLoat
	&-fl {
		&l {
			float: left;
		}
		&r {
			float: right;
		}
		&n {
			float: none;
		}
	}

	// Text align
	&-ta {
		&r {
			text-align: right;
		}
		&c {
			text-align: center;
		}
		&l {
			text-align: left;
		}
	}
	//-text-small
	&-text-small{
		font-size: 14px;
	}

	// Line Height
	&-lh {
		&1 {
			line-height: 1;
		}
	}

	// Position
	&-pos {
		&a {
			&0 {
				position: absolute 0;
			}
		}
		&f {
			&0 {
				position: fixed 0;
			}
		}

		@each $breakpoint in map-keys($grid-breakpoints) {
			@include mq-up($breakpoint) {
				$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

				@each $val, $abbrev in (static: s, relative: r, absolute:a, fixed: f){
					&#{$abbrev}#{$infix}{
						position: $val !important;
					}
				}
			}
		}

	}

	// Positioning
	@each $prop, $abbrev in (left: l, right: r, top: t, bottom: b){
		&-#{$abbrev}0 {
			#{$prop}: 0;
		}

		&-#{$abbrev}a {
			#{$prop}: auto;
		}
	}

	// Font Weight
	&-fw {
		@each $weight in (300, 400, 700) {
			&#{$weight} {
				font-weight: $weight;
			}
		}
	}

	// Font Style
	&-fs {
		&i {
			font-style: italic;
		}
	}

	// Text Transfrom
	&-tt {
		&u {
			text-transform: uppercase;
		}
		&c {
			text-transform: capitalize;
		}
	}

	// Flex
	&-fx {
		&1 {
			flex: 1 1 auto;
		}
		&g{
			&0{
				flex-grow: 0;
			}
			&1{
				flex-grow: 1;
			}
		}
		&s{
			&0 {
				flex-shrink: 0;
			}

			&1 {
				flex-shrink: 1;
			}
		}
		&b{
			&0{
				flex-basis: 0%;
			}
			&a{
				flex-basis: auto;
			}
		}
		&dc {
			flex-direction: column;
		}
		&ww {
			flex-wrap: wrap;
		}
	}

	&-ai {
		&b {
			align-items: baseline;
		}
	}

	// Justify Content
	&-jc {
		&sb {
			justify-content: space-between;
		}
		&fe {
			justify-content: flex-end;
		}
	}

	// Z Index
	&-z {
		@for $i from -1 through 5 {
			&#{$i} {
				z-index: $i;
			}
		}
	}

	// Opacity
	&-o {
		&0 {
			opacity: 0;
		}
		&1 {
			opacity: 1;
		}
	}

	@each $c,
	$hex in $bv-colors {
		// Background color
		&-bgc-#{$c} {
			background-color: $hex;
		} // Color
		&-c-#{$c} {
			color: $hex;
		} // Border color
		&-bdc-#{$c} {
			border-color: $hex;
		}
	}

	&-c-inherit {
		color: inherit;
	}
}

/* crit:end */
