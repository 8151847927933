.gj-button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    color: #000;
    border-radius: 3px;
    padding: 6px 10px;
    cursor: pointer;
}

.gj-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gj-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.gj-margin-left-5 {
    margin-left: 5px;
}

.gj-margin-left-10 {
    margin-left: 10px;
}

.gj-width-full {
    width: 100%;
}

.gj-cursor-pointer {
    cursor: pointer;
}

.gj-text-align-center {
    text-align: center;
}

.gj-font-size-16 {
    font-size: 16px;
}

.gj-hidden {
    display: none;
}

/** Material Design */
.gj-button-md {
    background: 0 0;
    border: none;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    height: 36px;
    margin: 0;
    min-width: 64px;
    padding: 0 16px;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gj-button-md:hover {
    background-color: rgba(158,158,158,.2);
}

.gj-button-md:disabled {
    color: rgba(0,0,0,.26);
    background: 0 0;
}

.gj-button-md .material-icons,
.gj-button-md .gj-icon {
    vertical-align: middle;
    /*font-size: 1.3rem;
    margin-right: 4px;*/
}

.gj-button-md.gj-button-md-icon {
    width: 24px;
    height: 31px;
    min-width: 24px;
    padding: 0px;
    display: table;
}

.gj-button-md.gj-button-md-icon .material-icons,
.gj-button-md.gj-button-md-icon .gj-icon {
    display: table-cell;
    margin-right: 0px;
    width: 24px;
    height: 24px;
}

.gj-button-md.active {
    background-color: rgba(158,158,158,.4);
}

.gj-button-md-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.gj-textbox-md {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.42);
    display: block;
    font-size: 16px;
    line-height: 16px;
    padding: 4px 0px;
    margin: 0;
    width: 100%;
    background: 0 0;
    text-align: left;
    color: rgba(0,0,0,.87);
}

.gj-textbox-md:focus,
.gj-textbox-md:active {
    border-bottom: 2px solid rgba(0,0,0,.42);
    outline: none;
}

.gj-textbox-md::placeholder {
    color: #8e8e8e;
}

.gj-textbox-md:-ms-input-placeholder {
    color: #8e8e8e;
}

.gj-textbox-md::-ms-input-placeholder {
    color: #8e8e8e;
}

.gj-md-spacer-24 {
    min-width: 24px;
    width: 24px;
    display: inline-block;
}

.gj-md-spacer-32 {
    min-width: 32px;
    width: 32px;
    display: inline-block;
}

.gj-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.54118);
    transition: 200ms ease opacity;
    will-change: opacity;
}

/* List */
ul.gj-list li [data-role="wrapper"] {
    display: table;
    width: 100%;
}

ul.gj-list li [data-role="checkbox"] {
    display: table-cell;
    vertical-align:middle;
    text-align:center;
}

ul.gj-list li [data-role="image"] {
    display: table-cell;
    vertical-align:middle;
    text-align:center;
}

ul.gj-list li [data-role="display"] {
    display: table-cell;
    vertical-align:middle;
    cursor: pointer;
}

ul.gj-list li [data-role="display"]:empty:before {
  content: "\200b"; /* unicode zero width space character */
}

/* List - Bootstrap */
ul.gj-list-bootstrap {
    padding-left: 0px;
    margin-bottom: 0px;
}

ul.gj-list-bootstrap li {
    padding: 0px;
}

ul.gj-list-bootstrap li [data-role="wrapper"] {
    padding: 0px 10px;
}

ul.gj-list-bootstrap li [data-role="checkbox"] {
    width: 24px;
    padding: 3px;
}

ul.gj-list-bootstrap li [data-role="image"] {
    width: 24px;
    height: 24px;
}

ul.gj-list-bootstrap li [data-role="display"] {
    padding: 8px 0px 8px 4px;
}

.list-group-item.active ul li, .list-group-item.active:focus ul li, .list-group-item.active:hover ul li {
    text-shadow: none;
    color:initial;
}

/* List - Material Design */
ul.gj-list-md {
    padding: 0px;
    list-style: none;
    list-style-type: none;
    line-height: 24px;
    letter-spacing: 0;
    color: #616161; /* Gray 700 */
}

ul.gj-list-md li {
    display: list-item;
    list-style-type: none;
    padding: 0px;
    min-height: unset;
    box-sizing: border-box;
    align-items: center;
    cursor: default;
    overflow: hidden;

    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

ul.gj-list-md li [data-role="checkbox"] {
    height: 24px;
    width: 24px;
}

ul.gj-list-md li [data-role="image"] {
    height: 24px;
    width: 24px;
}

ul.gj-list-md li [data-role="display"] {
    padding: 8px 0px 8px 5px;
    order: 0;
    flex-grow: 2;
    text-decoration: none;
    box-sizing: border-box;
    align-items: center;
    text-align: left;
    color: rgba(0,0,0,.87);
}

ul.gj-list-md li.disabled>[data-role="wrapper"]>[data-role="display"] {
    color: #9E9E9E; /* Gray 500 */
}

.gj-list-md-active {
    /* background: #E8EAF6; */
    background: rgba(0,0,0,.12);
    color: #3f51b5;
}


/* Picker */
.gj-picker {
    position: absolute;
    z-index: 1203;
    background-color: #fff;
}

.gj-picker .selected {
    color: #fff;
}

/* Material Design */
.gj-picker-md {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    color: rgba(0,0,0,.87);
    border: 1px solid #E0E0E0;
}

.gj-modal .gj-picker-md {
    border: 0px;
}

.gj-picker-md [role="header"] {
    color: rgba(255, 255, 255, 0.54);
    display: flex;
    background: #2196f3;
    align-items: baseline;
    user-select: none;
    justify-content: center;
}

.gj-picker-md [role="footer"] {
    float: right;
    padding: 10px;
}

.gj-picker-md [role="footer"] button.gj-button-md {
    color: #2196f3;
    font-weight: bold;
    font-size: 13px;
}

/* Bootstrap */
.gj-picker-bootstrap {
    border-radius: 4px;
    border: 1px solid #E0E0E0;
}

.gj-picker-bootstrap .selected {
    color: #888;
}

.gj-picker-bootstrap [role="header"] {
    background: #eee;
    color: #AAA;
}

@font-face {
    font-family: 'gijgo-material';
    src: url('../fonts/gijgo-material.eot?235541');
    src: url('../fonts/gijgo-material.eot?235541#iefix') format('embedded-opentype'), url('../fonts/gijgo-material.ttf?235541') format('truetype'), url('../fonts/gijgo-material.woff?235541') format('woff'), url('../fonts/gijgo-material.svg?235541#gijgo-material') format('svg');
    font-weight: normal;
    font-style: normal;
}

.gj-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'gijgo-material' !important;
    font-size: 24px;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gj-icon.undo:before {
    content: "\e900";
}

.gj-icon.vertical-align-top:before {
    content: "\e901";
}

.gj-icon.vertical-align-center:before {
    content: "\e902";
}

.gj-icon.vertical-align-bottom:before {
    content: "\e903";
}

.gj-icon.arrow-dropup:before {
    content: "\e904";
}

.gj-icon.clock:before {
    content: "\e905";
}

.gj-icon.refresh:before {
    content: "\e906";
}

.gj-icon.last-page:before {
    content: "\e907";
}

.gj-icon.first-page:before {
    content: "\e908";
}

.gj-icon.cancel:before {
    content: "\e909";
}

.gj-icon.clear:before {
    content: "\e90a";
}

.gj-icon.check-circle:before {
    content: "\e90b";
}

.gj-icon.delete:before {
    content: "\e90c";
}

.gj-icon.arrow-upward:before {
    content: "\e90d";
}

.gj-icon.arrow-forward:before {
    content: "\e90e";
}

.gj-icon.arrow-downward:before {
    content: "\e90f";
}

.gj-icon.arrow-back:before {
    content: "\e910";
}

.gj-icon.list-numbered:before {
    content: "\e911";
}

.gj-icon.list-bulleted:before {
    content: "\e912";
}

.gj-icon.indent-increase:before {
    content: "\e913";
}

.gj-icon.indent-decrease:before {
    content: "\e914";
}

.gj-icon.redo:before {
    content: "\e915";
}

.gj-icon.align-right:before {
    content: "\e916";
}

.gj-icon.align-left:before {
    content: "\e917";
}

.gj-icon.align-justify:before {
    content: "\e918";
}

.gj-icon.align-center:before {
    content: "\e919";
}

.gj-icon.strikethrough:before {
    content: "\e91a";
}

.gj-icon.italic:before {
    content: "\e91b";
}

.gj-icon.underlined:before {
    content: "\e91c";
}

.gj-icon.bold:before {
    content: "\e91d";
}

.gj-icon.arrow-dropdown:before {
    content: "\e91e";
}

.gj-icon.done:before {
    content: "\e91f";
}

.gj-icon.pencil:before {
    content: "\e920";
}

.gj-icon.minus:before {
    content: "\e921";
}

.gj-icon.plus:before {
    content: "\e922";
}

.gj-icon.chevron-up:before {
    content: "\e923";
}

.gj-icon.chevron-right:before {
    content: "\e924";
}

.gj-icon.chevron-down:before {
    content: "\e925";
}

.gj-icon.chevron-left:before {
    content: "\e926";
}

.gj-icon.event:before {
    content: "\e927";
}
.gj-draggable {
    cursor: move;
}
.gj-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    -ms-touch-action: none;
    touch-action: none;
    z-index: 1203;
}

.gj-resizable-n {
    cursor: n-resize;
    height: 7px;
    width: 100%;
    top: -5px;
    left: 0;
}
.gj-resizable-e {
    cursor: e-resize;
    width: 7px;
    right: -5px;
    top: 0;
    height: 100%;
}
.gj-resizable-s {
    cursor: s-resize;
    height: 7px;
    width: 100%;
    bottom: -5px;
    left: 0;
}
.gj-resizable-w {
	cursor: w-resize;
	width: 7px;
	left: -5px;
	top: 0;
	height: 100%;
}
.gj-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px;
}
.gj-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	bottom: -5px;
}
.gj-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	left: -5px;
	top: -5px;
}
.gj-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	right: -5px;
	top: -5px;
}

.gj-dialog-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-top: 0px;
}

.gj-dialog-scrollable [data-role="body"] {
    overflow-x: hidden;
    overflow-y: scroll;
}

/** Bootstrap 3 **/
.gj-dialog-bootstrap {
    overflow: hidden;
    z-index: 1202;
}

.gj-dialog-bootstrap [data-role="title"] {
    display: inline;
}
.gj-dialog-bootstrap [data-role="close"] {
    line-height: 1.42857143;
}

/** Bootstrap 4 **/
.gj-dialog-bootstrap4 {
    overflow: hidden;
    z-index: 1202;
}

.gj-dialog-bootstrap4 [data-role="title"] {
    display: inline;
}
.gj-dialog-bootstrap4 [data-role="close"] {
    line-height: 1.5;
}

/** Material Design **/
.gj-dialog-md {
    background-color: #FFF;
    overflow: hidden;
    border: none;
    box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
    box-sizing: border-box;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: 0;
    z-index: 1202;
}

.gj-dialog-md-header {
    padding: 24px 24px 0px 24px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.gj-dialog-md-title {
    margin: 0;
    font-weight: 400;
    display: inline;
    line-height: 28px;
    font-size: 20px;
}

.gj-dialog-md-close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    float: right;
    line-height: 28px;
    font-size: 28px;
}

.gj-dialog-md-body {
    padding: 20px 24px 24px 24px;
    color: rgba(0,0,0,.54);
    font-family: "Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.gj-dialog-md-footer {
    padding: 8px 8px 8px 24px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    box-sizing: border-box;
}

.gj-dialog-md-footer>*:first-child {
    margin-right: 0;
}

.gj-dialog-md-footer>* {
    margin-right: 8px;
    height: 36px;
}
DIV.gj-grid-wrapper {
    margin: auto;
    position: relative;
    clear:both;
    z-index: 1;
}

TABLE.gj-grid {
    margin: auto;    
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

TABLE.gj-grid THEAD TH [data-role="selectAll"] {
    margin: auto;
}

TABLE.gj-grid THEAD TH [data-role="title"] {
    display: inline-block;
}

TABLE.gj-grid THEAD TH [data-role="sorticon"] {
    display: inline-block;
}

TABLE.gj-grid THEAD TH {
    overflow: hidden;
    text-overflow: ellipsis;
}

TABLE.gj-grid.autogrow-header-row THEAD TH {
    overflow: auto;
    text-overflow: initial;
    white-space: pre-wrap;
    -ms-word-break: break-word;
    word-break: break-word;
}

TABLE.gj-grid > tbody > tr > td {
    overflow: hidden;
    position: relative;
}

table.gj-grid tbody div[data-role="display"] {
    vertical-align: middle;
    text-indent: 0;
    white-space: pre-wrap;
    -ms-word-break: break-word;
    word-break: break-word;
}

table.gj-grid.fixed-body-rows tbody div[data-role="display"] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -ms-word-break: initial;
    word-break: initial;
}

table.gj-grid tfoot DIV[data-role="display"] {
    vertical-align: middle;
    text-indent: 0;
    display: flex;
}

TABLE.gj-grid .fa {
    padding: 2px;
}

TABLE.gj-grid > tbody > tr > td > div {
    padding: 2px;
    overflow: hidden;
}

DIV.gj-grid-wrapper DIV.gj-grid-loading-cover
{
    background: #BBBBBB;
    opacity: 0.5;
    position: absolute;
    vertical-align: middle;
}

DIV.gj-grid-wrapper DIV.gj-grid-loading-text
{
    position: absolute;
    font-weight: bold;
}

/* Bootstrap Theme */
table.gj-grid-bootstrap thead th {
    background-color: #f5f5f5;
    vertical-align:middle;
}

table.gj-grid-bootstrap thead th [data-role="sorticon"] {
    margin-left: 5px;
}

table.gj-grid-bootstrap thead th [data-role="sorticon"] i.gj-icon,
table.gj-grid-bootstrap thead th [data-role="sorticon"] i.material-icons {
    position: absolute;
    font-size: 20px;
    top: 15px;
}

table.gj-grid-bootstrap tbody tr td div[data-role="display"] {
    padding: 0px;
}

.gj-grid-bootstrap-4 .gj-checkbox-bootstrap {
    display: inline-block;
    padding-top: 2px;
}

/* Material Design Theme */
.gj-grid-md {
    position: relative;
    border: 1px solid rgba(0,0,0,.12);
    border-collapse: collapse;
    white-space: nowrap;
    font-size: 13px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    background-color: #fff;
}

.gj-grid-md td:first-of-type, .gj-grid-md th:first-of-type {
    padding-left: 24px;
}

.gj-grid-md th {
    position: relative;
    vertical-align: bottom;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0;
    height: 56px;
    font-size: 12px;
    color: rgba(0,0,0,.54);
    padding-bottom: 8px;
    box-sizing: border-box;
    padding: 12px 18px;
    text-align: right;
}

.gj-grid-md td {
    position: relative;
    height: 48px;
    border-top: 1px solid rgba(0,0,0,.12);
    border-bottom: 1px solid rgba(0,0,0,.12);
    padding: 12px 18px;
    box-sizing: border-box;
    text-align: left;
    color: rgba(0,0,0,.87);
}

.gj-grid-md tbody tr {
    position: relative;
    height: 48px;
    transition-duration: .28s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-property: background-color;
}

.gj-grid-md tbody tr:hover {
    background-color: #EEEEEE; /* Gray 200 */
}

.gj-grid-md tbody tr.gj-grid-md-select {
    background-color: #F5F5F5; /* Grey 100 */
}


table.gj-grid-md thead th [data-role="sorticon"] {
    margin-left: 5px;
}

table.gj-grid-md thead th [data-role="sorticon"] i.gj-icon,
table.gj-grid-md thead th [data-role="sorticon"] i.material-icons {
    position: absolute;
    font-size: 16px;
    top: 19px;
}

table.gj-grid-md thead th.gj-grid-select-all {
    padding-bottom: 3px;
}
/* Hide all prioritized columns by default */
@media only all {
	th.display-1120,
	td.display-1120,
	th.display-960,
	td.display-960,
	th.display-800,
	td.display-800,
	th.display-640,
	td.display-640,
	th.display-480,
	td.display-480,
	th.display-320,
	td.display-320 {
		display: none;
	}
}

/* Show at 320px (20em x 16px) */
@media screen and (min-width: 20em) {
	TABLE.gj-grid-bootstrap th.display-320,
	TABLE.gj-grid-bootstrap td.display-320 {
		display: table-cell;
	}
}
/* Show at 480px (30em x 16px) */
@media screen and (min-width: 30em) {
	TABLE.gj-grid-bootstrap th.display-480,
	TABLE.gj-grid-bootstrap td.display-480 {
		display: table-cell;
	}
}
/* Show at 640px (40em x 16px) */
@media screen and (min-width: 40em) {
	TABLE.gj-grid-bootstrap th.display-640,
	TABLE.gj-grid-bootstrap td.display-640 {
		display: table-cell;
	}
}
/* Show at 800px (50em x 16px) */
@media screen and (min-width: 50em) {
	TABLE.gj-grid-bootstrap th.display-800,
	TABLE.gj-grid-bootstrap td.display-800 {
		display: table-cell;
	}
}
/* Show at 960px (60em x 16px) */
@media screen and (min-width: 60em) {
	TABLE.gj-grid-bootstrap th.display-960,
	TABLE.gj-grid-bootstrap td.display-960 {
		display: table-cell;
	}
}
/* Show at 1,120px (70em x 16px) */
@media screen and (min-width: 70em) {
	TABLE.gj-grid-bootstrap th.display-1120,
	TABLE.gj-grid-bootstrap td.display-1120 {
		display: table-cell;
	}
}

/* Material Design Theme */
.gj-grid-md tfoot tr th {
    padding-right: 14px;
}

.gj-grid-md tfoot tr[data-role="pager"] .gj-grid-mdl-pager-label {
    padding-left: 5px;
    padding-right: 5px;
}

.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md {
    margin-left: 12px;
}

.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md [role="presenter"] {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0,0,0,.54);
}

.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md [role="presenter"] [role="display"] {
    text-align: right;
}

.gj-grid-md tfoot tr[data-role="pager"] .gj-grid-md-limit-select {
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: rgba(0,0,0,.54);
}

/* Bootstrap */
.gj-grid-bootstrap tfoot tr[data-role="pager"] th {
    line-height: 30px;
    background-color: #f5f5f5;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > div {
    margin-right: 5px;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > button {
    margin-right: 5px;
}

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div button {
    height: 34px;
}

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th div .gj-dropdown-bootstrap-4 .gj-dropdown-expander-mi .gj-icon {
    top: 5px;
}

.gj-grid-bootstrap-3 tfoot tr[data-role="pager"] th > div > input {
    margin-right: 5px;
    width: 40px;
    text-align: right;
    display: inline-block;
    font-weight: bold;
}

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div > div.input-group {
    width: 40px;
}

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div > div.input-group input {
    text-align: right;
    font-weight: bold;
    height: 34px;
    padding-top: 2px;
    padding-bottom: 6px;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > select {
    display: inline-block;
    margin-right: 5px;
    width: 60px;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap .gj-list-bootstrap [data-role="display"] {
    line-height: 14px;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap [role="presenter"] [role="display"] {
    font-weight: bold;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap-3 [role="presenter"] {
    padding: 2px 8px;
}

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap-4 [role="presenter"] {
    padding: 1px 8px;
}
.gj-grid thead tr th div.gj-grid-column-resizer-wrapper {
    position: relative;
    width: 100%;
    height: 0px; 
    top: 0px; 
    left: 0px; 
    padding: 0px;
}

span.gj-grid-column-resizer {
    position: absolute;
    right: 0px;
    width: 10px;
    top: -100px;
    height: 300px;
    z-index: 1203;
    cursor: e-resize;
}

.gj-grid-resize-cursor {
    cursor: e-resize;
}
.gj-grid-md tbody tr.gj-grid-top-border td {
  border-top: 2px solid #777;
}
.gj-grid-md tbody tr.gj-grid-bottom-border td {
  border-bottom: 2px solid #777;
}

.gj-grid-bootstrap tbody tr.gj-grid-top-border td {
  border-top: 2px solid #777;
}
.gj-grid-bootstrap tbody tr.gj-grid-bottom-border td {
  border-bottom: 2px solid #777;
}
.gj-grid-md thead tr th.gj-grid-left-border,
.gj-grid-md tbody tr td.gj-grid-left-border
{
    border-left: 3px solid #777;
}
.gj-grid-md thead tr th.gj-grid-right-border,
.gj-grid-md tbody tr td.gj-grid-right-border
{
    border-right: 3px solid #777;
}

.gj-grid-bootstrap thead tr th.gj-grid-left-border,
.gj-grid-bootstrap tbody tr td.gj-grid-left-border
{
    border-left: 5px solid #ddd;
}
.gj-grid-bootstrap thead tr th.gj-grid-right-border,
.gj-grid-bootstrap tbody tr td.gj-grid-right-border
{
    border-right: 5px solid #ddd;
}
.gj-dirty {
    position: absolute;
    top: 0px;
    left: 0px;
    border-style: solid;
    border-width: 3px;
    border-color: #f00 transparent transparent #f00;
    padding: 0;
    overflow: hidden;
    vertical-align: top;
}

/* Material Design */
.gj-grid-md tbody tr td.gj-grid-management-column {
    padding: 3px;
}

.gj-grid-md tbody tr td[data-mode="edit"] {
    padding: 0px 18px;
}

.gj-grid-md tbody .gj-dropdown-md [role="presenter"] [role="display"] {
    padding: 0px;
}

/* Bootstrap */
.gj-grid-bootstrap tbody tr td[data-mode="edit"] {
    padding: 0px;
}

.gj-grid-bootstrap tbody tr td[data-mode="edit"] [data-role="edit"] {
    padding: 0px;
}

/* Bootstrap 3 */
.gj-grid-bootstrap-3 tbody tr td.gj-grid-management-column {
    padding: 3px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] {
    height: 38px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] [data-role="edit"] input[type="text"] {
    height: 37px;
    padding: 8px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap [role="presenter"] {
    border: 0px;
    border-radius: 0px;
    height: 37px;
    padding-left: 8px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap {
    height: 37px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="input"] {
    height: 37px;
    border:0px;
    border-radius: 0px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] {
    border:0px;
    border-radius: 0px;
}

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-checkbox-bootstrap {
    display: inline-block;
    padding-top: 10px;
    height: 32px;
}

/* Bootstrap 4 */
.gj-grid-bootstrap-4 tbody tr td.gj-grid-management-column {
    padding: 6px;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] [data-role="edit"] input[type="text"] {
    height: 48px;
    padding-left: 12px;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap [role="presenter"] {
    border: 0px;
    border-radius: 0px;
    height: 48px;
    padding-left: 12px;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap-4 [role="expander"].gj-dropdown-expander-mi .gj-icon,
.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap-4 [role="expander"].gj-dropdown-expander-mi .material-icons {
    top: 13px;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap {
    height: 48px;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="input"] {
    height: 48px;
    border:0px;
    border-radius: 0px;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] {
    background-color: #fff;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] button {
    border: 0px;
    border-radius: 0px;
    width: 43px;
    position: relative;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] .gj-icon,
.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] .material-icons {
    top: 13px;
    left: 10px;
    font-size: 24px;
}

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-checkbox-bootstrap {
    display: inline-block;
    padding-top: 15px;
    height: 42px;
}
.gj-grid-md thead tr[data-role="filter"] th {
    border-top: 1px solid rgba(0,0,0,.12);
}

div.gj-grid-wrapper div.gj-grid-bootstrap-toolbar {
    background-color: #f5f5f5;
    padding: 8px;
    font-weight: bold;
    border: 1px solid #ddd;
}

div.gj-grid-wrapper div.gj-grid-bootstrap-4-toolbar {
    background-color: #f5f5f5;
    padding: 12px;
    font-weight: bold;
    border: 1px solid #ddd;
}

div.gj-grid-wrapper div.gj-grid-md-toolbar {
    font-weight: bold;
    font-size: 24px;
    font-family: "Helvetica","Arial",sans-serif;
    background-color: rgb(255, 255, 255);
    
    border-top: 1px solid rgba(0,0,0,.12);
    border-left: 1px solid rgba(0,0,0,.12);
    border-right: 1px solid rgba(0,0,0,.12);
    border-bottom: 0px;
    border-collapse: collapse;

    padding: 0 18px 0px 18px;
    line-height: 56px;
}
table.gj-grid-scrollable tbody {
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
}

/* Material Design */
table.gj-grid-md.gj-grid-scrollable {
    border-bottom: 0px;
}

table.gj-grid-md.gj-grid-scrollable tbody {
    border-right: 1px solid rgba(0,0,0,.12);
    border-bottom: 1px solid rgba(0,0,0,.12);
}

table.gj-grid-md.gj-grid-scrollable tfoot {
    border-bottom: 1px solid rgba(0,0,0,.12);
}

/* Bootstrap 3 */
table.gj-grid-bootstrap.gj-grid-scrollable {
    border-bottom: 0px;
}

table.gj-grid-bootstrap.gj-grid-scrollable tbody {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

table.gj-grid-bootstrap.gj-grid-scrollable tbody tr[data-role="row"]:first-child td {
    border-top: 0px;
}

table.gj-grid-bootstrap.gj-grid-scrollable tbody tr[data-role="row"] td:first-child {
    border-left: 0px;
}

table.gj-grid-bootstrap.gj-grid-scrollable tbody tr[data-role="row"] td:last-child {
    border-right: 0px;
}

table.gj-grid-bootstrap.gj-grid-scrollable tfoot {
    border-bottom: 1px solid #ddd;
}

ul.gj-list li [data-role="spacer"] {
    display: table-cell;
}

ul.gj-list li [data-role="expander"] {
    display: table-cell;
    vertical-align:middle;
    text-align:center;
    cursor: pointer;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-material-icons-expander {
    width: 24px;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-font-awesome-expander {
    width: 24px;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander {
    width: 24px;
}

[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander .glyphicon {
    top: 4px;
    height: 24px;
}

/* Bootstrap Theme */
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li {
    border: 0px;
    border-radius: 0px;
    color: #333;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.active {
    color: #fff;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.disabled {
    color: #777;
    background-color: #eee;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li {
    border: 0px;
    border-radius: 0px;
    color: #212529;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.active {
    color: #fff;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.disabled {
    color: #868e96;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li ul.gj-list-bootstrap {
    width: 100%;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li {
    border: 1px solid #ddd;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li {
    border-left: 0px;
    border-right: 0px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li:last-child {
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander {
    padding-top: 8px;
    padding-bottom: 4px;
}

ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander .gj-icon {
    width: 24px;
    height: 24px;
}

/* Material Design Theme */
ul.gj-list-md li.disabled > [data-role="wrapper"] > [data-role="expander"] {
    color: #9E9E9E; /* Gray 500 */
}

.gj-tree-md-border ul.gj-list-md li {
    border: 1px solid #616161; /* Gray 700 */
    margin-bottom: -1px;
}

.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li {
    border-left: 0px;
    border-right: 0px;
}

.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li:last-child {
    border-bottom: 0px;
}
.gj-tree-drop-above {    
    border-top: 1px solid #000;
}

.gj-tree-drop-below {
    border-bottom: 1px solid #000;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
    border-top: 2px solid #000;
}

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
    border-bottom: 2px solid #000;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
    border-top: 2px solid #000;
}

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
    border-bottom: 2px solid #000;
}

.gj-tree-drag-el {
    padding: 0px;
    margin: 0px;
    z-index: 1203;
}

.gj-tree-drag-el li {
    padding: 0px;
    margin: 0px;
}

.gj-tree-drag-el [data-role="wrapper"] {
    cursor: move;
    display: table;
}

.gj-tree-drag-el [data-role="indicator"] {
    width: 14px;
    padding: 0px 3px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.gj-tree-bootstrap-drag-el li.list-group-item {
    border: 0px;
    background: unset;
}

.gj-tree-bootstrap-drag-el [data-role="indicator"] {
    width: 24px;
    height: 24px;
    padding: 0px;
}

.gj-tree-md-drag-el [data-role="indicator"] {
    width: 24px;
    height: 24px;
    padding: 0px;
}
/* Bootstrap */
.gj-checkbox-bootstrap {
    min-width: 0;
    font-size: 0;
    font-weight: normal;
    margin: 0px;
    text-align: center;
    width: 18px;
    height: 18px;
    position: relative;
    display: inline;
}

.gj-checkbox-bootstrap input[type="checkbox"] {
    display: none;
    margin-bottom: -12px;
}

.gj-checkbox-bootstrap span {
    background: #fff;
    display: block;
    content: " ";
    width: 18px;
    height: 18px;
    line-height: 11px;
    font-size: 11px;
    padding: 2px;
    color: #555555;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    transition: box-shadow 0.2s linear, border-color 0.2s linear;
    cursor: pointer;
    margin: auto;
}

.gj-checkbox-bootstrap input[type="checkbox"]:focus + span:before {
    outline: 0;
    box-shadow: 0 0 0 0 #66afe9, 0 0 6px rgba(102, 175, 233, .6);
    border-color: #66afe9;
}

.gj-checkbox-bootstrap input[type="checkbox"][disabled] + span {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Bootstrap 4 */
.gj-checkbox-bootstrap.gj-checkbox-bootstrap-4 span {
    line-height: 16px;
    padding: 0px;
}

.gj-checkbox-bootstrap-4.gj-checkbox-material-icons input[type="checkbox"]:checked + span:after {
    font-size: 16px;
}

.gj-checkbox-bootstrap-4.gj-checkbox-material-icons input[type="checkbox"]:indeterminate + span:after {
    font-size: 16px;
}

/* Material Design */
.gj-checkbox-md {
    min-width: 0;
    font-size: 0;
    font-weight: normal;
    margin: 0px;
    text-align: center;
    width: 16px;
    height: 16px;
    position: relative;
}

.gj-checkbox-md input[type="checkbox"] {
    display: none;
    margin-bottom: -12px;
}

.gj-checkbox-md span {
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    border: 2px solid #616161; /* Gray 700 */
    border-radius: 2px;
    z-index: 2;
}

.gj-checkbox-md input[type="checkbox"]:checked + span {
    border: 2px solid #536DFE; /* Indigo A200 */
}

.gj-checkbox-md input[type="checkbox"]:checked + span:after {
    color: #FFF;
    background-color: #536DFE; /* Indigo A200 */
    position: absolute;
    left: 1px;
    top: -15px;
}

.gj-checkbox-md input[type="checkbox"]:indeterminate + span {
    border: 2px solid #616161; /* Gray 700 */
}

.gj-checkbox-md input[type="checkbox"]:indeterminate + span:after {    
    color: #616161;/*color: rgba(0, 0, 0, 1);*/
    position: absolute;
    left: 1px;
    top: -15px;
}

.gj-checkbox-md input[type="checkbox"][disabled] + span {
    border: 2px solid #9E9E9E;
}

.gj-checkbox-md input[type="checkbox"][disabled] + span:after {
    background-color: #9E9E9E;
}

.gj-checkbox-md input[type="checkbox"][disabled]:indeterminate + span:after {
    color: #FFFFFF;
}

/* Material Icons */
.gj-checkbox-material-icons input[type="checkbox"]:checked + span:after {
    content: "\e91f";
    font-size: 14px;
    font-weight: bold;
    white-space: pre;
}

.gj-checkbox-material-icons input[type="checkbox"]:indeterminate + span:after {
    content: "\e921";
    font-size: 14px;
    font-weight: bold;
    white-space: pre;
}

/* Glyphicons */
.gj-checkbox-glyphicons input[type="checkbox"]:checked + span:after {
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    content: "\e013 ";
}

.gj-checkbox-glyphicons input[type="checkbox"]:indeterminate + span:after {
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    content: "\2212 ";
    padding-right: 1px;
}

/* fontawesome */
.gj-checkbox-fontawesome .fa {
    font-size: 14px;
}

.gj-checkbox-fontawesome input[type="checkbox"]:checked + span:before {
    content: "\f00c ";
}

.gj-checkbox-fontawesome input[type="checkbox"]:indeterminate + span:before {
    content: "\f068 ";
}
.gj-editor [role="body"] {
    overflow: auto;
    outline: 0px solid transparent;
    box-sizing: border-box;
}

/* Material Design */
.gj-editor-md {
    padding: 7px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    border: 1px solid rgba(158,158,158,.2);
}

.gj-editor-md [role="toolbar"] {
    margin-bottom: 7px;
}

.gj-editor-md [role="toolbar"] .gj-button-md {
    min-width: 54px;
    margin-right: 5px;
}

.gj-editor-md [role="toolbar"] .gj-button-md .gj-icon {
    width: 24px;
    height: 24px;
}

.gj-editor-md [role="body"] {
    border: 1px solid rgba(158,158,158,.2);
}

.gj-editor-md p {
    margin: 0;
    padding: 0;
}

.gj-editor-md blockquote {
    font-size: 14px;
}

/* Bootstrap */
.gj-editor-bootstrap {
    padding: 7px;
    border: 1px solid #eceeef;
}

.gj-editor-bootstrap [role="toolbar"] {
    margin-bottom: 7px;
}

.gj-editor-bootstrap [role="toolbar"] .btn-group {
    margin-right: 10px;
}

.gj-editor-bootstrap [role="toolbar"] button {
    height: 36px;
}

.gj-editor-bootstrap [role="body"] {
    border: 1px solid #eceeef;
}

.gj-editor-bootstrap p {
    margin: 0;
    padding: 0;
}

.gj-editor-bootstrap blockquote {
    font-size: 14px;
}
.gj-dropdown {
    position: relative;
    border-collapse: separate;
}

.gj-dropdown [role="presenter"] {
    display: table;
    cursor: pointer;
    outline: none;
    position: relative;
}

.gj-dropdown [role="presenter"] [role="display"] {
    display: table-cell;
    text-align: left;
    width: 100%;
}

.gj-dropdown [role="presenter"] [role="expander"] {
    display: table-cell;
    vertical-align:middle;
    text-align:center;
    width: 24px;
    height: 24px;
}

/* Material Design */
.gj-dropdown-md [role="presenter"] {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    color: rgba(0,0,0,.87);
    padding: 0px;
    border: 0px;
    border-bottom: 1px solid rgba(0,0,0,.42);
    background: transparent;
}

.gj-dropdown-md [role="presenter"]:focus,
.gj-dropdown-md [role="presenter"]:active {
    border-bottom: 2px solid rgba(0,0,0,.42);
}

.gj-dropdown-md [role="presenter"] [role="display"] {
    padding: 4px 0px;
    line-height: 18px;
}

.gj-dropdown-md [role="presenter"] [role="display"] .placeholder {
    color: #8e8e8e;
}

.gj-dropdown-list-md {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #f5f5f5;
    color: #000;
    margin: 0px;
    z-index: 1203;
}

.gj-dropdown-list-md li:hover, .gj-dropdown-list-md li.active {
    background-color: #eee;
}

/* Bootstrap */
.gj-dropdown-bootstrap [role="presenter"] [role="display"] {
    padding-right: 5px;
}

.gj-dropdown-bootstrap [role="presenter"] [role="expander"] {
    padding-left: 5px;
}

.gj-dropdown-bootstrap [role="presenter"] [role="expander"].gj-dropdown-expander-mi {
    width: 24px;
}

.gj-dropdown-bootstrap-3 [role="presenter"] [role="display"] {
    line-height: 20px;
}

.gj-dropdown-bootstrap-3 [role="presenter"] [role="display"] .placeholder {
    color: #9999b3;
}

.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"] {
    width: 20px;
    height: 20px;
}

.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .gj-icon,
.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .material-icons {
    top: 5px;
    right: 10px;
    position: absolute;
}

.gj-dropdown-bootstrap-4 [role="presenter"] {
    border: 1px solid #ced4da;
}

.gj-dropdown-bootstrap-4 [role="presenter"] [role="display"] {
    line-height: 24px;
}

.gj-dropdown-bootstrap-4 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .gj-icon,
.gj-dropdown-bootstrap-4 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .material-icons {
    top: 7px;
    right: 10px;
    position: absolute;
}

.gj-dropdown-list-bootstrap {
    position: absolute;
    top: 32px;
    left: 0px;
    margin: 0px;
    z-index: 1203;
}
.gj-datepicker [role="input"]::-ms-clear {
    display: none;
}

.gj-datepicker [role="right-icon"] {
    cursor: pointer;
}

.gj-picker div[role="navigator"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.gj-picker div[role="navigator"] div {
    cursor: pointer;
    position: relative;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.gj-picker div[role="navigator"] div[role="period"] {
    width: 100%;
    text-align: center;
}

/* Material Design */
.gj-datepicker-md {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    color: rgba(0,0,0,.87);
    position: relative;
}

.gj-datepicker-md [role="right-icon"] {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 24px;
}

.gj-datepicker-md.small .gj-textbox-md {
    font-size: 14px;
}

.gj-datepicker-md.small .gj-icon {
    font-size: 22px;
}

.gj-datepicker-md.large .gj-textbox-md {
    font-size: 18px;
}

.gj-datepicker-md.large .gj-icon {
    font-size: 28px;
}

.gj-picker-md.datepicker [role="header"] {
    padding: 20px 20px;
    display: block;
}

.gj-picker-md.datepicker [role="header"] [role="year"] {
    font-size: 17px;
    padding-bottom: 5px;
    cursor: pointer;
}

.gj-picker-md.datepicker [role="header"] [role="date"] {
    font-size: 36px;
    cursor: pointer;
}

.gj-picker-md div[role="navigator"] {
    height: 42px;
    line-height: 42px;
}

.gj-picker div[role="navigator"] div[role="period"] {
    font-weight: bold;
    font-size: 15px;
}

.gj-picker-md div[role="navigator"] div:first-child {
    max-width: 42px;
}

.gj-picker-md div[role="navigator"] div:last-child {
    max-width: 42px;
}

.gj-picker-md div[role="navigator"] div i.gj-icon,
.gj-picker-md div[role="navigator"] div i.material-icons {
    position: absolute;
    top: 8px;
}

.gj-picker-md div[role="navigator"] div:first-child i.gj-icon,
.gj-picker-md div[role="navigator"] div:first-child i.material-icons {
    left: 10px;
}

.gj-picker-md div[role="navigator"] div:last-child i.gj-icon,
.gj-picker-md div[role="navigator"] div:last-child i.material-icons {
    right: 11px;
}

.gj-picker-md table thead {
    color: #9E9E9E; /* Gray 500 */
}

.gj-picker-md table tr th div,
.gj-picker-md table tr td div {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
}

[type="year"].gj-picker-md table tr td div,
[type="decade"].gj-picker-md table tr td div,
[type="century"].gj-picker-md table tr td div {
    width: 73px;
    height: 73px;
    line-height: 73px;
    cursor: pointer;
}

.gj-picker-md table tr td.gj-cursor-pointer div:hover {
    background: #EEEEEE;
    border-radius: 50%;
    color: rgba(0,0,0,.87);
}

.gj-picker-md table tr td.other-month div,
.gj-picker-md table tr td.disabled div {
    color: #BDBDBD; /* Gray 400 */
}

.gj-picker-md table tr td.focused div {
    background: #E0E0E0; /* Gray 300 */
    border-radius: 50%;
}

.gj-picker-md table tr td.today div {
    color: #1976D2;
}

.gj-picker-md table tr td.selected.gj-cursor-pointer div {
    color: #FFFFFF;
    background: #1976D2; /* Blue 700 */
    border-radius: 50%;
}

.gj-picker-md table tr td.calendar-week div {
    font-weight: bold;
}

/* Bootstrap */
.gj-datepicker-bootstrap :focus,
.gj-datepicker-bootstrap :active {
    box-shadow: none;
}

.gj-picker-bootstrap {
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    padding: 4px;
}

.gj-modal .gj-picker-bootstrap {
    padding: 0px;
}

.gj-picker-bootstrap.datepicker [role="header"] {
    padding: 10px 20px;
    display: block;
}

.gj-picker-bootstrap.datepicker [role="header"] [role="year"] {
    font-size: 15px;
    cursor: pointer;
}

.gj-picker-bootstrap [role="header"] [role="date"] {
    font-size: 24px;
    cursor: pointer;
}

.gj-modal .gj-picker-bootstrap.datepicker [role="body"] {
    padding: 15px;
}

.gj-picker-bootstrap div[role="navigator"] {
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.gj-picker-bootstrap div[role="navigator"] div:first-child {
    max-width: 30px;
}

.gj-picker-bootstrap div[role="navigator"] div:last-child {
    max-width: 30px;
}

.gj-picker-bootstrap table tr td div,
.gj-picker-bootstrap table tr th div {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
}

[type="year"].gj-picker-bootstrap table tr td div,
[type="decade"].gj-picker-bootstrap table tr td div,
[type="century"].gj-picker-bootstrap table tr td div {
    width: 53px;
    height: 53px;
    line-height: 53px;
    cursor: pointer;
}

.gj-picker-bootstrap table tr th div i,
.gj-picker-bootstrap table tr th div span {
    line-height: 30px;
}

.gj-picker-bootstrap div[role="navigator"] .gj-icon,
.gj-picker-bootstrap div[role="navigator"] .material-icons {
    margin: 3px;
}

.gj-picker-bootstrap table tr td.focused div,
.gj-picker-bootstrap table tr td.gj-cursor-pointer div:hover {
    background: #EEEEEE;
    border-radius: 4px;
    color: #212529;
}

.gj-picker-bootstrap table tr td.today div {
    color: #204d74;
    font-weight: bold;
}

.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
    border-radius: 4px;
}

.gj-picker-bootstrap table tr td.other-month div,
.gj-picker-bootstrap table tr td.disabled div {
    color: #777;
}

/* Bootstrap 3 */
.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0px;
    position: relative;
    /*width: 38px;*/
}

.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon .gj-icon,
.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon .material-icons {
    position: absolute;
    top: 7px;
    left: 7px;
}

/* Bootstrap 4 */
.gj-datepicker-bootstrap [role="right-icon"] button {
    width: 38px;
    position: relative;
    border: 1px solid #ced4da;
}

.gj-datepicker-bootstrap [role="right-icon"] button:hover {
    color: #6c757d;
    background-color: transparent;
}

.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button {
    width: 30px;
}

.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button {
    width: 48px;
}

.gj-datepicker-bootstrap [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap [role="right-icon"] button .material-icons {
    position: absolute;
    font-size: 21px;
    top: 9px;
    left: 9px;
}

.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button .material-icons {
    top: 6px;
    left: 6px;
    font-size: 19px;
}

.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button .material-icons {
    font-size: 27px;
    top: 10px;
    left: 10px;
}
.gj-timepicker [role="input"]::-ms-clear {
    display: none;
}

.gj-timepicker [role="right-icon"] {
    cursor: pointer;
}

.gj-picker.timepicker [role="header"] {
    font-size: 58px;
    padding: 20px 0;
    line-height: 58px;

    display: flex;
    align-items: baseline;
    user-select: none;
    justify-content: center;
}

.gj-picker.timepicker [role="header"] div {
    cursor: pointer;
    width: 66px;
    text-align: right;
}

.gj-picker [role="header"] [role="mode"] {
    position: relative;
    width: 0px;
}

.gj-picker [role="header"] [role="mode"] span {
    position: absolute;
    left: 7px;
    line-height: 18px;
    font-size: 18px;
}

.gj-picker [role="header"] [role="mode"] span[role="am"] {
    top: 7px;
}

.gj-picker [role="header"] [role="mode"] span[role="pm"] {
    bottom: 7px;
}

.gj-picker [role="body"] [role="dial"] {
    width: 256px;
    color: rgba(0, 0, 0, 0.87);
    height: 256px;
    position: relative;
    background: #eeeeee;
    border-radius: 50%;
    margin: 10px;
}

.gj-picker [role="body"] [role="hour"] {
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
    user-select: none;
    pointer-events: none;
}

.gj-picker [role="body"] [role="hour"].selected {
    color: rgba(255, 255, 255, 1);
}

.gj-picker [role="body"] [role="arrow"] {
    top: calc(50% - 1px);
    left: 50%;
    width: calc(50% - 20px);
    height: 2px;
    position: absolute;
    pointer-events: none;
    transform-origin: left center;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    width: calc(50% - 52px);
}

.gj-picker .arrow-begin {
    top: -3px;
    left: -4px;
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
}

.gj-picker .arrow-end {
    top: -15px;
    right: -16px;
    width: 0;
    height: 0;
    position: absolute;
    box-sizing: content-box;
    border-width: 16px;
    border-radius: 50%;
}

/* Material Design */
.gj-timepicker-md {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .04em;
    line-height: 1;
    color: rgba(0,0,0,.87);
    position: relative;
}

.gj-timepicker-md.small .gj-textbox-md {
    font-size: 14px;
}

.gj-timepicker-md.small .gj-icon {
    font-size: 22px;
}

.gj-timepicker-md.large .gj-textbox-md {
    font-size: 18px;
}

.gj-timepicker-md.large .gj-icon {
    font-size: 28px;
}

.gj-timepicker-md [role="right-icon"] {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 24px;
}

.gj-picker-md .arrow-begin {
    background-color: #2196f3;
}
.gj-picker-md .arrow-end {
    border: 16px solid #2196f3;
}

.gj-picker-md [role="body"] [role="arrow"] {
    background-color: #2196f3;
}

/* Bootstrap */
.gj-timepicker-bootstrap :focus,
.gj-timepicker-bootstrap :active {
    box-shadow: none;
}

.gj-picker-bootstrap [role="body"] [role="arrow"] {
    background-color: #888;
}

.gj-picker-bootstrap .arrow-begin {
    background-color: #888;
}

.gj-picker-bootstrap .arrow-end {
    border: 16px solid #888;
}

/* Bootstrap 3 */
.gj-timepicker-bootstrap .input-group-addon {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0px;
    position: relative;
    width: 38px;
}

.gj-timepicker-bootstrap.input-group-sm .input-group-addon {
    width: 30px;
}

.gj-timepicker-bootstrap.input-group-lg .input-group-addon {
    width: 46px;
}

.gj-timepicker-bootstrap .input-group-addon .gj-icon,
.gj-timepicker-bootstrap .input-group-addon .material-icons {
    position: absolute;
    font-size: 21px;
    top: 6px;
    left: 8px;
}

.gj-timepicker-bootstrap.input-group-sm .input-group-addon .gj-icon,
.gj-timepicker-bootstrap.input-group-sm .input-group-addon .material-icons {
    font-size: 19px;
    top: 5px;
    left: 5px;
}

.gj-timepicker-bootstrap.input-group-lg .input-group-addon .gj-icon,
.gj-timepicker-bootstrap.input-group-lg .input-group-addon .material-icons {
    font-size: 27px;
    top: 10px;
    left: 10px;
}

/* Bootstrap 4 */
.gj-timepicker-bootstrap [role="right-icon"] button {
    width: 38px;
    position: relative;
}

.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button {
    width: 30px;
}

.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button {
    width: 48px;
}

.gj-timepicker-bootstrap [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap [role="right-icon"] button .material-icons {
    position: absolute;
    font-size: 21px;
    top: 7px;
    left: 9px;
}

.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button .material-icons {
    top: 4px;
    left: 6px;
    font-size: 19px;
}

.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button .material-icons {
    font-size: 27px;
    top: 8px;
    left: 10px;
}
.gj-picker.datetimepicker [role="header"] [role="date"] {
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;
}

.gj-picker [role="switch"] {
    align-items: baseline;
    user-select: none;
    position: relative;
}

.gj-picker [role="switch"] [role="calendarMode"] {
    cursor: pointer;
    position: absolute;
    bottom: 2px;
    left: 0px;
}

.gj-picker [role="switch"] [role="time"] {
    width: 100%;
    text-align: center;
}

.gj-picker [role="switch"] [role="time"] div {
    display: inline;
    cursor: pointer;
}

.gj-picker [role="switch"] [role="calendarMode"] {
    cursor: pointer;
}

.gj-picker [role="switch"] [role="clockMode"] {
    position: absolute;
    right: 0px;
    bottom: 3px;
    cursor: pointer;
}

/* Material Design */
.gj-picker-md.datetimepicker [role="header"] {
    font-size: 36px;
    padding: 10px 20px;
    display: block;
}

.gj-picker-md [role="switch"] {
    color: rgba(255, 255, 255, 0.54);
    background: #2196f3;
    font-size: 32px;
}

/* Bootstrap */
.gj-picker-bootstrap.datetimepicker [role="header"] {
    font-size: 36px;
    padding: 10px 20px;
    display: block;
}

.gj-picker-bootstrap.datetimepicker [role="header"] [role="time"] {
    font-size: 22px;
}

