@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.btn-group.disabled, .bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
  opacity: .7; }

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important; }

/* crit:start */
.input-group > .bootstrap-tagsinput {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden; }
  .input-group > .bootstrap-tagsinput > input {
    border: 0; }

.bootstrap-tagsinput {
  background-color: #fff;
  display: inline-block;
  padding: 0px 6px 0px;
  color: #555;
  vertical-align: middle;
  border-radius: 0;
  max-width: 100%;
  line-height: 22px;
  min-height: 36px;
  cursor: text; }

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto !important;
  max-width: inherit; }

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1; }

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777; }

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777; }

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none; }

.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #2c2c2c;
  display: inline-block;
  margin-top: 5px;
  font-weight: 500;
  line-height: 24px; }

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  padding-left: 5px;
  margin-right: -4px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.5); }

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  font-family: 'g-icons';
  content: "\EA07";
  padding: 0px 2px;
  display: inline-block;
  transform: rotate(45deg); }

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

/* crit:end */
/* crit:start */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(44, 44, 44, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* crit:end */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

/* crit:start */
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 768px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dee2e6;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #dee2e6;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 767.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

/* crit:end */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #15559a;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #0f3c6d; }

.alert-secondary {
  color: #4d5256;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #35383b; }

.alert-success {
  color: #2a6c39;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #1c4726; }

.alert-info {
  color: #216975;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #16454d; }

.alert-warning {
  color: #9a7919;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #6e5712; }

.alert-danger {
  color: #883139;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #632329; }

.alert-light {
  color: #969797;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #7c7e7e; }

.alert-dark {
  color: #303336;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #181a1b; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 768px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #15559a;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #15559a;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #15559a;
    border-color: #15559a; }

.list-group-item-secondary {
  color: #4d5256;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4d5256;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #4d5256;
    border-color: #4d5256; }

.list-group-item-success {
  color: #2a6c39;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2a6c39;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #2a6c39;
    border-color: #2a6c39; }

.list-group-item-info {
  color: #216975;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #216975;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #216975;
    border-color: #216975; }

.list-group-item-warning {
  color: #9a7919;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #9a7919;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #9a7919;
    border-color: #9a7919; }

.list-group-item-danger {
  color: #883139;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #883139;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #883139;
    border-color: #883139; }

.list-group-item-light {
  color: #969797;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #969797;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #969797;
    border-color: #969797; }

.list-group-item-dark {
  color: #303336;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #303336;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #303336;
    border-color: #303336; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

.modal-xl {
  max-width: 1140px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

/* crit:start */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(44, 44, 44, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* crit:end */
/* crit:start */
/* crit:end */
.gj-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #000;
  border-radius: 3px;
  padding: 6px 10px;
  cursor: pointer; }

.gj-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.gj-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.gj-margin-left-5 {
  margin-left: 5px; }

.gj-margin-left-10 {
  margin-left: 10px; }

.gj-width-full {
  width: 100%; }

.gj-cursor-pointer {
  cursor: pointer; }

.gj-text-align-center {
  text-align: center; }

.gj-font-size-16 {
  font-size: 16px; }

.gj-hidden {
  display: none; }

/** Material Design */
.gj-button-md {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.gj-button-md:hover {
  background-color: rgba(158, 158, 158, 0.2); }

.gj-button-md:disabled {
  color: rgba(0, 0, 0, 0.26);
  background: 0 0; }

.gj-button-md .material-icons,
.gj-button-md .gj-icon {
  vertical-align: middle;
  /*font-size: 1.3rem;
    margin-right: 4px;*/ }

.gj-button-md.gj-button-md-icon {
  width: 24px;
  height: 31px;
  min-width: 24px;
  padding: 0px;
  display: table; }

.gj-button-md.gj-button-md-icon .material-icons,
.gj-button-md.gj-button-md-icon .gj-icon {
  display: table-cell;
  margin-right: 0px;
  width: 24px;
  height: 24px; }

.gj-button-md.active {
  background-color: rgba(158, 158, 158, 0.4); }

.gj-button-md-group {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.gj-textbox-md {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  display: block;
  font-size: 16px;
  line-height: 16px;
  padding: 4px 0px;
  margin: 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: rgba(0, 0, 0, 0.87); }

.gj-textbox-md:focus,
.gj-textbox-md:active {
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
  outline: none; }

.gj-textbox-md::placeholder {
  color: #8e8e8e; }

.gj-textbox-md:-ms-input-placeholder {
  color: #8e8e8e; }

.gj-textbox-md::-ms-input-placeholder {
  color: #8e8e8e; }

.gj-md-spacer-24 {
  min-width: 24px;
  width: 24px;
  display: inline-block; }

.gj-md-spacer-32 {
  min-width: 32px;
  width: 32px;
  display: inline-block; }

.gj-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.54118);
  transition: 200ms ease opacity;
  will-change: opacity; }

/* List */
ul.gj-list li [data-role="wrapper"] {
  display: table;
  width: 100%; }

ul.gj-list li [data-role="checkbox"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

ul.gj-list li [data-role="image"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

ul.gj-list li [data-role="display"] {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer; }

ul.gj-list li [data-role="display"]:empty:before {
  content: "\200b";
  /* unicode zero width space character */ }

/* List - Bootstrap */
ul.gj-list-bootstrap {
  padding-left: 0px;
  margin-bottom: 0px; }

ul.gj-list-bootstrap li {
  padding: 0px; }

ul.gj-list-bootstrap li [data-role="wrapper"] {
  padding: 0px 10px; }

ul.gj-list-bootstrap li [data-role="checkbox"] {
  width: 24px;
  padding: 3px; }

ul.gj-list-bootstrap li [data-role="image"] {
  width: 24px;
  height: 24px; }

ul.gj-list-bootstrap li [data-role="display"] {
  padding: 8px 0px 8px 4px; }

.list-group-item.active ul li, .list-group-item.active:focus ul li, .list-group-item.active:hover ul li {
  text-shadow: none;
  color: initial; }

/* List - Material Design */
ul.gj-list-md {
  padding: 0px;
  list-style: none;
  list-style-type: none;
  line-height: 24px;
  letter-spacing: 0;
  color: #616161;
  /* Gray 700 */ }

ul.gj-list-md li {
  display: list-item;
  list-style-type: none;
  padding: 0px;
  min-height: unset;
  box-sizing: border-box;
  align-items: center;
  cursor: default;
  overflow: hidden;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

ul.gj-list-md li [data-role="checkbox"] {
  height: 24px;
  width: 24px; }

ul.gj-list-md li [data-role="image"] {
  height: 24px;
  width: 24px; }

ul.gj-list-md li [data-role="display"] {
  padding: 8px 0px 8px 5px;
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  align-items: center;
  text-align: left;
  color: rgba(0, 0, 0, 0.87); }

ul.gj-list-md li.disabled > [data-role="wrapper"] > [data-role="display"] {
  color: #9E9E9E;
  /* Gray 500 */ }

.gj-list-md-active {
  /* background: #E8EAF6; */
  background: rgba(0, 0, 0, 0.12);
  color: #3f51b5; }

/* Picker */
.gj-picker {
  position: absolute;
  z-index: 1203;
  background-color: #fff; }

.gj-picker .selected {
  color: #fff; }

/* Material Design */
.gj-picker-md {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #E0E0E0; }

.gj-modal .gj-picker-md {
  border: 0px; }

.gj-picker-md [role="header"] {
  color: rgba(255, 255, 255, 0.54);
  display: flex;
  background: #2196f3;
  align-items: baseline;
  user-select: none;
  justify-content: center; }

.gj-picker-md [role="footer"] {
  float: right;
  padding: 10px; }

.gj-picker-md [role="footer"] button.gj-button-md {
  color: #2196f3;
  font-weight: bold;
  font-size: 13px; }

/* Bootstrap */
.gj-picker-bootstrap {
  border-radius: 4px;
  border: 1px solid #E0E0E0; }

.gj-picker-bootstrap .selected {
  color: #888; }

.gj-picker-bootstrap [role="header"] {
  background: #eee;
  color: #AAA; }

@font-face {
  font-family: 'gijgo-material';
  src: url("../fonts/gijgo-material.eot?235541");
  src: url("../fonts/gijgo-material.eot?235541#iefix") format("embedded-opentype"), url("../fonts/gijgo-material.ttf?235541") format("truetype"), url("../fonts/gijgo-material.woff?235541") format("woff"), url("../fonts/gijgo-material.svg?235541#gijgo-material") format("svg");
  font-weight: normal;
  font-style: normal; }

.gj-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'gijgo-material' !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.gj-icon.undo:before {
  content: "\e900"; }

.gj-icon.vertical-align-top:before {
  content: "\e901"; }

.gj-icon.vertical-align-center:before {
  content: "\e902"; }

.gj-icon.vertical-align-bottom:before {
  content: "\e903"; }

.gj-icon.arrow-dropup:before {
  content: "\e904"; }

.gj-icon.clock:before {
  content: "\e905"; }

.gj-icon.refresh:before {
  content: "\e906"; }

.gj-icon.last-page:before {
  content: "\e907"; }

.gj-icon.first-page:before {
  content: "\e908"; }

.gj-icon.cancel:before {
  content: "\e909"; }

.gj-icon.clear:before {
  content: "\e90a"; }

.gj-icon.check-circle:before {
  content: "\e90b"; }

.gj-icon.delete:before {
  content: "\e90c"; }

.gj-icon.arrow-upward:before {
  content: "\e90d"; }

.gj-icon.arrow-forward:before {
  content: "\e90e"; }

.gj-icon.arrow-downward:before {
  content: "\e90f"; }

.gj-icon.arrow-back:before {
  content: "\e910"; }

.gj-icon.list-numbered:before {
  content: "\e911"; }

.gj-icon.list-bulleted:before {
  content: "\e912"; }

.gj-icon.indent-increase:before {
  content: "\e913"; }

.gj-icon.indent-decrease:before {
  content: "\e914"; }

.gj-icon.redo:before {
  content: "\e915"; }

.gj-icon.align-right:before {
  content: "\e916"; }

.gj-icon.align-left:before {
  content: "\e917"; }

.gj-icon.align-justify:before {
  content: "\e918"; }

.gj-icon.align-center:before {
  content: "\e919"; }

.gj-icon.strikethrough:before {
  content: "\e91a"; }

.gj-icon.italic:before {
  content: "\e91b"; }

.gj-icon.underlined:before {
  content: "\e91c"; }

.gj-icon.bold:before {
  content: "\e91d"; }

.gj-icon.arrow-dropdown:before {
  content: "\e91e"; }

.gj-icon.done:before {
  content: "\e91f"; }

.gj-icon.pencil:before {
  content: "\e920"; }

.gj-icon.minus:before {
  content: "\e921"; }

.gj-icon.plus:before {
  content: "\e922"; }

.gj-icon.chevron-up:before {
  content: "\e923"; }

.gj-icon.chevron-right:before {
  content: "\e924"; }

.gj-icon.chevron-down:before {
  content: "\e925"; }

.gj-icon.chevron-left:before {
  content: "\e926"; }

.gj-icon.event:before {
  content: "\e927"; }

.gj-draggable {
  cursor: move; }

.gj-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1203; }

.gj-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0; }

.gj-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%; }

.gj-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0; }

.gj-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%; }

.gj-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px; }

.gj-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px; }

.gj-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px; }

.gj-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px; }

.gj-dialog-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-top: 0px; }

.gj-dialog-scrollable [data-role="body"] {
  overflow-x: hidden;
  overflow-y: scroll; }

/** Bootstrap 3 **/
.gj-dialog-bootstrap {
  overflow: hidden;
  z-index: 1202; }

.gj-dialog-bootstrap [data-role="title"] {
  display: inline; }

.gj-dialog-bootstrap [data-role="close"] {
  line-height: 1.42857143; }

/** Bootstrap 4 **/
.gj-dialog-bootstrap4 {
  overflow: hidden;
  z-index: 1202; }

.gj-dialog-bootstrap4 [data-role="title"] {
  display: inline; }

.gj-dialog-bootstrap4 [data-role="close"] {
  line-height: 1.5; }

/** Material Design **/
.gj-dialog-md {
  background-color: #FFF;
  overflow: hidden;
  border: none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
  z-index: 1202; }

.gj-dialog-md-header {
  padding: 24px 24px 0px 24px;
  font-family: "Roboto","Helvetica","Arial",sans-serif; }

.gj-dialog-md-title {
  margin: 0;
  font-weight: 400;
  display: inline;
  line-height: 28px;
  font-size: 20px; }

.gj-dialog-md-close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: right;
  line-height: 28px;
  font-size: 28px; }

.gj-dialog-md-body {
  padding: 20px 24px 24px 24px;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; }

.gj-dialog-md-footer {
  padding: 8px 8px 8px 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box; }

.gj-dialog-md-footer > *:first-child {
  margin-right: 0; }

.gj-dialog-md-footer > * {
  margin-right: 8px;
  height: 36px; }

DIV.gj-grid-wrapper {
  margin: auto;
  position: relative;
  clear: both;
  z-index: 1; }

TABLE.gj-grid {
  margin: auto;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; }

TABLE.gj-grid THEAD TH [data-role="selectAll"] {
  margin: auto; }

TABLE.gj-grid THEAD TH [data-role="title"] {
  display: inline-block; }

TABLE.gj-grid THEAD TH [data-role="sorticon"] {
  display: inline-block; }

TABLE.gj-grid THEAD TH {
  overflow: hidden;
  text-overflow: ellipsis; }

TABLE.gj-grid.autogrow-header-row THEAD TH {
  overflow: auto;
  text-overflow: initial;
  white-space: pre-wrap;
  -ms-word-break: break-word;
  word-break: break-word; }

TABLE.gj-grid > tbody > tr > td {
  overflow: hidden;
  position: relative; }

table.gj-grid tbody div[data-role="display"] {
  vertical-align: middle;
  text-indent: 0;
  white-space: pre-wrap;
  -ms-word-break: break-word;
  word-break: break-word; }

table.gj-grid.fixed-body-rows tbody div[data-role="display"] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-word-break: initial;
  word-break: initial; }

table.gj-grid tfoot DIV[data-role="display"] {
  vertical-align: middle;
  text-indent: 0;
  display: flex; }

TABLE.gj-grid .fa {
  padding: 2px; }

TABLE.gj-grid > tbody > tr > td > div {
  padding: 2px;
  overflow: hidden; }

DIV.gj-grid-wrapper DIV.gj-grid-loading-cover {
  background: #BBBBBB;
  opacity: 0.5;
  position: absolute;
  vertical-align: middle; }

DIV.gj-grid-wrapper DIV.gj-grid-loading-text {
  position: absolute;
  font-weight: bold; }

/* Bootstrap Theme */
table.gj-grid-bootstrap thead th {
  background-color: #f5f5f5;
  vertical-align: middle; }

table.gj-grid-bootstrap thead th [data-role="sorticon"] {
  margin-left: 5px; }

table.gj-grid-bootstrap thead th [data-role="sorticon"] i.gj-icon,
table.gj-grid-bootstrap thead th [data-role="sorticon"] i.material-icons {
  position: absolute;
  font-size: 20px;
  top: 15px; }

table.gj-grid-bootstrap tbody tr td div[data-role="display"] {
  padding: 0px; }

.gj-grid-bootstrap-4 .gj-checkbox-bootstrap {
  display: inline-block;
  padding-top: 2px; }

/* Material Design Theme */
.gj-grid-md {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  background-color: #fff; }

.gj-grid-md td:first-of-type, .gj-grid-md th:first-of-type {
  padding-left: 24px; }

.gj-grid-md th {
  position: relative;
  vertical-align: bottom;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0;
  height: 56px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  box-sizing: border-box;
  padding: 12px 18px;
  text-align: right; }

.gj-grid-md td {
  position: relative;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 18px;
  box-sizing: border-box;
  text-align: left;
  color: rgba(0, 0, 0, 0.87); }

.gj-grid-md tbody tr {
  position: relative;
  height: 48px;
  transition-duration: .28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color; }

.gj-grid-md tbody tr:hover {
  background-color: #EEEEEE;
  /* Gray 200 */ }

.gj-grid-md tbody tr.gj-grid-md-select {
  background-color: #F5F5F5;
  /* Grey 100 */ }

table.gj-grid-md thead th [data-role="sorticon"] {
  margin-left: 5px; }

table.gj-grid-md thead th [data-role="sorticon"] i.gj-icon,
table.gj-grid-md thead th [data-role="sorticon"] i.material-icons {
  position: absolute;
  font-size: 16px;
  top: 19px; }

table.gj-grid-md thead th.gj-grid-select-all {
  padding-bottom: 3px; }

/* Hide all prioritized columns by default */
@media only all {
  th.display-1120,
  td.display-1120,
  th.display-960,
  td.display-960,
  th.display-800,
  td.display-800,
  th.display-640,
  td.display-640,
  th.display-480,
  td.display-480,
  th.display-320,
  td.display-320 {
    display: none; } }

/* Show at 320px (20em x 16px) */
@media screen and (min-width: 20em) {
  TABLE.gj-grid-bootstrap th.display-320,
  TABLE.gj-grid-bootstrap td.display-320 {
    display: table-cell; } }

/* Show at 480px (30em x 16px) */
@media screen and (min-width: 30em) {
  TABLE.gj-grid-bootstrap th.display-480,
  TABLE.gj-grid-bootstrap td.display-480 {
    display: table-cell; } }

/* Show at 640px (40em x 16px) */
@media screen and (min-width: 40em) {
  TABLE.gj-grid-bootstrap th.display-640,
  TABLE.gj-grid-bootstrap td.display-640 {
    display: table-cell; } }

/* Show at 800px (50em x 16px) */
@media screen and (min-width: 50em) {
  TABLE.gj-grid-bootstrap th.display-800,
  TABLE.gj-grid-bootstrap td.display-800 {
    display: table-cell; } }

/* Show at 960px (60em x 16px) */
@media screen and (min-width: 60em) {
  TABLE.gj-grid-bootstrap th.display-960,
  TABLE.gj-grid-bootstrap td.display-960 {
    display: table-cell; } }

/* Show at 1,120px (70em x 16px) */
@media screen and (min-width: 70em) {
  TABLE.gj-grid-bootstrap th.display-1120,
  TABLE.gj-grid-bootstrap td.display-1120 {
    display: table-cell; } }

/* Material Design Theme */
.gj-grid-md tfoot tr th {
  padding-right: 14px; }

.gj-grid-md tfoot tr[data-role="pager"] .gj-grid-mdl-pager-label {
  padding-left: 5px;
  padding-right: 5px; }

.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md {
  margin-left: 12px; }

.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md [role="presenter"] {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54); }

.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md [role="presenter"] [role="display"] {
  text-align: right; }

.gj-grid-md tfoot tr[data-role="pager"] .gj-grid-md-limit-select {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54); }

/* Bootstrap */
.gj-grid-bootstrap tfoot tr[data-role="pager"] th {
  line-height: 30px;
  background-color: #f5f5f5; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > div {
  margin-right: 5px; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > button {
  margin-right: 5px; }

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div button {
  height: 34px; }

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th div .gj-dropdown-bootstrap-4 .gj-dropdown-expander-mi .gj-icon {
  top: 5px; }

.gj-grid-bootstrap-3 tfoot tr[data-role="pager"] th > div > input {
  margin-right: 5px;
  width: 40px;
  text-align: right;
  display: inline-block;
  font-weight: bold; }

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div > div.input-group {
  width: 40px; }

.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div > div.input-group input {
  text-align: right;
  font-weight: bold;
  height: 34px;
  padding-top: 2px;
  padding-bottom: 6px; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > select {
  display: inline-block;
  margin-right: 5px;
  width: 60px; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap .gj-list-bootstrap [data-role="display"] {
  line-height: 14px; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap [role="presenter"] [role="display"] {
  font-weight: bold; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap-3 [role="presenter"] {
  padding: 2px 8px; }

.gj-grid-bootstrap tfoot tr[data-role="pager"] th .gj-dropdown-bootstrap-4 [role="presenter"] {
  padding: 1px 8px; }

.gj-grid thead tr th div.gj-grid-column-resizer-wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  top: 0px;
  left: 0px;
  padding: 0px; }

span.gj-grid-column-resizer {
  position: absolute;
  right: 0px;
  width: 10px;
  top: -100px;
  height: 300px;
  z-index: 1203;
  cursor: e-resize; }

.gj-grid-resize-cursor {
  cursor: e-resize; }

.gj-grid-md tbody tr.gj-grid-top-border td {
  border-top: 2px solid #777; }

.gj-grid-md tbody tr.gj-grid-bottom-border td {
  border-bottom: 2px solid #777; }

.gj-grid-bootstrap tbody tr.gj-grid-top-border td {
  border-top: 2px solid #777; }

.gj-grid-bootstrap tbody tr.gj-grid-bottom-border td {
  border-bottom: 2px solid #777; }

.gj-grid-md thead tr th.gj-grid-left-border,
.gj-grid-md tbody tr td.gj-grid-left-border {
  border-left: 3px solid #777; }

.gj-grid-md thead tr th.gj-grid-right-border,
.gj-grid-md tbody tr td.gj-grid-right-border {
  border-right: 3px solid #777; }

.gj-grid-bootstrap thead tr th.gj-grid-left-border,
.gj-grid-bootstrap tbody tr td.gj-grid-left-border {
  border-left: 5px solid #ddd; }

.gj-grid-bootstrap thead tr th.gj-grid-right-border,
.gj-grid-bootstrap tbody tr td.gj-grid-right-border {
  border-right: 5px solid #ddd; }

.gj-dirty {
  position: absolute;
  top: 0px;
  left: 0px;
  border-style: solid;
  border-width: 3px;
  border-color: #f00 transparent transparent #f00;
  padding: 0;
  overflow: hidden;
  vertical-align: top; }

/* Material Design */
.gj-grid-md tbody tr td.gj-grid-management-column {
  padding: 3px; }

.gj-grid-md tbody tr td[data-mode="edit"] {
  padding: 0px 18px; }

.gj-grid-md tbody .gj-dropdown-md [role="presenter"] [role="display"] {
  padding: 0px; }

/* Bootstrap */
.gj-grid-bootstrap tbody tr td[data-mode="edit"] {
  padding: 0px; }

.gj-grid-bootstrap tbody tr td[data-mode="edit"] [data-role="edit"] {
  padding: 0px; }

/* Bootstrap 3 */
.gj-grid-bootstrap-3 tbody tr td.gj-grid-management-column {
  padding: 3px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] {
  height: 38px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] [data-role="edit"] input[type="text"] {
  height: 37px;
  padding: 8px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap [role="presenter"] {
  border: 0px;
  border-radius: 0px;
  height: 37px;
  padding-left: 8px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap {
  height: 37px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="input"] {
  height: 37px;
  border: 0px;
  border-radius: 0px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] {
  border: 0px;
  border-radius: 0px; }

.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-checkbox-bootstrap {
  display: inline-block;
  padding-top: 10px;
  height: 32px; }

/* Bootstrap 4 */
.gj-grid-bootstrap-4 tbody tr td.gj-grid-management-column {
  padding: 6px; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] [data-role="edit"] input[type="text"] {
  height: 48px;
  padding-left: 12px; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap [role="presenter"] {
  border: 0px;
  border-radius: 0px;
  height: 48px;
  padding-left: 12px;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap-4 [role="expander"].gj-dropdown-expander-mi .gj-icon,
.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-dropdown-bootstrap-4 [role="expander"].gj-dropdown-expander-mi .material-icons {
  top: 13px; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap {
  height: 48px; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="input"] {
  height: 48px;
  border: 0px;
  border-radius: 0px; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] {
  background-color: #fff; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] button {
  border: 0px;
  border-radius: 0px;
  width: 43px;
  position: relative; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] .gj-icon,
.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap [role="right-icon"] .material-icons {
  top: 13px;
  left: 10px;
  font-size: 24px; }

.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-checkbox-bootstrap {
  display: inline-block;
  padding-top: 15px;
  height: 42px; }

.gj-grid-md thead tr[data-role="filter"] th {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

div.gj-grid-wrapper div.gj-grid-bootstrap-toolbar {
  background-color: #f5f5f5;
  padding: 8px;
  font-weight: bold;
  border: 1px solid #ddd; }

div.gj-grid-wrapper div.gj-grid-bootstrap-4-toolbar {
  background-color: #f5f5f5;
  padding: 12px;
  font-weight: bold;
  border: 1px solid #ddd; }

div.gj-grid-wrapper div.gj-grid-md-toolbar {
  font-weight: bold;
  font-size: 24px;
  font-family: "Helvetica","Arial",sans-serif;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 0px;
  border-collapse: collapse;
  padding: 0 18px 0px 18px;
  line-height: 56px; }

table.gj-grid-scrollable tbody {
  overflow-y: auto;
  overflow-x: hidden;
  display: block; }

/* Material Design */
table.gj-grid-md.gj-grid-scrollable {
  border-bottom: 0px; }

table.gj-grid-md.gj-grid-scrollable tbody {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

table.gj-grid-md.gj-grid-scrollable tfoot {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

/* Bootstrap 3 */
table.gj-grid-bootstrap.gj-grid-scrollable {
  border-bottom: 0px; }

table.gj-grid-bootstrap.gj-grid-scrollable tbody {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

table.gj-grid-bootstrap.gj-grid-scrollable tbody tr[data-role="row"]:first-child td {
  border-top: 0px; }

table.gj-grid-bootstrap.gj-grid-scrollable tbody tr[data-role="row"] td:first-child {
  border-left: 0px; }

table.gj-grid-bootstrap.gj-grid-scrollable tbody tr[data-role="row"] td:last-child {
  border-right: 0px; }

table.gj-grid-bootstrap.gj-grid-scrollable tfoot {
  border-bottom: 1px solid #ddd; }

ul.gj-list li [data-role="spacer"] {
  display: table-cell; }

ul.gj-list li [data-role="expander"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer; }

[data-type="tree"] ul li [data-role="expander"].gj-tree-material-icons-expander {
  width: 24px; }

[data-type="tree"] ul li [data-role="expander"].gj-tree-font-awesome-expander {
  width: 24px; }

[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander {
  width: 24px; }

[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander .glyphicon {
  top: 4px;
  height: 24px; }

/* Bootstrap Theme */
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li {
  border: 0px;
  border-radius: 0px;
  color: #333; }

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.active {
  color: #fff; }

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.disabled {
  color: #777;
  background-color: #eee; }

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li {
  border: 0px;
  border-radius: 0px;
  color: #212529; }

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.active {
  color: #fff; }

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.disabled {
  color: #868e96; }

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li ul.gj-list-bootstrap {
  width: 100%; }

.gj-tree-bootstrap-border ul.gj-list-bootstrap li {
  border: 1px solid #ddd; }

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li {
  border-left: 0px;
  border-right: 0px; }

.gj-tree-bootstrap-border ul.gj-list-bootstrap li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.gj-tree-bootstrap-border ul.gj-list-bootstrap li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }

.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li:last-child {
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander {
  padding-top: 8px;
  padding-bottom: 4px; }

ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander .gj-icon {
  width: 24px;
  height: 24px; }

/* Material Design Theme */
ul.gj-list-md li.disabled > [data-role="wrapper"] > [data-role="expander"] {
  color: #9E9E9E;
  /* Gray 500 */ }

.gj-tree-md-border ul.gj-list-md li {
  border: 1px solid #616161;
  /* Gray 700 */
  margin-bottom: -1px; }

.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li {
  border-left: 0px;
  border-right: 0px; }

.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li:last-child {
  border-bottom: 0px; }

.gj-tree-drop-above {
  border-top: 1px solid #000; }

.gj-tree-drop-below {
  border-bottom: 1px solid #000; }

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
  border-top: 2px solid #000; }

.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
  border-bottom: 2px solid #000; }

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
  border-top: 2px solid #000; }

.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
  border-bottom: 2px solid #000; }

.gj-tree-drag-el {
  padding: 0px;
  margin: 0px;
  z-index: 1203; }

.gj-tree-drag-el li {
  padding: 0px;
  margin: 0px; }

.gj-tree-drag-el [data-role="wrapper"] {
  cursor: move;
  display: table; }

.gj-tree-drag-el [data-role="indicator"] {
  width: 14px;
  padding: 0px 3px;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.gj-tree-bootstrap-drag-el li.list-group-item {
  border: 0px;
  background: unset; }

.gj-tree-bootstrap-drag-el [data-role="indicator"] {
  width: 24px;
  height: 24px;
  padding: 0px; }

.gj-tree-md-drag-el [data-role="indicator"] {
  width: 24px;
  height: 24px;
  padding: 0px; }

/* Bootstrap */
.gj-checkbox-bootstrap {
  min-width: 0;
  font-size: 0;
  font-weight: normal;
  margin: 0px;
  text-align: center;
  width: 18px;
  height: 18px;
  position: relative;
  display: inline; }

.gj-checkbox-bootstrap input[type="checkbox"] {
  display: none;
  margin-bottom: -12px; }

.gj-checkbox-bootstrap span {
  background: #fff;
  display: block;
  content: " ";
  width: 18px;
  height: 18px;
  line-height: 11px;
  font-size: 11px;
  padding: 2px;
  color: #555555;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  transition: box-shadow 0.2s linear, border-color 0.2s linear;
  cursor: pointer;
  margin: auto; }

.gj-checkbox-bootstrap input[type="checkbox"]:focus + span:before {
  outline: 0;
  box-shadow: 0 0 0 0 #66afe9, 0 0 6px rgba(102, 175, 233, 0.6);
  border-color: #66afe9; }

.gj-checkbox-bootstrap input[type="checkbox"][disabled] + span {
  opacity: 0.6;
  cursor: not-allowed; }

/* Bootstrap 4 */
.gj-checkbox-bootstrap.gj-checkbox-bootstrap-4 span {
  line-height: 16px;
  padding: 0px; }

.gj-checkbox-bootstrap-4.gj-checkbox-material-icons input[type="checkbox"]:checked + span:after {
  font-size: 16px; }

.gj-checkbox-bootstrap-4.gj-checkbox-material-icons input[type="checkbox"]:indeterminate + span:after {
  font-size: 16px; }

/* Material Design */
.gj-checkbox-md {
  min-width: 0;
  font-size: 0;
  font-weight: normal;
  margin: 0px;
  text-align: center;
  width: 16px;
  height: 16px;
  position: relative; }

.gj-checkbox-md input[type="checkbox"] {
  display: none;
  margin-bottom: -12px; }

.gj-checkbox-md span {
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid #616161;
  /* Gray 700 */
  border-radius: 2px;
  z-index: 2; }

.gj-checkbox-md input[type="checkbox"]:checked + span {
  border: 2px solid #536DFE;
  /* Indigo A200 */ }

.gj-checkbox-md input[type="checkbox"]:checked + span:after {
  color: #FFF;
  background-color: #536DFE;
  /* Indigo A200 */
  position: absolute;
  left: 1px;
  top: -15px; }

.gj-checkbox-md input[type="checkbox"]:indeterminate + span {
  border: 2px solid #616161;
  /* Gray 700 */ }

.gj-checkbox-md input[type="checkbox"]:indeterminate + span:after {
  color: #616161;
  /*color: rgba(0, 0, 0, 1);*/
  position: absolute;
  left: 1px;
  top: -15px; }

.gj-checkbox-md input[type="checkbox"][disabled] + span {
  border: 2px solid #9E9E9E; }

.gj-checkbox-md input[type="checkbox"][disabled] + span:after {
  background-color: #9E9E9E; }

.gj-checkbox-md input[type="checkbox"][disabled]:indeterminate + span:after {
  color: #FFFFFF; }

/* Material Icons */
.gj-checkbox-material-icons input[type="checkbox"]:checked + span:after {
  content: "\e91f";
  font-size: 14px;
  font-weight: bold;
  white-space: pre; }

.gj-checkbox-material-icons input[type="checkbox"]:indeterminate + span:after {
  content: "\e921";
  font-size: 14px;
  font-weight: bold;
  white-space: pre; }

/* Glyphicons */
.gj-checkbox-glyphicons input[type="checkbox"]:checked + span:after {
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  content: "\e013 "; }

.gj-checkbox-glyphicons input[type="checkbox"]:indeterminate + span:after {
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  content: "\2212 ";
  padding-right: 1px; }

/* fontawesome */
.gj-checkbox-fontawesome .fa {
  font-size: 14px; }

.gj-checkbox-fontawesome input[type="checkbox"]:checked + span:before {
  content: "\f00c "; }

.gj-checkbox-fontawesome input[type="checkbox"]:indeterminate + span:before {
  content: "\f068 "; }

.gj-editor [role="body"] {
  overflow: auto;
  outline: 0px solid transparent;
  box-sizing: border-box; }

/* Material Design */
.gj-editor-md {
  padding: 7px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  border: 1px solid rgba(158, 158, 158, 0.2); }

.gj-editor-md [role="toolbar"] {
  margin-bottom: 7px; }

.gj-editor-md [role="toolbar"] .gj-button-md {
  min-width: 54px;
  margin-right: 5px; }

.gj-editor-md [role="toolbar"] .gj-button-md .gj-icon {
  width: 24px;
  height: 24px; }

.gj-editor-md [role="body"] {
  border: 1px solid rgba(158, 158, 158, 0.2); }

.gj-editor-md p {
  margin: 0;
  padding: 0; }

.gj-editor-md blockquote {
  font-size: 14px; }

/* Bootstrap */
.gj-editor-bootstrap {
  padding: 7px;
  border: 1px solid #eceeef; }

.gj-editor-bootstrap [role="toolbar"] {
  margin-bottom: 7px; }

.gj-editor-bootstrap [role="toolbar"] .btn-group {
  margin-right: 10px; }

.gj-editor-bootstrap [role="toolbar"] button {
  height: 36px; }

.gj-editor-bootstrap [role="body"] {
  border: 1px solid #eceeef; }

.gj-editor-bootstrap p {
  margin: 0;
  padding: 0; }

.gj-editor-bootstrap blockquote {
  font-size: 14px; }

.gj-dropdown {
  position: relative;
  border-collapse: separate; }

.gj-dropdown [role="presenter"] {
  display: table;
  cursor: pointer;
  outline: none;
  position: relative; }

.gj-dropdown [role="presenter"] [role="display"] {
  display: table-cell;
  text-align: left;
  width: 100%; }

.gj-dropdown [role="presenter"] [role="expander"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 24px;
  height: 24px; }

/* Material Design */
.gj-dropdown-md [role="presenter"] {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background: transparent; }

.gj-dropdown-md [role="presenter"]:focus,
.gj-dropdown-md [role="presenter"]:active {
  border-bottom: 2px solid rgba(0, 0, 0, 0.42); }

.gj-dropdown-md [role="presenter"] [role="display"] {
  padding: 4px 0px;
  line-height: 18px; }

.gj-dropdown-md [role="presenter"] [role="display"] .placeholder {
  color: #8e8e8e; }

.gj-dropdown-list-md {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #f5f5f5;
  color: #000;
  margin: 0px;
  z-index: 1203; }

.gj-dropdown-list-md li:hover, .gj-dropdown-list-md li.active {
  background-color: #eee; }

/* Bootstrap */
.gj-dropdown-bootstrap [role="presenter"] [role="display"] {
  padding-right: 5px; }

.gj-dropdown-bootstrap [role="presenter"] [role="expander"] {
  padding-left: 5px; }

.gj-dropdown-bootstrap [role="presenter"] [role="expander"].gj-dropdown-expander-mi {
  width: 24px; }

.gj-dropdown-bootstrap-3 [role="presenter"] [role="display"] {
  line-height: 20px; }

.gj-dropdown-bootstrap-3 [role="presenter"] [role="display"] .placeholder {
  color: #9999b3; }

.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"] {
  width: 20px;
  height: 20px; }

.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .gj-icon,
.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .material-icons {
  top: 5px;
  right: 10px;
  position: absolute; }

.gj-dropdown-bootstrap-4 [role="presenter"] {
  border: 1px solid #ced4da; }

.gj-dropdown-bootstrap-4 [role="presenter"] [role="display"] {
  line-height: 24px; }

.gj-dropdown-bootstrap-4 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .gj-icon,
.gj-dropdown-bootstrap-4 [role="presenter"] [role="expander"].gj-dropdown-expander-mi .material-icons {
  top: 7px;
  right: 10px;
  position: absolute; }

.gj-dropdown-list-bootstrap {
  position: absolute;
  top: 32px;
  left: 0px;
  margin: 0px;
  z-index: 1203; }

.gj-datepicker [role="input"]::-ms-clear {
  display: none; }

.gj-datepicker [role="right-icon"] {
  cursor: pointer; }

.gj-picker div[role="navigator"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.gj-picker div[role="navigator"] div {
  cursor: pointer;
  position: relative;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.gj-picker div[role="navigator"] div[role="period"] {
  width: 100%;
  text-align: center; }

/* Material Design */
.gj-datepicker-md {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  position: relative; }

.gj-datepicker-md [role="right-icon"] {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 24px; }

.gj-datepicker-md.small .gj-textbox-md {
  font-size: 14px; }

.gj-datepicker-md.small .gj-icon {
  font-size: 22px; }

.gj-datepicker-md.large .gj-textbox-md {
  font-size: 18px; }

.gj-datepicker-md.large .gj-icon {
  font-size: 28px; }

.gj-picker-md.datepicker [role="header"] {
  padding: 20px 20px;
  display: block; }

.gj-picker-md.datepicker [role="header"] [role="year"] {
  font-size: 17px;
  padding-bottom: 5px;
  cursor: pointer; }

.gj-picker-md.datepicker [role="header"] [role="date"] {
  font-size: 36px;
  cursor: pointer; }

.gj-picker-md div[role="navigator"] {
  height: 42px;
  line-height: 42px; }

.gj-picker div[role="navigator"] div[role="period"] {
  font-weight: bold;
  font-size: 15px; }

.gj-picker-md div[role="navigator"] div:first-child {
  max-width: 42px; }

.gj-picker-md div[role="navigator"] div:last-child {
  max-width: 42px; }

.gj-picker-md div[role="navigator"] div i.gj-icon,
.gj-picker-md div[role="navigator"] div i.material-icons {
  position: absolute;
  top: 8px; }

.gj-picker-md div[role="navigator"] div:first-child i.gj-icon,
.gj-picker-md div[role="navigator"] div:first-child i.material-icons {
  left: 10px; }

.gj-picker-md div[role="navigator"] div:last-child i.gj-icon,
.gj-picker-md div[role="navigator"] div:last-child i.material-icons {
  right: 11px; }

.gj-picker-md table thead {
  color: #9E9E9E;
  /* Gray 500 */ }

.gj-picker-md table tr th div,
.gj-picker-md table tr td div {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle; }

[type="year"].gj-picker-md table tr td div,
[type="decade"].gj-picker-md table tr td div,
[type="century"].gj-picker-md table tr td div {
  width: 73px;
  height: 73px;
  line-height: 73px;
  cursor: pointer; }

.gj-picker-md table tr td.gj-cursor-pointer div:hover {
  background: #EEEEEE;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87); }

.gj-picker-md table tr td.other-month div,
.gj-picker-md table tr td.disabled div {
  color: #BDBDBD;
  /* Gray 400 */ }

.gj-picker-md table tr td.focused div {
  background: #E0E0E0;
  /* Gray 300 */
  border-radius: 50%; }

.gj-picker-md table tr td.today div {
  color: #1976D2; }

.gj-picker-md table tr td.selected.gj-cursor-pointer div {
  color: #FFFFFF;
  background: #1976D2;
  /* Blue 700 */
  border-radius: 50%; }

.gj-picker-md table tr td.calendar-week div {
  font-weight: bold; }

/* Bootstrap */
.gj-datepicker-bootstrap :focus,
.gj-datepicker-bootstrap :active {
  box-shadow: none; }

.gj-picker-bootstrap {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px; }

.gj-modal .gj-picker-bootstrap {
  padding: 0px; }

.gj-picker-bootstrap.datepicker [role="header"] {
  padding: 10px 20px;
  display: block; }

.gj-picker-bootstrap.datepicker [role="header"] [role="year"] {
  font-size: 15px;
  cursor: pointer; }

.gj-picker-bootstrap [role="header"] [role="date"] {
  font-size: 24px;
  cursor: pointer; }

.gj-modal .gj-picker-bootstrap.datepicker [role="body"] {
  padding: 15px; }

.gj-picker-bootstrap div[role="navigator"] {
  height: 30px;
  line-height: 30px;
  text-align: center; }

.gj-picker-bootstrap div[role="navigator"] div:first-child {
  max-width: 30px; }

.gj-picker-bootstrap div[role="navigator"] div:last-child {
  max-width: 30px; }

.gj-picker-bootstrap table tr td div,
.gj-picker-bootstrap table tr th div {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle; }

[type="year"].gj-picker-bootstrap table tr td div,
[type="decade"].gj-picker-bootstrap table tr td div,
[type="century"].gj-picker-bootstrap table tr td div {
  width: 53px;
  height: 53px;
  line-height: 53px;
  cursor: pointer; }

.gj-picker-bootstrap table tr th div i,
.gj-picker-bootstrap table tr th div span {
  line-height: 30px; }

.gj-picker-bootstrap div[role="navigator"] .gj-icon,
.gj-picker-bootstrap div[role="navigator"] .material-icons {
  margin: 3px; }

.gj-picker-bootstrap table tr td.focused div,
.gj-picker-bootstrap table tr td.gj-cursor-pointer div:hover {
  background: #EEEEEE;
  border-radius: 4px;
  color: #212529; }

.gj-picker-bootstrap table tr td.today div {
  color: #204d74;
  font-weight: bold; }

.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
  border-radius: 4px; }

.gj-picker-bootstrap table tr td.other-month div,
.gj-picker-bootstrap table tr td.disabled div {
  color: #777; }

/* Bootstrap 3 */
.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0px;
  position: relative;
  /*width: 38px;*/ }

.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon .gj-icon,
.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon .material-icons {
  position: absolute;
  top: 7px;
  left: 7px; }

/* Bootstrap 4 */
.gj-datepicker-bootstrap [role="right-icon"] button {
  width: 38px;
  position: relative;
  border: 1px solid #ced4da; }

.gj-datepicker-bootstrap [role="right-icon"] button:hover {
  color: #6c757d;
  background-color: transparent; }

.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button {
  width: 30px; }

.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button {
  width: 48px; }

.gj-datepicker-bootstrap [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap [role="right-icon"] button .material-icons {
  position: absolute;
  font-size: 21px;
  top: 9px;
  left: 9px; }

.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button .material-icons {
  top: 6px;
  left: 6px;
  font-size: 19px; }

.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button .material-icons {
  font-size: 27px;
  top: 10px;
  left: 10px; }

.gj-timepicker [role="input"]::-ms-clear {
  display: none; }

.gj-timepicker [role="right-icon"] {
  cursor: pointer; }

.gj-picker.timepicker [role="header"] {
  font-size: 58px;
  padding: 20px 0;
  line-height: 58px;
  display: flex;
  align-items: baseline;
  user-select: none;
  justify-content: center; }

.gj-picker.timepicker [role="header"] div {
  cursor: pointer;
  width: 66px;
  text-align: right; }

.gj-picker [role="header"] [role="mode"] {
  position: relative;
  width: 0px; }

.gj-picker [role="header"] [role="mode"] span {
  position: absolute;
  left: 7px;
  line-height: 18px;
  font-size: 18px; }

.gj-picker [role="header"] [role="mode"] span[role="am"] {
  top: 7px; }

.gj-picker [role="header"] [role="mode"] span[role="pm"] {
  bottom: 7px; }

.gj-picker [role="body"] [role="dial"] {
  width: 256px;
  color: rgba(0, 0, 0, 0.87);
  height: 256px;
  position: relative;
  background: #eeeeee;
  border-radius: 50%;
  margin: 10px; }

.gj-picker [role="body"] [role="hour"] {
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  user-select: none;
  pointer-events: none; }

.gj-picker [role="body"] [role="hour"].selected {
  color: white; }

.gj-picker [role="body"] [role="arrow"] {
  top: calc(50% - 1px);
  left: 50%;
  width: calc(50% - 20px);
  height: 2px;
  position: absolute;
  pointer-events: none;
  transform-origin: left center;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: calc(50% - 52px); }

.gj-picker .arrow-begin {
  top: -3px;
  left: -4px;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%; }

.gj-picker .arrow-end {
  top: -15px;
  right: -16px;
  width: 0;
  height: 0;
  position: absolute;
  box-sizing: content-box;
  border-width: 16px;
  border-radius: 50%; }

/* Material Design */
.gj-timepicker-md {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .04em;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  position: relative; }

.gj-timepicker-md.small .gj-textbox-md {
  font-size: 14px; }

.gj-timepicker-md.small .gj-icon {
  font-size: 22px; }

.gj-timepicker-md.large .gj-textbox-md {
  font-size: 18px; }

.gj-timepicker-md.large .gj-icon {
  font-size: 28px; }

.gj-timepicker-md [role="right-icon"] {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 24px; }

.gj-picker-md .arrow-begin {
  background-color: #2196f3; }

.gj-picker-md .arrow-end {
  border: 16px solid #2196f3; }

.gj-picker-md [role="body"] [role="arrow"] {
  background-color: #2196f3; }

/* Bootstrap */
.gj-timepicker-bootstrap :focus,
.gj-timepicker-bootstrap :active {
  box-shadow: none; }

.gj-picker-bootstrap [role="body"] [role="arrow"] {
  background-color: #888; }

.gj-picker-bootstrap .arrow-begin {
  background-color: #888; }

.gj-picker-bootstrap .arrow-end {
  border: 16px solid #888; }

/* Bootstrap 3 */
.gj-timepicker-bootstrap .input-group-addon {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0px;
  position: relative;
  width: 38px; }

.gj-timepicker-bootstrap.input-group-sm .input-group-addon {
  width: 30px; }

.gj-timepicker-bootstrap.input-group-lg .input-group-addon {
  width: 46px; }

.gj-timepicker-bootstrap .input-group-addon .gj-icon,
.gj-timepicker-bootstrap .input-group-addon .material-icons {
  position: absolute;
  font-size: 21px;
  top: 6px;
  left: 8px; }

.gj-timepicker-bootstrap.input-group-sm .input-group-addon .gj-icon,
.gj-timepicker-bootstrap.input-group-sm .input-group-addon .material-icons {
  font-size: 19px;
  top: 5px;
  left: 5px; }

.gj-timepicker-bootstrap.input-group-lg .input-group-addon .gj-icon,
.gj-timepicker-bootstrap.input-group-lg .input-group-addon .material-icons {
  font-size: 27px;
  top: 10px;
  left: 10px; }

/* Bootstrap 4 */
.gj-timepicker-bootstrap [role="right-icon"] button {
  width: 38px;
  position: relative; }

.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button {
  width: 30px; }

.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button {
  width: 48px; }

.gj-timepicker-bootstrap [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap [role="right-icon"] button .material-icons {
  position: absolute;
  font-size: 21px;
  top: 7px;
  left: 9px; }

.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button .material-icons {
  top: 4px;
  left: 6px;
  font-size: 19px; }

.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button .material-icons {
  font-size: 27px;
  top: 8px;
  left: 10px; }

.gj-picker.datetimepicker [role="header"] [role="date"] {
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer; }

.gj-picker [role="switch"] {
  align-items: baseline;
  user-select: none;
  position: relative; }

.gj-picker [role="switch"] [role="calendarMode"] {
  cursor: pointer;
  position: absolute;
  bottom: 2px;
  left: 0px; }

.gj-picker [role="switch"] [role="time"] {
  width: 100%;
  text-align: center; }

.gj-picker [role="switch"] [role="time"] div {
  display: inline;
  cursor: pointer; }

.gj-picker [role="switch"] [role="calendarMode"] {
  cursor: pointer; }

.gj-picker [role="switch"] [role="clockMode"] {
  position: absolute;
  right: 0px;
  bottom: 3px;
  cursor: pointer; }

/* Material Design */
.gj-picker-md.datetimepicker [role="header"] {
  font-size: 36px;
  padding: 10px 20px;
  display: block; }

.gj-picker-md [role="switch"] {
  color: rgba(255, 255, 255, 0.54);
  background: #2196f3;
  font-size: 32px; }

/* Bootstrap */
.gj-picker-bootstrap.datetimepicker [role="header"] {
  font-size: 36px;
  padding: 10px 20px;
  display: block; }

.gj-picker-bootstrap.datetimepicker [role="header"] [role="time"] {
  font-size: 22px; }

/* crit:start */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #353535; }

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 2px;
    background-color: #353535;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -11px; }
  .hamburger-inner::after {
    bottom: -11px; }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/* crit:end */
/* crit:start */
@font-face {
  font-family: "g-icons";
  src: url("/www_shared/assets/fonts/g-icons.eot?1598881792");
  src: url("/www_shared/assets/fonts/g-icons.eot?1598881792#iefix") format("eot"), url("/www_shared/assets/fonts/g-icons.woff2?1598881792") format("woff2"), url("/www_shared/assets/fonts/g-icons.woff?1598881792") format("woff"), url("/www_shared/assets/fonts/g-icons.ttf?1598881792") format("truetype"), url("/www_shared/assets/fonts/g-icons.svg?1598881792#g-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="g-icon-"], [class*=" g-icon-"], .g-icon {
  font-family: 'g-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.g-icon-add:before {
  content: ""; }

.g-icon-calendar:before {
  content: ""; }

.g-icon-chev-down:before {
  content: ""; }

.g-icon-chev-left:before {
  content: ""; }

.g-icon-chev-right:before {
  content: ""; }

.g-icon-chev-up:before {
  content: ""; }

.g-icon-close:before {
  content: ""; }

.g-icon-delete:before {
  content: ""; }

.g-icon-doc-excel:before {
  content: ""; }

.g-icon-doc-invoice:before {
  content: ""; }

.g-icon-doc-multi:before {
  content: ""; }

.g-icon-doc-pdf:before {
  content: ""; }

.g-icon-doc-word:before {
  content: ""; }

.g-icon-document:before {
  content: ""; }

.g-icon-download:before {
  content: ""; }

.g-icon-edit:before {
  content: ""; }

.g-icon-facebook:before {
  content: ""; }

.g-icon-help:before {
  content: ""; }

.g-icon-info:before {
  content: ""; }

.g-icon-instagram:before {
  content: ""; }

.g-icon-licensor-shared:before {
  content: ""; }

.g-icon-licensor:before {
  content: ""; }

.g-icon-marker:before {
  content: ""; }

.g-icon-payment:before {
  content: ""; }

.g-icon-phone:before {
  content: ""; }

.g-icon-share:before {
  content: ""; }

.g-icon-tick:before {
  content: ""; }

.g-icon-user-shared:before {
  content: ""; }

.g-icon-user:before {
  content: ""; }

/* crit:end */
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.white-popup-block {
  background: #FFF;
  padding: 20px 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative; }

.mfp-bg, .mfp-wrap {
  z-index: 99999; }

.mfp-close-btn-in .mfp-close {
  font-size: 50px;
  top: 10px;
  right: 10px; }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .collapse {
    height: auto !important;
    display: block; }
  [data-mh] {
    height: auto !important; } }

/* crit:start */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* crit:end */
.slick-slide {
  height: auto;
  flex: 1;
  outline: 0;
  /* crit */ }
  .slick-slide .lt-ie10 {
    height: 100%;
    /* crit */ }

.slick-track {
  display: flex;
  /* crit */ }
  .slick-track:before {
    content: none;
    /* crit */ }
  .lt-ie10 .slick-track:before {
    content: '';
    /* crit */ }

.slick-loading .slick-list {
  background: #fff 50% no-repeat;
  /* crit */ }

.text-core {
  position: relative; }

.text-core .text-wrap {
  background: #fff; }

.text-core .text-wrap textarea,
.text-core .text-wrap input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  outline: none;
  resize: none;
  position: absolute;
  z-index: 1;
  background: none;
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  line-height: 13px;
  height: auto;
  min-height: 36px;
  border: 0;
  padding-top: 5px; }

.text-core .text-wrap .text-tags {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px 35px 3px 10px;
  cursor: text; }

.text-core .text-wrap .text-tags.text-tags-on-top {
  z-index: 2; }

.text-core .text-wrap .text-tags .text-tag {
  float: left; }

.text-core .text-wrap .text-tags .text-tag .text-button {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  float: left;
  border: 1px solid #9daccc;
  background: #e2e6f0;
  color: #000;
  padding: 0px 17px 0px 3px;
  margin: 0 2px 2px 0;
  cursor: pointer;
  height: 16px;
  font: 11px "lucida grande", tahoma, verdana, arial, sans-serif; }

.text-core .text-wrap .text-tags .text-tag .text-button a.text-remove {
  position: absolute;
  right: 3px;
  top: 2px;
  display: block;
  width: 11px;
  height: 11px;
  background: url("close.png") 0 0 no-repeat; }

.text-core .text-wrap .text-tags .text-tag .text-button a.text-remove:hover {
  background-position: 0 -11px; }

.text-core .text-wrap .text-tags .text-tag .text-button a.text-remove:active {
  background-position: 0 -22px; }

.text-core .text-wrap .text-focus {
  -webkit-box-shadow: 0px 0px 6px #6d84b4;
  -moz-box-shadow: 0px 0px 6px #6d84b4;
  box-shadow: 0px 0px 6px #6d84b4;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none; }

.text-core .text-wrap .text-focus.text-show-focus {
  display: block; }

.text-core .text-wrap .text-prompt {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 1px 0 0 2px;
  font: 11px "lucida grande", tahoma, verdana, arial, sans-serif;
  color: #c0c0c0;
  overflow: hidden;
  white-space: pre; }

.text-core .text-wrap .text-prompt.text-hide-prompt {
  display: none; }

.text-core .text-wrap .text-arrow {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  background: url("arrow.png") 50% 50% no-repeat;
  cursor: pointer;
  z-index: 2; }

.text-core .text-wrap .text-dropdown {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  z-index: 3;
  background: #fff;
  border: 1px solid #9daccc;
  width: 100%;
  max-height: 100px;
  padding: 1px;
  font: 11px "lucida grande", tahoma, verdana, arial, sans-serif;
  display: none;
  overflow-x: hidden;
  overflow-y: auto; }

.text-core .text-wrap .text-dropdown.text-position-below {
  margin-top: 1px; }

.text-core .text-wrap .text-dropdown.text-position-above {
  margin-bottom: 1px; }

.text-core .text-wrap .text-dropdown .text-list .text-suggestion {
  padding: 3px 5px;
  cursor: pointer; }

.text-core .text-wrap .text-dropdown .text-list .text-suggestion em {
  font-style: normal;
  text-decoration: underline; }

.text-core .text-wrap .text-dropdown .text-list .text-suggestion.text-selected {
  color: #fff;
  background: #6d84b4; }

/* crit:start */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* crit:end */
/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

*:focus {
  outline: 0; }

/* crit:start */
*,
*:before,
*:after {
  flex: 0 1 auto; }

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar; }

body {
  min-height: 100vh;
  color: #2c2c2c;
  font-family: "Roboto", Arial, sans-serif; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

input {
  font-size: 15px;
  line-height: 1.6;
  border-radius: 0;
  /* crit */ }
  input[type=search], input[type=text], input[type=password], input[type=tel], input[type=number], input[type=email] {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #dedede;
    min-height: 36px; }
  input:invalid {
    box-shadow: none;
    /* crit */ }
  input::placeholder {
    color: rgba(68, 68, 68, 0.8);
    /* crit */ }
  input.error {
    border-color: red; }
  input::-ms-clear {
    display: none; }

table {
  width: 100%; }
  table td, table th {
    padding: 15px; }
    table td:first-child, table th:first-child {
      padding-left: 0; }
  table th {
    color: #444444;
    text-align: left; }
  table tr {
    border-bottom: 1px solid #eeeeee; }
  table thead tr {
    border-bottom: 3px solid #eeeeee; }

select, textarea {
  appearance: none;
  border-radius: 0;
  display: block;
  padding: 11px 18px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eeeeee; }
  select:disabled, textarea:disabled {
    opacity: .5; }
  select:focus, textarea:focus {
    box-shadow: 0 0 10px 0 rgba(204, 218, 225, 0.7);
    background-color: #fff; }

label {
  font-weight: 700; }

select {
  border-radius: 0;
  background: #fff url("/www_shared/assets/img/chev-down.svg") calc(100% - 5px) 50% no-repeat;
  background-size: 24px auto;
  padding: 5px 10px;
  border-color: #dedede; }
  select::-ms-expand {
    display: none; }

textarea {
  overflow: auto;
  resize: none;
  resize: vertical; }

img {
  max-width: 100%;
  height: auto; }

main {
  padding-top: 50px;
  padding-bottom: 50px; }

/* crit:end */
h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1 {
  font-size: 40px; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 30px; }

h4 {
  font-size: 28px; }

h5 {
  font-size: 24px; }

h6 {
  font-size: 18px; }

.b-fs1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5; }

.b-fs2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3333333333; }

.b-fs3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2; }

.b-fs4 {
  font-weight: 900;
  font-size: 30px;
  line-height: 1.2; }

.b-fs4 {
  font-weight: 900;
  font-size: 30px;
  line-height: 1.2; }

.b-fs5 {
  font-weight: 900;
  font-size: 48px;
  line-height: 1.125; }

.b-fs6 {
  font-weight: 900;
  font-size: 60px;
  line-height: 1.1; }

.b-fsbtnsmall {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 11px;
  line-height: 1.6363636364; }

.b-fsblockquote {
  font-style: italic;
  font-size: 23px;
  line-height: 1.5652173913; }

.b-fsbodysmall {
  font-size: 16px;
  line-height: 1.875; }

.b-fsbodytiny {
  font-size: 14px;
  line-height: 2.1428571429; }

p {
  margin-bottom: 30px; }
  p:empty {
    display: none; }

.lead {
  font-weight: 300;
  font-size: 21px;
  line-height: 1.4285714286; }

small,
.small {
  font-size: 12px;
  line-height: 1.5; }

.g-icon-close {
  transform: rotate(45deg); }

.ui-datepicker .ui-datepicker-title select {
  margin: 0 auto;
  padding: 0;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  background-color: transparent; }

.u-btn-unstyled {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent; }
  .u-btn-unstyled:hover, .u-btn-unstyled:focus {
    text-decoration: none;
    background-color: transparent; }

.u-list-unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.u-input-unstyled {
  border: 0; }

/* crit:start */
.u-bd {
  border: 1px solid #eeeeee; }
  .u-bd0 {
    border: 0; }

.u-bdt {
  border-top: 1px solid #eeeeee; }
  .u-bdt0 {
    border-top: 0; }

.u-bdl {
  border-left: 1px solid #eeeeee; }
  .u-bdl0 {
    border-left: 0; }

.u-bdr {
  border-right: 1px solid #eeeeee; }
  .u-bdr0 {
    border-right: 0; }

.u-bdb {
  border-bottom: 1px solid #eeeeee; }
  .u-bdb0 {
    border-bottom: 0; }

.u-bdra0 {
  border-radius: 0; }

.u-bdra50p {
  border-radius: 50%; }

.u-link-tdn:hover, .u-link-tdn:focus {
  text-decoration: none; }

.u-link-colorstay {
  color: inherit; }
  .u-link-colorstay:hover, .u-link-colorstay:focus {
    color: inherit; }

.u-m0 {
  margin: 0px; }

.u-mtb0 {
  margin-top: 0px; }

.u-myb0 {
  margin-top: 0px; }

.u-mrb0 {
  margin-right: 0px; }

.u-mxb0 {
  margin-right: 0px; }

.u-mbb0 {
  margin-bottom: 0px; }

.u-myb0 {
  margin-bottom: 0px; }

.u-mlb0 {
  margin-left: 0px; }

.u-mxb0 {
  margin-left: 0px; }

.u-mtb1 {
  margin-top: 6px; }

.u-myb1 {
  margin-top: 6px; }

.u-mrb1 {
  margin-right: 6px; }

.u-mxb1 {
  margin-right: 6px; }

.u-mbb1 {
  margin-bottom: 6px; }

.u-myb1 {
  margin-bottom: 6px; }

.u-mlb1 {
  margin-left: 6px; }

.u-mxb1 {
  margin-left: 6px; }

.u-mtb2 {
  margin-top: 12px; }

.u-myb2 {
  margin-top: 12px; }

.u-mrb2 {
  margin-right: 12px; }

.u-mxb2 {
  margin-right: 12px; }

.u-mbb2 {
  margin-bottom: 12px; }

.u-myb2 {
  margin-bottom: 12px; }

.u-mlb2 {
  margin-left: 12px; }

.u-mxb2 {
  margin-left: 12px; }

.u-mtb3 {
  margin-top: 18px; }

.u-myb3 {
  margin-top: 18px; }

.u-mrb3 {
  margin-right: 18px; }

.u-mxb3 {
  margin-right: 18px; }

.u-mbb3 {
  margin-bottom: 18px; }

.u-myb3 {
  margin-bottom: 18px; }

.u-mlb3 {
  margin-left: 18px; }

.u-mxb3 {
  margin-left: 18px; }

.u-mtb4 {
  margin-top: 24px; }

.u-myb4 {
  margin-top: 24px; }

.u-mrb4 {
  margin-right: 24px; }

.u-mxb4 {
  margin-right: 24px; }

.u-mbb4 {
  margin-bottom: 24px; }

.u-myb4 {
  margin-bottom: 24px; }

.u-mlb4 {
  margin-left: 24px; }

.u-mxb4 {
  margin-left: 24px; }

.u-mtb5 {
  margin-top: 30px; }

.u-myb5 {
  margin-top: 30px; }

.u-mrb5 {
  margin-right: 30px; }

.u-mxb5 {
  margin-right: 30px; }

.u-mbb5 {
  margin-bottom: 30px; }

.u-myb5 {
  margin-bottom: 30px; }

.u-mlb5 {
  margin-left: 30px; }

.u-mxb5 {
  margin-left: 30px; }

.u-mtb6 {
  margin-top: 36px; }

.u-myb6 {
  margin-top: 36px; }

.u-mrb6 {
  margin-right: 36px; }

.u-mxb6 {
  margin-right: 36px; }

.u-mbb6 {
  margin-bottom: 36px; }

.u-myb6 {
  margin-bottom: 36px; }

.u-mlb6 {
  margin-left: 36px; }

.u-mxb6 {
  margin-left: 36px; }

.u-mtb7 {
  margin-top: 42px; }

.u-myb7 {
  margin-top: 42px; }

.u-mrb7 {
  margin-right: 42px; }

.u-mxb7 {
  margin-right: 42px; }

.u-mbb7 {
  margin-bottom: 42px; }

.u-myb7 {
  margin-bottom: 42px; }

.u-mlb7 {
  margin-left: 42px; }

.u-mxb7 {
  margin-left: 42px; }

.u-mtb8 {
  margin-top: 48px; }

.u-myb8 {
  margin-top: 48px; }

.u-mrb8 {
  margin-right: 48px; }

.u-mxb8 {
  margin-right: 48px; }

.u-mbb8 {
  margin-bottom: 48px; }

.u-myb8 {
  margin-bottom: 48px; }

.u-mlb8 {
  margin-left: 48px; }

.u-mxb8 {
  margin-left: 48px; }

.u-mtb9 {
  margin-top: 54px; }

.u-myb9 {
  margin-top: 54px; }

.u-mrb9 {
  margin-right: 54px; }

.u-mxb9 {
  margin-right: 54px; }

.u-mbb9 {
  margin-bottom: 54px; }

.u-myb9 {
  margin-bottom: 54px; }

.u-mlb9 {
  margin-left: 54px; }

.u-mxb9 {
  margin-left: 54px; }

.u-mtb10 {
  margin-top: 60px; }

.u-myb10 {
  margin-top: 60px; }

.u-mrb10 {
  margin-right: 60px; }

.u-mxb10 {
  margin-right: 60px; }

.u-mbb10 {
  margin-bottom: 60px; }

.u-myb10 {
  margin-bottom: 60px; }

.u-mlb10 {
  margin-left: 60px; }

.u-mxb10 {
  margin-left: 60px; }

.u-p0 {
  padding: 0px; }

.u-ptb0 {
  padding-top: 0px; }

.u-pyb0 {
  padding-top: 0px; }

.u-prb0 {
  padding-right: 0px; }

.u-pxb0 {
  padding-right: 0px; }

.u-pbb0 {
  padding-bottom: 0px; }

.u-pyb0 {
  padding-bottom: 0px; }

.u-plb0 {
  padding-left: 0px; }

.u-pxb0 {
  padding-left: 0px; }

.u-ptb1 {
  padding-top: 6px; }

.u-pyb1 {
  padding-top: 6px; }

.u-prb1 {
  padding-right: 6px; }

.u-pxb1 {
  padding-right: 6px; }

.u-pbb1 {
  padding-bottom: 6px; }

.u-pyb1 {
  padding-bottom: 6px; }

.u-plb1 {
  padding-left: 6px; }

.u-pxb1 {
  padding-left: 6px; }

.u-ptb2 {
  padding-top: 12px; }

.u-pyb2 {
  padding-top: 12px; }

.u-prb2 {
  padding-right: 12px; }

.u-pxb2 {
  padding-right: 12px; }

.u-pbb2 {
  padding-bottom: 12px; }

.u-pyb2 {
  padding-bottom: 12px; }

.u-plb2 {
  padding-left: 12px; }

.u-pxb2 {
  padding-left: 12px; }

.u-ptb3 {
  padding-top: 18px; }

.u-pyb3 {
  padding-top: 18px; }

.u-prb3 {
  padding-right: 18px; }

.u-pxb3 {
  padding-right: 18px; }

.u-pbb3 {
  padding-bottom: 18px; }

.u-pyb3 {
  padding-bottom: 18px; }

.u-plb3 {
  padding-left: 18px; }

.u-pxb3 {
  padding-left: 18px; }

.u-ptb4 {
  padding-top: 24px; }

.u-pyb4 {
  padding-top: 24px; }

.u-prb4 {
  padding-right: 24px; }

.u-pxb4 {
  padding-right: 24px; }

.u-pbb4 {
  padding-bottom: 24px; }

.u-pyb4 {
  padding-bottom: 24px; }

.u-plb4 {
  padding-left: 24px; }

.u-pxb4 {
  padding-left: 24px; }

.u-ptb5 {
  padding-top: 30px; }

.u-pyb5 {
  padding-top: 30px; }

.u-prb5 {
  padding-right: 30px; }

.u-pxb5 {
  padding-right: 30px; }

.u-pbb5 {
  padding-bottom: 30px; }

.u-pyb5 {
  padding-bottom: 30px; }

.u-plb5 {
  padding-left: 30px; }

.u-pxb5 {
  padding-left: 30px; }

.u-ptb6 {
  padding-top: 36px; }

.u-pyb6 {
  padding-top: 36px; }

.u-prb6 {
  padding-right: 36px; }

.u-pxb6 {
  padding-right: 36px; }

.u-pbb6 {
  padding-bottom: 36px; }

.u-pyb6 {
  padding-bottom: 36px; }

.u-plb6 {
  padding-left: 36px; }

.u-pxb6 {
  padding-left: 36px; }

.u-ptb7 {
  padding-top: 42px; }

.u-pyb7 {
  padding-top: 42px; }

.u-prb7 {
  padding-right: 42px; }

.u-pxb7 {
  padding-right: 42px; }

.u-pbb7 {
  padding-bottom: 42px; }

.u-pyb7 {
  padding-bottom: 42px; }

.u-plb7 {
  padding-left: 42px; }

.u-pxb7 {
  padding-left: 42px; }

.u-ptb8 {
  padding-top: 48px; }

.u-pyb8 {
  padding-top: 48px; }

.u-prb8 {
  padding-right: 48px; }

.u-pxb8 {
  padding-right: 48px; }

.u-pbb8 {
  padding-bottom: 48px; }

.u-pyb8 {
  padding-bottom: 48px; }

.u-plb8 {
  padding-left: 48px; }

.u-pxb8 {
  padding-left: 48px; }

.u-ptb9 {
  padding-top: 54px; }

.u-pyb9 {
  padding-top: 54px; }

.u-prb9 {
  padding-right: 54px; }

.u-pxb9 {
  padding-right: 54px; }

.u-pbb9 {
  padding-bottom: 54px; }

.u-pyb9 {
  padding-bottom: 54px; }

.u-plb9 {
  padding-left: 54px; }

.u-pxb9 {
  padding-left: 54px; }

.u-ptb10 {
  padding-top: 60px; }

.u-pyb10 {
  padding-top: 60px; }

.u-prb10 {
  padding-right: 60px; }

.u-pxb10 {
  padding-right: 60px; }

.u-pbb10 {
  padding-bottom: 60px; }

.u-pyb10 {
  padding-bottom: 60px; }

.u-plb10 {
  padding-left: 60px; }

.u-pxb10 {
  padding-left: 60px; }

.u-mra {
  margin-right: auto; }

.u-mxa {
  margin-right: auto; }

.u-mla {
  margin-left: auto; }

.u-mxa {
  margin-left: auto; }

@media (min-width: 768px) {
  .u-m0-sm {
    margin: 0px; }
  .u-mtb0-sm {
    margin-top: 0px; }
  .u-myb0-sm {
    margin-top: 0px; }
  .u-mrb0-sm {
    margin-right: 0px; }
  .u-mxb0-sm {
    margin-right: 0px; }
  .u-mbb0-sm {
    margin-bottom: 0px; }
  .u-myb0-sm {
    margin-bottom: 0px; }
  .u-mlb0-sm {
    margin-left: 0px; }
  .u-mxb0-sm {
    margin-left: 0px; }
  .u-mtb1-sm {
    margin-top: 6px; }
  .u-myb1-sm {
    margin-top: 6px; }
  .u-mrb1-sm {
    margin-right: 6px; }
  .u-mxb1-sm {
    margin-right: 6px; }
  .u-mbb1-sm {
    margin-bottom: 6px; }
  .u-myb1-sm {
    margin-bottom: 6px; }
  .u-mlb1-sm {
    margin-left: 6px; }
  .u-mxb1-sm {
    margin-left: 6px; }
  .u-mtb2-sm {
    margin-top: 12px; }
  .u-myb2-sm {
    margin-top: 12px; }
  .u-mrb2-sm {
    margin-right: 12px; }
  .u-mxb2-sm {
    margin-right: 12px; }
  .u-mbb2-sm {
    margin-bottom: 12px; }
  .u-myb2-sm {
    margin-bottom: 12px; }
  .u-mlb2-sm {
    margin-left: 12px; }
  .u-mxb2-sm {
    margin-left: 12px; }
  .u-mtb3-sm {
    margin-top: 18px; }
  .u-myb3-sm {
    margin-top: 18px; }
  .u-mrb3-sm {
    margin-right: 18px; }
  .u-mxb3-sm {
    margin-right: 18px; }
  .u-mbb3-sm {
    margin-bottom: 18px; }
  .u-myb3-sm {
    margin-bottom: 18px; }
  .u-mlb3-sm {
    margin-left: 18px; }
  .u-mxb3-sm {
    margin-left: 18px; }
  .u-mtb4-sm {
    margin-top: 24px; }
  .u-myb4-sm {
    margin-top: 24px; }
  .u-mrb4-sm {
    margin-right: 24px; }
  .u-mxb4-sm {
    margin-right: 24px; }
  .u-mbb4-sm {
    margin-bottom: 24px; }
  .u-myb4-sm {
    margin-bottom: 24px; }
  .u-mlb4-sm {
    margin-left: 24px; }
  .u-mxb4-sm {
    margin-left: 24px; }
  .u-mtb5-sm {
    margin-top: 30px; }
  .u-myb5-sm {
    margin-top: 30px; }
  .u-mrb5-sm {
    margin-right: 30px; }
  .u-mxb5-sm {
    margin-right: 30px; }
  .u-mbb5-sm {
    margin-bottom: 30px; }
  .u-myb5-sm {
    margin-bottom: 30px; }
  .u-mlb5-sm {
    margin-left: 30px; }
  .u-mxb5-sm {
    margin-left: 30px; }
  .u-mtb6-sm {
    margin-top: 36px; }
  .u-myb6-sm {
    margin-top: 36px; }
  .u-mrb6-sm {
    margin-right: 36px; }
  .u-mxb6-sm {
    margin-right: 36px; }
  .u-mbb6-sm {
    margin-bottom: 36px; }
  .u-myb6-sm {
    margin-bottom: 36px; }
  .u-mlb6-sm {
    margin-left: 36px; }
  .u-mxb6-sm {
    margin-left: 36px; }
  .u-mtb7-sm {
    margin-top: 42px; }
  .u-myb7-sm {
    margin-top: 42px; }
  .u-mrb7-sm {
    margin-right: 42px; }
  .u-mxb7-sm {
    margin-right: 42px; }
  .u-mbb7-sm {
    margin-bottom: 42px; }
  .u-myb7-sm {
    margin-bottom: 42px; }
  .u-mlb7-sm {
    margin-left: 42px; }
  .u-mxb7-sm {
    margin-left: 42px; }
  .u-mtb8-sm {
    margin-top: 48px; }
  .u-myb8-sm {
    margin-top: 48px; }
  .u-mrb8-sm {
    margin-right: 48px; }
  .u-mxb8-sm {
    margin-right: 48px; }
  .u-mbb8-sm {
    margin-bottom: 48px; }
  .u-myb8-sm {
    margin-bottom: 48px; }
  .u-mlb8-sm {
    margin-left: 48px; }
  .u-mxb8-sm {
    margin-left: 48px; }
  .u-mtb9-sm {
    margin-top: 54px; }
  .u-myb9-sm {
    margin-top: 54px; }
  .u-mrb9-sm {
    margin-right: 54px; }
  .u-mxb9-sm {
    margin-right: 54px; }
  .u-mbb9-sm {
    margin-bottom: 54px; }
  .u-myb9-sm {
    margin-bottom: 54px; }
  .u-mlb9-sm {
    margin-left: 54px; }
  .u-mxb9-sm {
    margin-left: 54px; }
  .u-mtb10-sm {
    margin-top: 60px; }
  .u-myb10-sm {
    margin-top: 60px; }
  .u-mrb10-sm {
    margin-right: 60px; }
  .u-mxb10-sm {
    margin-right: 60px; }
  .u-mbb10-sm {
    margin-bottom: 60px; }
  .u-myb10-sm {
    margin-bottom: 60px; }
  .u-mlb10-sm {
    margin-left: 60px; }
  .u-mxb10-sm {
    margin-left: 60px; }
  .u-p0-sm {
    padding: 0px; }
  .u-ptb0-sm {
    padding-top: 0px; }
  .u-pyb0-sm {
    padding-top: 0px; }
  .u-prb0-sm {
    padding-right: 0px; }
  .u-pxb0-sm {
    padding-right: 0px; }
  .u-pbb0-sm {
    padding-bottom: 0px; }
  .u-pyb0-sm {
    padding-bottom: 0px; }
  .u-plb0-sm {
    padding-left: 0px; }
  .u-pxb0-sm {
    padding-left: 0px; }
  .u-ptb1-sm {
    padding-top: 6px; }
  .u-pyb1-sm {
    padding-top: 6px; }
  .u-prb1-sm {
    padding-right: 6px; }
  .u-pxb1-sm {
    padding-right: 6px; }
  .u-pbb1-sm {
    padding-bottom: 6px; }
  .u-pyb1-sm {
    padding-bottom: 6px; }
  .u-plb1-sm {
    padding-left: 6px; }
  .u-pxb1-sm {
    padding-left: 6px; }
  .u-ptb2-sm {
    padding-top: 12px; }
  .u-pyb2-sm {
    padding-top: 12px; }
  .u-prb2-sm {
    padding-right: 12px; }
  .u-pxb2-sm {
    padding-right: 12px; }
  .u-pbb2-sm {
    padding-bottom: 12px; }
  .u-pyb2-sm {
    padding-bottom: 12px; }
  .u-plb2-sm {
    padding-left: 12px; }
  .u-pxb2-sm {
    padding-left: 12px; }
  .u-ptb3-sm {
    padding-top: 18px; }
  .u-pyb3-sm {
    padding-top: 18px; }
  .u-prb3-sm {
    padding-right: 18px; }
  .u-pxb3-sm {
    padding-right: 18px; }
  .u-pbb3-sm {
    padding-bottom: 18px; }
  .u-pyb3-sm {
    padding-bottom: 18px; }
  .u-plb3-sm {
    padding-left: 18px; }
  .u-pxb3-sm {
    padding-left: 18px; }
  .u-ptb4-sm {
    padding-top: 24px; }
  .u-pyb4-sm {
    padding-top: 24px; }
  .u-prb4-sm {
    padding-right: 24px; }
  .u-pxb4-sm {
    padding-right: 24px; }
  .u-pbb4-sm {
    padding-bottom: 24px; }
  .u-pyb4-sm {
    padding-bottom: 24px; }
  .u-plb4-sm {
    padding-left: 24px; }
  .u-pxb4-sm {
    padding-left: 24px; }
  .u-ptb5-sm {
    padding-top: 30px; }
  .u-pyb5-sm {
    padding-top: 30px; }
  .u-prb5-sm {
    padding-right: 30px; }
  .u-pxb5-sm {
    padding-right: 30px; }
  .u-pbb5-sm {
    padding-bottom: 30px; }
  .u-pyb5-sm {
    padding-bottom: 30px; }
  .u-plb5-sm {
    padding-left: 30px; }
  .u-pxb5-sm {
    padding-left: 30px; }
  .u-ptb6-sm {
    padding-top: 36px; }
  .u-pyb6-sm {
    padding-top: 36px; }
  .u-prb6-sm {
    padding-right: 36px; }
  .u-pxb6-sm {
    padding-right: 36px; }
  .u-pbb6-sm {
    padding-bottom: 36px; }
  .u-pyb6-sm {
    padding-bottom: 36px; }
  .u-plb6-sm {
    padding-left: 36px; }
  .u-pxb6-sm {
    padding-left: 36px; }
  .u-ptb7-sm {
    padding-top: 42px; }
  .u-pyb7-sm {
    padding-top: 42px; }
  .u-prb7-sm {
    padding-right: 42px; }
  .u-pxb7-sm {
    padding-right: 42px; }
  .u-pbb7-sm {
    padding-bottom: 42px; }
  .u-pyb7-sm {
    padding-bottom: 42px; }
  .u-plb7-sm {
    padding-left: 42px; }
  .u-pxb7-sm {
    padding-left: 42px; }
  .u-ptb8-sm {
    padding-top: 48px; }
  .u-pyb8-sm {
    padding-top: 48px; }
  .u-prb8-sm {
    padding-right: 48px; }
  .u-pxb8-sm {
    padding-right: 48px; }
  .u-pbb8-sm {
    padding-bottom: 48px; }
  .u-pyb8-sm {
    padding-bottom: 48px; }
  .u-plb8-sm {
    padding-left: 48px; }
  .u-pxb8-sm {
    padding-left: 48px; }
  .u-ptb9-sm {
    padding-top: 54px; }
  .u-pyb9-sm {
    padding-top: 54px; }
  .u-prb9-sm {
    padding-right: 54px; }
  .u-pxb9-sm {
    padding-right: 54px; }
  .u-pbb9-sm {
    padding-bottom: 54px; }
  .u-pyb9-sm {
    padding-bottom: 54px; }
  .u-plb9-sm {
    padding-left: 54px; }
  .u-pxb9-sm {
    padding-left: 54px; }
  .u-ptb10-sm {
    padding-top: 60px; }
  .u-pyb10-sm {
    padding-top: 60px; }
  .u-prb10-sm {
    padding-right: 60px; }
  .u-pxb10-sm {
    padding-right: 60px; }
  .u-pbb10-sm {
    padding-bottom: 60px; }
  .u-pyb10-sm {
    padding-bottom: 60px; }
  .u-plb10-sm {
    padding-left: 60px; }
  .u-pxb10-sm {
    padding-left: 60px; }
  .u-mra-sm {
    margin-right: auto; }
  .u-mxa-sm {
    margin-right: auto; }
  .u-mla-sm {
    margin-left: auto; }
  .u-mxa-sm {
    margin-left: auto; } }

@media (min-width: 992px) {
  .u-m0-md {
    margin: 0px; }
  .u-mtb0-md {
    margin-top: 0px; }
  .u-myb0-md {
    margin-top: 0px; }
  .u-mrb0-md {
    margin-right: 0px; }
  .u-mxb0-md {
    margin-right: 0px; }
  .u-mbb0-md {
    margin-bottom: 0px; }
  .u-myb0-md {
    margin-bottom: 0px; }
  .u-mlb0-md {
    margin-left: 0px; }
  .u-mxb0-md {
    margin-left: 0px; }
  .u-mtb1-md {
    margin-top: 6px; }
  .u-myb1-md {
    margin-top: 6px; }
  .u-mrb1-md {
    margin-right: 6px; }
  .u-mxb1-md {
    margin-right: 6px; }
  .u-mbb1-md {
    margin-bottom: 6px; }
  .u-myb1-md {
    margin-bottom: 6px; }
  .u-mlb1-md {
    margin-left: 6px; }
  .u-mxb1-md {
    margin-left: 6px; }
  .u-mtb2-md {
    margin-top: 12px; }
  .u-myb2-md {
    margin-top: 12px; }
  .u-mrb2-md {
    margin-right: 12px; }
  .u-mxb2-md {
    margin-right: 12px; }
  .u-mbb2-md {
    margin-bottom: 12px; }
  .u-myb2-md {
    margin-bottom: 12px; }
  .u-mlb2-md {
    margin-left: 12px; }
  .u-mxb2-md {
    margin-left: 12px; }
  .u-mtb3-md {
    margin-top: 18px; }
  .u-myb3-md {
    margin-top: 18px; }
  .u-mrb3-md {
    margin-right: 18px; }
  .u-mxb3-md {
    margin-right: 18px; }
  .u-mbb3-md {
    margin-bottom: 18px; }
  .u-myb3-md {
    margin-bottom: 18px; }
  .u-mlb3-md {
    margin-left: 18px; }
  .u-mxb3-md {
    margin-left: 18px; }
  .u-mtb4-md {
    margin-top: 24px; }
  .u-myb4-md {
    margin-top: 24px; }
  .u-mrb4-md {
    margin-right: 24px; }
  .u-mxb4-md {
    margin-right: 24px; }
  .u-mbb4-md {
    margin-bottom: 24px; }
  .u-myb4-md {
    margin-bottom: 24px; }
  .u-mlb4-md {
    margin-left: 24px; }
  .u-mxb4-md {
    margin-left: 24px; }
  .u-mtb5-md {
    margin-top: 30px; }
  .u-myb5-md {
    margin-top: 30px; }
  .u-mrb5-md {
    margin-right: 30px; }
  .u-mxb5-md {
    margin-right: 30px; }
  .u-mbb5-md {
    margin-bottom: 30px; }
  .u-myb5-md {
    margin-bottom: 30px; }
  .u-mlb5-md {
    margin-left: 30px; }
  .u-mxb5-md {
    margin-left: 30px; }
  .u-mtb6-md {
    margin-top: 36px; }
  .u-myb6-md {
    margin-top: 36px; }
  .u-mrb6-md {
    margin-right: 36px; }
  .u-mxb6-md {
    margin-right: 36px; }
  .u-mbb6-md {
    margin-bottom: 36px; }
  .u-myb6-md {
    margin-bottom: 36px; }
  .u-mlb6-md {
    margin-left: 36px; }
  .u-mxb6-md {
    margin-left: 36px; }
  .u-mtb7-md {
    margin-top: 42px; }
  .u-myb7-md {
    margin-top: 42px; }
  .u-mrb7-md {
    margin-right: 42px; }
  .u-mxb7-md {
    margin-right: 42px; }
  .u-mbb7-md {
    margin-bottom: 42px; }
  .u-myb7-md {
    margin-bottom: 42px; }
  .u-mlb7-md {
    margin-left: 42px; }
  .u-mxb7-md {
    margin-left: 42px; }
  .u-mtb8-md {
    margin-top: 48px; }
  .u-myb8-md {
    margin-top: 48px; }
  .u-mrb8-md {
    margin-right: 48px; }
  .u-mxb8-md {
    margin-right: 48px; }
  .u-mbb8-md {
    margin-bottom: 48px; }
  .u-myb8-md {
    margin-bottom: 48px; }
  .u-mlb8-md {
    margin-left: 48px; }
  .u-mxb8-md {
    margin-left: 48px; }
  .u-mtb9-md {
    margin-top: 54px; }
  .u-myb9-md {
    margin-top: 54px; }
  .u-mrb9-md {
    margin-right: 54px; }
  .u-mxb9-md {
    margin-right: 54px; }
  .u-mbb9-md {
    margin-bottom: 54px; }
  .u-myb9-md {
    margin-bottom: 54px; }
  .u-mlb9-md {
    margin-left: 54px; }
  .u-mxb9-md {
    margin-left: 54px; }
  .u-mtb10-md {
    margin-top: 60px; }
  .u-myb10-md {
    margin-top: 60px; }
  .u-mrb10-md {
    margin-right: 60px; }
  .u-mxb10-md {
    margin-right: 60px; }
  .u-mbb10-md {
    margin-bottom: 60px; }
  .u-myb10-md {
    margin-bottom: 60px; }
  .u-mlb10-md {
    margin-left: 60px; }
  .u-mxb10-md {
    margin-left: 60px; }
  .u-p0-md {
    padding: 0px; }
  .u-ptb0-md {
    padding-top: 0px; }
  .u-pyb0-md {
    padding-top: 0px; }
  .u-prb0-md {
    padding-right: 0px; }
  .u-pxb0-md {
    padding-right: 0px; }
  .u-pbb0-md {
    padding-bottom: 0px; }
  .u-pyb0-md {
    padding-bottom: 0px; }
  .u-plb0-md {
    padding-left: 0px; }
  .u-pxb0-md {
    padding-left: 0px; }
  .u-ptb1-md {
    padding-top: 6px; }
  .u-pyb1-md {
    padding-top: 6px; }
  .u-prb1-md {
    padding-right: 6px; }
  .u-pxb1-md {
    padding-right: 6px; }
  .u-pbb1-md {
    padding-bottom: 6px; }
  .u-pyb1-md {
    padding-bottom: 6px; }
  .u-plb1-md {
    padding-left: 6px; }
  .u-pxb1-md {
    padding-left: 6px; }
  .u-ptb2-md {
    padding-top: 12px; }
  .u-pyb2-md {
    padding-top: 12px; }
  .u-prb2-md {
    padding-right: 12px; }
  .u-pxb2-md {
    padding-right: 12px; }
  .u-pbb2-md {
    padding-bottom: 12px; }
  .u-pyb2-md {
    padding-bottom: 12px; }
  .u-plb2-md {
    padding-left: 12px; }
  .u-pxb2-md {
    padding-left: 12px; }
  .u-ptb3-md {
    padding-top: 18px; }
  .u-pyb3-md {
    padding-top: 18px; }
  .u-prb3-md {
    padding-right: 18px; }
  .u-pxb3-md {
    padding-right: 18px; }
  .u-pbb3-md {
    padding-bottom: 18px; }
  .u-pyb3-md {
    padding-bottom: 18px; }
  .u-plb3-md {
    padding-left: 18px; }
  .u-pxb3-md {
    padding-left: 18px; }
  .u-ptb4-md {
    padding-top: 24px; }
  .u-pyb4-md {
    padding-top: 24px; }
  .u-prb4-md {
    padding-right: 24px; }
  .u-pxb4-md {
    padding-right: 24px; }
  .u-pbb4-md {
    padding-bottom: 24px; }
  .u-pyb4-md {
    padding-bottom: 24px; }
  .u-plb4-md {
    padding-left: 24px; }
  .u-pxb4-md {
    padding-left: 24px; }
  .u-ptb5-md {
    padding-top: 30px; }
  .u-pyb5-md {
    padding-top: 30px; }
  .u-prb5-md {
    padding-right: 30px; }
  .u-pxb5-md {
    padding-right: 30px; }
  .u-pbb5-md {
    padding-bottom: 30px; }
  .u-pyb5-md {
    padding-bottom: 30px; }
  .u-plb5-md {
    padding-left: 30px; }
  .u-pxb5-md {
    padding-left: 30px; }
  .u-ptb6-md {
    padding-top: 36px; }
  .u-pyb6-md {
    padding-top: 36px; }
  .u-prb6-md {
    padding-right: 36px; }
  .u-pxb6-md {
    padding-right: 36px; }
  .u-pbb6-md {
    padding-bottom: 36px; }
  .u-pyb6-md {
    padding-bottom: 36px; }
  .u-plb6-md {
    padding-left: 36px; }
  .u-pxb6-md {
    padding-left: 36px; }
  .u-ptb7-md {
    padding-top: 42px; }
  .u-pyb7-md {
    padding-top: 42px; }
  .u-prb7-md {
    padding-right: 42px; }
  .u-pxb7-md {
    padding-right: 42px; }
  .u-pbb7-md {
    padding-bottom: 42px; }
  .u-pyb7-md {
    padding-bottom: 42px; }
  .u-plb7-md {
    padding-left: 42px; }
  .u-pxb7-md {
    padding-left: 42px; }
  .u-ptb8-md {
    padding-top: 48px; }
  .u-pyb8-md {
    padding-top: 48px; }
  .u-prb8-md {
    padding-right: 48px; }
  .u-pxb8-md {
    padding-right: 48px; }
  .u-pbb8-md {
    padding-bottom: 48px; }
  .u-pyb8-md {
    padding-bottom: 48px; }
  .u-plb8-md {
    padding-left: 48px; }
  .u-pxb8-md {
    padding-left: 48px; }
  .u-ptb9-md {
    padding-top: 54px; }
  .u-pyb9-md {
    padding-top: 54px; }
  .u-prb9-md {
    padding-right: 54px; }
  .u-pxb9-md {
    padding-right: 54px; }
  .u-pbb9-md {
    padding-bottom: 54px; }
  .u-pyb9-md {
    padding-bottom: 54px; }
  .u-plb9-md {
    padding-left: 54px; }
  .u-pxb9-md {
    padding-left: 54px; }
  .u-ptb10-md {
    padding-top: 60px; }
  .u-pyb10-md {
    padding-top: 60px; }
  .u-prb10-md {
    padding-right: 60px; }
  .u-pxb10-md {
    padding-right: 60px; }
  .u-pbb10-md {
    padding-bottom: 60px; }
  .u-pyb10-md {
    padding-bottom: 60px; }
  .u-plb10-md {
    padding-left: 60px; }
  .u-pxb10-md {
    padding-left: 60px; }
  .u-mra-md {
    margin-right: auto; }
  .u-mxa-md {
    margin-right: auto; }
  .u-mla-md {
    margin-left: auto; }
  .u-mxa-md {
    margin-left: auto; } }

@media (min-width: 1200px) {
  .u-m0-lg {
    margin: 0px; }
  .u-mtb0-lg {
    margin-top: 0px; }
  .u-myb0-lg {
    margin-top: 0px; }
  .u-mrb0-lg {
    margin-right: 0px; }
  .u-mxb0-lg {
    margin-right: 0px; }
  .u-mbb0-lg {
    margin-bottom: 0px; }
  .u-myb0-lg {
    margin-bottom: 0px; }
  .u-mlb0-lg {
    margin-left: 0px; }
  .u-mxb0-lg {
    margin-left: 0px; }
  .u-mtb1-lg {
    margin-top: 6px; }
  .u-myb1-lg {
    margin-top: 6px; }
  .u-mrb1-lg {
    margin-right: 6px; }
  .u-mxb1-lg {
    margin-right: 6px; }
  .u-mbb1-lg {
    margin-bottom: 6px; }
  .u-myb1-lg {
    margin-bottom: 6px; }
  .u-mlb1-lg {
    margin-left: 6px; }
  .u-mxb1-lg {
    margin-left: 6px; }
  .u-mtb2-lg {
    margin-top: 12px; }
  .u-myb2-lg {
    margin-top: 12px; }
  .u-mrb2-lg {
    margin-right: 12px; }
  .u-mxb2-lg {
    margin-right: 12px; }
  .u-mbb2-lg {
    margin-bottom: 12px; }
  .u-myb2-lg {
    margin-bottom: 12px; }
  .u-mlb2-lg {
    margin-left: 12px; }
  .u-mxb2-lg {
    margin-left: 12px; }
  .u-mtb3-lg {
    margin-top: 18px; }
  .u-myb3-lg {
    margin-top: 18px; }
  .u-mrb3-lg {
    margin-right: 18px; }
  .u-mxb3-lg {
    margin-right: 18px; }
  .u-mbb3-lg {
    margin-bottom: 18px; }
  .u-myb3-lg {
    margin-bottom: 18px; }
  .u-mlb3-lg {
    margin-left: 18px; }
  .u-mxb3-lg {
    margin-left: 18px; }
  .u-mtb4-lg {
    margin-top: 24px; }
  .u-myb4-lg {
    margin-top: 24px; }
  .u-mrb4-lg {
    margin-right: 24px; }
  .u-mxb4-lg {
    margin-right: 24px; }
  .u-mbb4-lg {
    margin-bottom: 24px; }
  .u-myb4-lg {
    margin-bottom: 24px; }
  .u-mlb4-lg {
    margin-left: 24px; }
  .u-mxb4-lg {
    margin-left: 24px; }
  .u-mtb5-lg {
    margin-top: 30px; }
  .u-myb5-lg {
    margin-top: 30px; }
  .u-mrb5-lg {
    margin-right: 30px; }
  .u-mxb5-lg {
    margin-right: 30px; }
  .u-mbb5-lg {
    margin-bottom: 30px; }
  .u-myb5-lg {
    margin-bottom: 30px; }
  .u-mlb5-lg {
    margin-left: 30px; }
  .u-mxb5-lg {
    margin-left: 30px; }
  .u-mtb6-lg {
    margin-top: 36px; }
  .u-myb6-lg {
    margin-top: 36px; }
  .u-mrb6-lg {
    margin-right: 36px; }
  .u-mxb6-lg {
    margin-right: 36px; }
  .u-mbb6-lg {
    margin-bottom: 36px; }
  .u-myb6-lg {
    margin-bottom: 36px; }
  .u-mlb6-lg {
    margin-left: 36px; }
  .u-mxb6-lg {
    margin-left: 36px; }
  .u-mtb7-lg {
    margin-top: 42px; }
  .u-myb7-lg {
    margin-top: 42px; }
  .u-mrb7-lg {
    margin-right: 42px; }
  .u-mxb7-lg {
    margin-right: 42px; }
  .u-mbb7-lg {
    margin-bottom: 42px; }
  .u-myb7-lg {
    margin-bottom: 42px; }
  .u-mlb7-lg {
    margin-left: 42px; }
  .u-mxb7-lg {
    margin-left: 42px; }
  .u-mtb8-lg {
    margin-top: 48px; }
  .u-myb8-lg {
    margin-top: 48px; }
  .u-mrb8-lg {
    margin-right: 48px; }
  .u-mxb8-lg {
    margin-right: 48px; }
  .u-mbb8-lg {
    margin-bottom: 48px; }
  .u-myb8-lg {
    margin-bottom: 48px; }
  .u-mlb8-lg {
    margin-left: 48px; }
  .u-mxb8-lg {
    margin-left: 48px; }
  .u-mtb9-lg {
    margin-top: 54px; }
  .u-myb9-lg {
    margin-top: 54px; }
  .u-mrb9-lg {
    margin-right: 54px; }
  .u-mxb9-lg {
    margin-right: 54px; }
  .u-mbb9-lg {
    margin-bottom: 54px; }
  .u-myb9-lg {
    margin-bottom: 54px; }
  .u-mlb9-lg {
    margin-left: 54px; }
  .u-mxb9-lg {
    margin-left: 54px; }
  .u-mtb10-lg {
    margin-top: 60px; }
  .u-myb10-lg {
    margin-top: 60px; }
  .u-mrb10-lg {
    margin-right: 60px; }
  .u-mxb10-lg {
    margin-right: 60px; }
  .u-mbb10-lg {
    margin-bottom: 60px; }
  .u-myb10-lg {
    margin-bottom: 60px; }
  .u-mlb10-lg {
    margin-left: 60px; }
  .u-mxb10-lg {
    margin-left: 60px; }
  .u-p0-lg {
    padding: 0px; }
  .u-ptb0-lg {
    padding-top: 0px; }
  .u-pyb0-lg {
    padding-top: 0px; }
  .u-prb0-lg {
    padding-right: 0px; }
  .u-pxb0-lg {
    padding-right: 0px; }
  .u-pbb0-lg {
    padding-bottom: 0px; }
  .u-pyb0-lg {
    padding-bottom: 0px; }
  .u-plb0-lg {
    padding-left: 0px; }
  .u-pxb0-lg {
    padding-left: 0px; }
  .u-ptb1-lg {
    padding-top: 6px; }
  .u-pyb1-lg {
    padding-top: 6px; }
  .u-prb1-lg {
    padding-right: 6px; }
  .u-pxb1-lg {
    padding-right: 6px; }
  .u-pbb1-lg {
    padding-bottom: 6px; }
  .u-pyb1-lg {
    padding-bottom: 6px; }
  .u-plb1-lg {
    padding-left: 6px; }
  .u-pxb1-lg {
    padding-left: 6px; }
  .u-ptb2-lg {
    padding-top: 12px; }
  .u-pyb2-lg {
    padding-top: 12px; }
  .u-prb2-lg {
    padding-right: 12px; }
  .u-pxb2-lg {
    padding-right: 12px; }
  .u-pbb2-lg {
    padding-bottom: 12px; }
  .u-pyb2-lg {
    padding-bottom: 12px; }
  .u-plb2-lg {
    padding-left: 12px; }
  .u-pxb2-lg {
    padding-left: 12px; }
  .u-ptb3-lg {
    padding-top: 18px; }
  .u-pyb3-lg {
    padding-top: 18px; }
  .u-prb3-lg {
    padding-right: 18px; }
  .u-pxb3-lg {
    padding-right: 18px; }
  .u-pbb3-lg {
    padding-bottom: 18px; }
  .u-pyb3-lg {
    padding-bottom: 18px; }
  .u-plb3-lg {
    padding-left: 18px; }
  .u-pxb3-lg {
    padding-left: 18px; }
  .u-ptb4-lg {
    padding-top: 24px; }
  .u-pyb4-lg {
    padding-top: 24px; }
  .u-prb4-lg {
    padding-right: 24px; }
  .u-pxb4-lg {
    padding-right: 24px; }
  .u-pbb4-lg {
    padding-bottom: 24px; }
  .u-pyb4-lg {
    padding-bottom: 24px; }
  .u-plb4-lg {
    padding-left: 24px; }
  .u-pxb4-lg {
    padding-left: 24px; }
  .u-ptb5-lg {
    padding-top: 30px; }
  .u-pyb5-lg {
    padding-top: 30px; }
  .u-prb5-lg {
    padding-right: 30px; }
  .u-pxb5-lg {
    padding-right: 30px; }
  .u-pbb5-lg {
    padding-bottom: 30px; }
  .u-pyb5-lg {
    padding-bottom: 30px; }
  .u-plb5-lg {
    padding-left: 30px; }
  .u-pxb5-lg {
    padding-left: 30px; }
  .u-ptb6-lg {
    padding-top: 36px; }
  .u-pyb6-lg {
    padding-top: 36px; }
  .u-prb6-lg {
    padding-right: 36px; }
  .u-pxb6-lg {
    padding-right: 36px; }
  .u-pbb6-lg {
    padding-bottom: 36px; }
  .u-pyb6-lg {
    padding-bottom: 36px; }
  .u-plb6-lg {
    padding-left: 36px; }
  .u-pxb6-lg {
    padding-left: 36px; }
  .u-ptb7-lg {
    padding-top: 42px; }
  .u-pyb7-lg {
    padding-top: 42px; }
  .u-prb7-lg {
    padding-right: 42px; }
  .u-pxb7-lg {
    padding-right: 42px; }
  .u-pbb7-lg {
    padding-bottom: 42px; }
  .u-pyb7-lg {
    padding-bottom: 42px; }
  .u-plb7-lg {
    padding-left: 42px; }
  .u-pxb7-lg {
    padding-left: 42px; }
  .u-ptb8-lg {
    padding-top: 48px; }
  .u-pyb8-lg {
    padding-top: 48px; }
  .u-prb8-lg {
    padding-right: 48px; }
  .u-pxb8-lg {
    padding-right: 48px; }
  .u-pbb8-lg {
    padding-bottom: 48px; }
  .u-pyb8-lg {
    padding-bottom: 48px; }
  .u-plb8-lg {
    padding-left: 48px; }
  .u-pxb8-lg {
    padding-left: 48px; }
  .u-ptb9-lg {
    padding-top: 54px; }
  .u-pyb9-lg {
    padding-top: 54px; }
  .u-prb9-lg {
    padding-right: 54px; }
  .u-pxb9-lg {
    padding-right: 54px; }
  .u-pbb9-lg {
    padding-bottom: 54px; }
  .u-pyb9-lg {
    padding-bottom: 54px; }
  .u-plb9-lg {
    padding-left: 54px; }
  .u-pxb9-lg {
    padding-left: 54px; }
  .u-ptb10-lg {
    padding-top: 60px; }
  .u-pyb10-lg {
    padding-top: 60px; }
  .u-prb10-lg {
    padding-right: 60px; }
  .u-pxb10-lg {
    padding-right: 60px; }
  .u-pbb10-lg {
    padding-bottom: 60px; }
  .u-pyb10-lg {
    padding-bottom: 60px; }
  .u-plb10-lg {
    padding-left: 60px; }
  .u-pxb10-lg {
    padding-left: 60px; }
  .u-mra-lg {
    margin-right: auto; }
  .u-mxa-lg {
    margin-right: auto; }
  .u-mla-lg {
    margin-left: auto; }
  .u-mxa-lg {
    margin-left: auto; } }

.u-h100p {
  height: 100%; }

.u-h100vh {
  height: 100vh; }

.u-w100p {
  width: 100%; }

.u-maw100p {
  max-width: 100%; }

.u-mah100p {
  max-height: 100%; }

.u-mah100vh {
  max-height: 100vh; }

.u-mih100vh {
  min-height: 100vh; }

.u-bgc-t {
  background-color: transparent; }

.u-bgc-orange {
  background-color: #feefe2; }

.u-ovh {
  overflow: hidden; }

.u-ova {
  overflow: auto; }

.u-dtb--fixed {
  width: 100%;
  table-layout: fixed; }

.u-dn {
  display: none; }

.u-di {
  display: inline; }

.u-dib {
  display: inline-block; }

.u-db {
  display: block; }

.u-dtb {
  display: table; }

.u-dtr {
  display: table-row; }

.u-dtc {
  display: table-cell; }

.u-df {
  display: flex; }

.u-dif {
  display: inline-flex; }

.u-dg {
  display: grid; }

@media (min-width: 768px) {
  .u-dn-sm {
    display: none; }
  .u-di-sm {
    display: inline; }
  .u-dib-sm {
    display: inline-block; }
  .u-db-sm {
    display: block; }
  .u-dtb-sm {
    display: table; }
  .u-dtr-sm {
    display: table-row; }
  .u-dtc-sm {
    display: table-cell; }
  .u-df-sm {
    display: flex; }
  .u-dif-sm {
    display: inline-flex; }
  .u-dg-sm {
    display: grid; } }

@media (min-width: 992px) {
  .u-dn-md {
    display: none; }
  .u-di-md {
    display: inline; }
  .u-dib-md {
    display: inline-block; }
  .u-db-md {
    display: block; }
  .u-dtb-md {
    display: table; }
  .u-dtr-md {
    display: table-row; }
  .u-dtc-md {
    display: table-cell; }
  .u-df-md {
    display: flex; }
  .u-dif-md {
    display: inline-flex; }
  .u-dg-md {
    display: grid; } }

@media (min-width: 1200px) {
  .u-dn-lg {
    display: none; }
  .u-di-lg {
    display: inline; }
  .u-dib-lg {
    display: inline-block; }
  .u-db-lg {
    display: block; }
  .u-dtb-lg {
    display: table; }
  .u-dtr-lg {
    display: table-row; }
  .u-dtc-lg {
    display: table-cell; }
  .u-df-lg {
    display: flex; }
  .u-dif-lg {
    display: inline-flex; }
  .u-dg-lg {
    display: grid; } }

.u-vat {
  vertical-align: top; }

.u-vam {
  vertical-align: middle; }

.u-vab {
  vertical-align: bottom; }

.u-fll {
  float: left; }

.u-flr {
  float: right; }

.u-fln {
  float: none; }

.u-tar {
  text-align: right; }

.u-tac {
  text-align: center; }

.u-tal {
  text-align: left; }

.u-text-small {
  font-size: 14px; }

.u-lh1 {
  line-height: 1; }

.u-posa0 {
  position: absolute 0; }

.u-posf0 {
  position: fixed 0; }

.u-poss {
  position: static !important; }

.u-posr {
  position: relative !important; }

.u-posa {
  position: absolute !important; }

.u-posf {
  position: fixed !important; }

@media (min-width: 768px) {
  .u-poss-sm {
    position: static !important; }
  .u-posr-sm {
    position: relative !important; }
  .u-posa-sm {
    position: absolute !important; }
  .u-posf-sm {
    position: fixed !important; } }

@media (min-width: 992px) {
  .u-poss-md {
    position: static !important; }
  .u-posr-md {
    position: relative !important; }
  .u-posa-md {
    position: absolute !important; }
  .u-posf-md {
    position: fixed !important; } }

@media (min-width: 1200px) {
  .u-poss-lg {
    position: static !important; }
  .u-posr-lg {
    position: relative !important; }
  .u-posa-lg {
    position: absolute !important; }
  .u-posf-lg {
    position: fixed !important; } }

.u-l0 {
  left: 0; }

.u-la {
  left: auto; }

.u-r0 {
  right: 0; }

.u-ra {
  right: auto; }

.u-t0 {
  top: 0; }

.u-ta {
  top: auto; }

.u-b0 {
  bottom: 0; }

.u-ba {
  bottom: auto; }

.u-fw300 {
  font-weight: 300; }

.u-fw400 {
  font-weight: 400; }

.u-fw700 {
  font-weight: 700; }

.u-fsi {
  font-style: italic; }

.u-ttu {
  text-transform: uppercase; }

.u-ttc {
  text-transform: capitalize; }

.u-fx1 {
  flex: 1 1 auto; }

.u-fxg0 {
  flex-grow: 0; }

.u-fxg1 {
  flex-grow: 1; }

.u-fxs0 {
  flex-shrink: 0; }

.u-fxs1 {
  flex-shrink: 1; }

.u-fxb0 {
  flex-basis: 0%; }

.u-fxba {
  flex-basis: auto; }

.u-fxdc {
  flex-direction: column; }

.u-fxww {
  flex-wrap: wrap; }

.u-aib {
  align-items: baseline; }

.u-jcsb {
  justify-content: space-between; }

.u-jcfe {
  justify-content: flex-end; }

.u-z-1 {
  z-index: -1; }

.u-z0 {
  z-index: 0; }

.u-z1 {
  z-index: 1; }

.u-z2 {
  z-index: 2; }

.u-z3 {
  z-index: 3; }

.u-z4 {
  z-index: 4; }

.u-z5 {
  z-index: 5; }

.u-o0 {
  opacity: 0; }

.u-o1 {
  opacity: 1; }

.u-bgc-primary {
  background-color: #007bff; }

.u-c-primary {
  color: #007bff; }

.u-bdc-primary {
  border-color: #007bff; }

.u-bgc-secondary {
  background-color: #6c757d; }

.u-c-secondary {
  color: #6c757d; }

.u-bdc-secondary {
  border-color: #6c757d; }

.u-bgc-text {
  background-color: #212529; }

.u-c-text {
  color: #212529; }

.u-bdc-text {
  border-color: #212529; }

.u-bgc-highlight {
  background-color: #f8f9fa; }

.u-c-highlight {
  color: #f8f9fa; }

.u-bdc-highlight {
  border-color: #f8f9fa; }

.u-c-inherit {
  color: inherit; }

/* crit:end */
/* crit:start */
.u-bgimg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  page-break-inside: avoid; }
  @media print {
    .u-bgimg__backup {
      opacity: 1; } }

.u-embed {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 100%; }
  .u-embed__item,
  .u-embed iframe,
  .u-embed embed,
  .u-embed object,
  .u-embed video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }
  .u-embed--16by9 {
    padding-bottom: 56.25%; }
  .u-embed--4by3 {
    padding-bottom: 75%; }

@media (max-width: 767.98px) {
  .u-table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .u-table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .u-table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .u-table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .u-table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .u-table-responsive-lg > .table-bordered {
      border: 0; } }

.u-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .u-table-responsive > .table-bordered {
    border: 0; }

.u-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* crit */ }

.u-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* crit */ }

.u-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* crit */ }

.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; }

.u-b2 {
  border: 2px solid #444444; }
  .u-b2-orange {
    border: 2px solid #f47f15 !important; }

.u-bt2 {
  border-top: 2px solid #444444; }
  .u-bt2-orange {
    border-top: 2px solid #f47f15 !important; }

/* crit:end */
.o-breadcrumb .breadcrumb {
  background-color: #f4f4f4;
  border-radius: 0;
  border-bottom: 1px solid #d8d8d8; }

.o-btn {
  position: relative;
  border-radius: 2px;
  padding: .375rem 1rem;
  font-weight: 700;
  /* crit */
  transition: .3s; }
  .o-btn:hover, .o-btn:focus {
    color: #fff; }
    .o-btn:hover:before, .o-btn:focus:before {
      transform: none;
      opacity: 1; }
  .o-btn + .o-btn {
    margin-left: 8px; }
  .o-btn [class*=" g-icon-"],
  .o-btn [class^=g-icon-] {
    margin-right: 5px;
    vertical-align: middle;
    font-size: 18px;
    margin-top: -2px; }
  .o-btn--green {
    background-color: #99c81e;
    color: #2c2c2c;
    border: 0; }
    .o-btn--green:before {
      background-color: #779c17; }
    .o-btn--green:hover, .o-btn--green:focus {
      background-color: #779c17;
      color: #2c2c2c; }
  .o-btn--white {
    background-color: #ffffff;
    color: #2c2c2c;
    border: 1px solid #dedede;
    border-radius: 2px; }
    .o-btn--white:hover, .o-btn--white:focus {
      background-color: #99c81e;
      text-decoration: none;
      border-color: #99c81e;
      color: #2c2c2c; }
  .o-btn--blue {
    background-color: #0d2c54;
    color: #ffffff;
    border: 0;
    border-radius: 2px; }
    .o-btn--blue:hover, .o-btn--blue:focus {
      background-color: #061528;
      text-decoration: none;
      border-color: #99c81e;
      color: #ffffff; }
  .o-btn--orange {
    background-color: #f47f15;
    color: #2c2c2c;
    border: 0; }
    .o-btn--orange:before {
      background-color: #cc660a; }
    .o-btn--orange:hover, .o-btn--orange:focus {
      background-color: #cc660a;
      color: #2c2c2c; }
  .o-btn--red {
    background-color: #e4102a;
    color: #ffffff;
    border: 0;
    border-radius: 2px; }
    .o-btn--red:hover, .o-btn--red:focus {
      background-color: #b40d21;
      text-decoration: none;
      color: #ffffff; }
  .o-btn--black {
    background-color: #2c2c2c;
    color: #ffffff;
    border-radius: 2px; }
    .o-btn--black:hover, .o-btn--black:focus {
      background-color: #99c81e;
      text-decoration: none;
      color: #ffffff; }
  .o-btn--grey {
    background-color: #f4f4f4;
    border-radius: 2px; }
    .o-btn--grey:hover, .o-btn--grey:focus {
      background-color: #dbdada;
      text-decoration: none;
      color: #2c2c2c; }
  .o-btn--flat {
    background-color: #ffffff;
    color: #737373;
    border-radius: 2px; }
    .o-btn--flat:hover, .o-btn--flat:focus {
      background-color: #99c81e;
      text-decoration: none;
      color: #737373; }
  .o-btn--border {
    background-color: transparent;
    color: #2c2c2c;
    border: 1px solid #dedede;
    border-radius: 2px; }
    .o-btn--border:hover, .o-btn--border:focus {
      background-color: #99c81e;
      text-decoration: none;
      border-color: #99c81e;
      color: #2c2c2c; }
  .o-btn--icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 0;
    color: #737373; }
    .o-btn--icon span {
      line-height: 40px; }
    .o-btn--icon [class*=" g-icon-"],
    .o-btn--icon [class^=g-icon-] {
      margin-right: 0; }
    .o-btn--iconsmall {
      width: 32px;
      height: 32px;
      font-size: 18px;
      padding: 0;
      color: #737373; }
      .o-btn--iconsmall span {
        line-height: 32px; }
      .o-btn--iconsmall [class*=" g-icon-"],
      .o-btn--iconsmall [class^=g-icon-] {
        margin-right: 0; }
  .o-btn--small {
    padding: 0 4px; }
    .o-btn--small span {
      font-size: 20px;
      line-height: 0; }
  .o-btn--circle {
    border-radius: 100px; }

.o-collapselist {
  border-radius: 2px;
  border: 0;
  background-color: transparent;
  /* crit */ }
  .o-collapselist__item {
    margin-top: -1px;
    /* crit */ }
    .o-collapselist__item:first-child {
      border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }
    .o-collapselist__item:last-child {
      border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }
    .o-collapselist__item .card-header {
      background-color: #2c2c2c;
      padding-left: 15px;
      padding-right: 15px;
      color: #ffffff;
      border: 1px solid #2c2c2c;
      border-radius: 0;
      /* crit */ }
      .o-collapselist__item .card-header .o-collapselist__title-link {
        color: #ffffff; }
      .o-collapselist__item .card-header .o-collapselist__title .o-btn--grey {
        background-color: #444444;
        color: #ffffff !important; }
      .o-collapselist__item .card-header .o-collapselist__arrow {
        color: #99c81e; }
      .o-collapselist__item .card-header .o-collapselist__icon {
        border-right: 2px solid #444444; }
      .o-collapselist__item .card-header.collapsed {
        background-color: #fff;
        color: #2c2c2c;
        border: 1px solid #dedede;
        /* crit */ }
        .o-collapselist__item .card-header.collapsed .o-collapselist__title {
          color: #2c2c2c; }
          .o-collapselist__item .card-header.collapsed .o-collapselist__title-link {
            color: #2c2c2c; }
        .o-collapselist__item .card-header.collapsed .o-collapselist__icon {
          border-right: 2px solid #f4f4f4; }
    .o-collapselist__item .card-body {
      padding: 32px;
      background-color: #ffffff;
      border-radius: 4px;
      /* crit */ }
    .o-collapselist__item.no-border {
      box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.18); }
      .o-collapselist__item.no-border .card-header {
        border: 0; }
    .o-collapselist__item.active .card-header {
      background-color: #2c2c2c; }
      .o-collapselist__item.active .card-header .o-collapselist__title-link {
        color: #ffffff; }
      .o-collapselist__item.active .card-header .o-btn--grey {
        background-color: #444444;
        color: #ffffff !important; }
      .o-collapselist__item.active .card-header .o-collapselist__icon {
        border-right: 2px solid #444444; }
    .o-collapselist__item .collapse,
    .o-collapselist__item .collapsing {
      box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.22);
      margin-bottom: 16px; }
    .o-collapselist__item .o-collapselist {
      margin-bottom: 32px; }
      .o-collapselist__item .o-collapselist .collapse,
      .o-collapselist__item .o-collapselist .collapsing {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
        margin-bottom: 32px; }
  .o-collapselist__title-link {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 1.625;
    color: #2c2c2c;
    font-weight: 500;
    display: inline-block;
    max-width: 395px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* crit */
    transition: .3s; }
    @media (max-width: 767px) {
      .o-collapselist__title-link {
        max-width: 100%; } }
  .o-collapselist__title .btn span {
    font-size: 20px; }
  .o-collapselist__title .btn + .btn {
    margin-left: 10px; }
  .o-collapselist__icon {
    color: #99c81e;
    vertical-align: middle;
    font-size: 48px;
    line-height: 0.0416666667;
    border-right: 2px solid #f4f4f4;
    margin-right: 10px;
    padding-right: 12px;
    display: inline-block;
    float: left;
    /* crit */ }
  .o-collapselist__arrow {
    color: #99c81e;
    font-size: 32px;
    outline: none;
    height: 40px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    /* crit */
    transition: .3s; }
    .o-collapselist__arrow:hover, .o-collapselist__arrow:focus, .o-collapselist__arrow:active {
      background-color: transparent;
      outline: none;
      box-shadow: none;
      color: #99c81e; }
  .o-collapselist__media-inner {
    padding-bottom: 66.9833729216%; }
  .o-collapselist__body {
    padding: 0 24px 60px; }

/* ON/OFF Switch */
/* crit:start */
.onoffswitch {
  position: relative;
  width: 72px;
  display: inline-block;
  font-weight: 700; }
  .onoffswitch .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    margin: 0; }
  .onoffswitch .onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -webkit-transition: margin 0.15s ease-in-out;
    -o-transition: margin 0.15s ease-in-out;
    -moz-transition: margin 0.15s ease-in-out;
    transition: margin 0.15s ease-in-out; }
    .onoffswitch .onoffswitch-inner:before, .onoffswitch .onoffswitch-inner:after {
      float: left;
      width: 50%;
      height: 36px;
      padding: 0;
      line-height: 36px;
      font-size: 80%;
      color: #ffffff;
      font-weight: normal;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
    .onoffswitch .onoffswitch-inner:before {
      content: "YES";
      padding-left: 10px;
      background-color: #99c81e;
      color: #2c2c2c;
      font-weight: 700; }
    .onoffswitch .onoffswitch-inner:after {
      content: "NO";
      padding-right: 15px;
      background-color: #eeeeee;
      color: #2c2c2c;
      text-align: right;
      font-weight: 700; }
  .onoffswitch .onoffswitch-switch {
    width: 32px;
    height: 32px;
    margin: 0;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 45px;
    -webkit-transition: right 0.15s ease-in-out;
    -o-transition: right 0.15s ease-in-out;
    -moz-transition: right 0.15s ease-in-out;
    transition: right 0.15s ease-in-out; }

.toggle-group {
  position: relative;
  height: 37px;
  top: 50%;
  left: 20px;
  margin-top: -18.5px; }
  .toggle-group input[type=checkbox] {
    position: absolute;
    left: 30px; }
    .toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
      margin-left: 0; }
    .toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
      right: 1px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); }
    .toggle-group input[type=checkbox]:focus ~ .onoffswitch {
      outline: thin dotted #333;
      outline: 0; }
  .toggle-group label {
    position: absolute;
    cursor: pointer;
    padding-left: 82px;
    display: inline-block;
    text-align: left;
    line-height: 36px;
    width: 100%;
    z-index: 1;
    height: 36px;
    font-weight: 200; }

/* crit:end */
/* ==== Accessibility ===== */
.aural {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px; }
  .aural:focus {
    clip: rect(0, 0, 0, 0);
    font-size: 1em;
    height: auto;
    outline: thin dotted;
    position: static !important;
    width: auto;
    overflow: visible; }

.o-gotop {
  width: 56px;
  height: 56px;
  transition: .3s;
  color: #fff; }
  .o-gotop:hover, .o-gotop:focus {
    background-color: grey;
    color: #fff; }
  .o-gotop__icon {
    line-height: 56px; }

.o-panel {
  padding: 24px;
  border: 1px solid #dedede;
  background-color: #fafafa;
  /*crit*/ }
  .o-panel--list li.list-group-item {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-color: #dedede;
    padding-top: 8px;
    padding-bottom: 8px; }
  .o-panel--list-title {
    line-height: 2em; }
    .o-panel--list-title [class*=' g-icon-'],
    .o-panel--list-title [class^=g-icon] {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 8px;
      color: #737373;
      margin-top: -4px; }
    .o-panel--list-title .o-btn span {
      margin-right: 0; }
    .o-panel--list-title .g-icon-chev-right {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -2px; }
    .o-panel--list-title .g-icon-info {
      color: #e4102a;
      transform: rotate(180deg);
      margin-left: 10px;
      margin-top: -2px; }
  .o-panel--list-action .dd1,
  .o-panel--list-action .dd2 {
    position: absolute; }
  .o-panel--list-action .dd1 {
    right: 50px; }
  .o-panel--list-action .dd2 {
    right: 15px; }

.o-richtext > *:last-child:not(.lead):not(.b-lead) {
  margin-bottom: 0;
  padding-bottom: 0; }

.o-richtext h1, .o-richtext h2, .o-richtext h3, .o-richtext h4, .o-richtext h5, .o-richtext h6 {
  margin-bottom: 12px; }
  .o-richtext h1:not(:first-child), .o-richtext h2:not(:first-child), .o-richtext h3:not(:first-child), .o-richtext h4:not(:first-child), .o-richtext h5:not(:first-child), .o-richtext h6:not(:first-child) {
    margin-top: 12px; }

.o-richtext ul, .o-richtext ol {
  padding-left: 0;
  margin-bottom: 30px; }

.o-richtext ul {
  list-style: none; }
  .o-richtext ul li {
    position: relative;
    padding-left: 13px; }
    .o-richtext ul li:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 4px;
      height: 4px;
      background-color: #000;
      left: 0; }

.o-richtext a {
  text-decoration: underline; }

.o-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease; }
  .o-slide.slick-initialized {
    visibility: visible;
    opacity: 1; }
  .o-slide--files .slick-arrow {
    display: none !important; }
  .o-slide--files-nav {
    border: 1px solid #eeeeee;
    background-color: #fff;
    display: inline-block;
    float: right;
    margin-top: 20px;
    border-radius: 2px; }
    .o-slide--files-nav .pagination {
      margin-bottom: 0; }
      .o-slide--files-nav .pagination .page-item .page-link {
        border: 0;
        color: #2c2c2c;
        font-size: 22px;
        margin-top: 4px; }
        .o-slide--files-nav .pagination .page-item .page-link:hover, .o-slide--files-nav .pagination .page-item .page-link:focus {
          background-color: #fff;
          color: #99c81e; }
      .o-slide--files-nav .pagination .page-item .o-slide--files-name {
        font-weight: 500;
        font-size: 22px;
        padding-left: 0;
        padding-right: 0;
        margin-top: 2px; }
      .o-slide--files-nav .pagination .page-item.disabled .page-link {
        color: #dedede; }
      .o-slide--files-nav .pagination .page-item.disabled .o-slide--files-name {
        color: #2c2c2c; }

.o-socialstream__item a {
  color: inherit; }

.o-socialstream__itemicon {
  font-size: 24px; }
  .o-socialstream__itemicon.o-socialstream__itemicon:hover, .o-socialstream__itemicon.o-socialstream__itemicon:focus {
    color: #f8f9fa; }

.o-socialstream__item-inner {
  transition: .3s; }
  .o-socialstream__item--youtube:hover .o-socialstream__item-inner,
  .o-socialstream__item--youtube:focus .o-socialstream__item-inner {
    transform: scale(1.1); }

.o-socialstream__filter-item:last-child {
  margin-right: 0; }

.o-socialstream__filter-indicator {
  transition: .3s;
  height: 3px; }
  .active .o-socialstream__filter-indicator {
    opacity: 1; }

@media (max-width: 767.98px) {
  .o-socialstream__filter-btn {
    width: 48px;
    height: 48px; } }

@media (max-width: 767.98px) {
  .o-socialstream__filter-btn-icon {
    font-size: 24px;
    text-align: center;
    margin-right: 0; } }

@media (max-width: 767.98px) {
  .o-socialstream__filter-btn--all .o-socialstream__filter-btn-icon {
    display: none; } }

.o-socialstream__filter-btn--all .o-socialstream__filter-btn-text {
  display: inline-block !important; }

.o-socialstream__content-inner {
  margin-left: -12px;
  margin-right: -12px;
  max-height: calc( ( 100vw - (30px * 2) + 24px) * 6); }

.o-socialstream__col {
  padding-left: 12px;
  padding-right: 12px; }

.o-tabs__nav-link {
  padding: 15px 18px;
  /* crit */
  transition: background-color .3s; }
  .o-tabs__nav-link.active {
    border-bottom-color: transparent;
    background-color: #eee; }
    .o-tabs__nav-link.active :after {
      content: none;
      /* crit */ }
    .o-tabs__nav-link.active:before {
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      left: 0;
      height: 3px;
      background-color: #000;
      display: block;
      /* crit */ }
    .o-tabs__nav-link.active:first-child:before {
      left: -1px;
      /* crit */ }
    .o-tabs__nav-link.active:first-child {
      border-left-color: #eee; }
    .o-tabs__nav-link.active:after {
      content: none; }
    .o-tabs__nav-link.active:last-child:after {
      content: '';
      position: absolute 0 -1px 0 auto;
      width: 1px;
      background-color: #eee; }
  @media (max-width: 767.98px) {
    .o-tabs__nav-link {
      padding-left: 20px;
      padding-right: 20px; } }

.o-tabs__pane:not(.active) {
  display: none; }

.o-tabs__media {
  width: 356px; }
  @media (max-width: 991.98px) {
    .o-tabs__media {
      width: auto;
      display: block;
      margin-bottom: 24px; } }

@media (max-width: 991.98px) {
  .o-tabs__text {
    display: block; } }

.o-tabs__img {
  padding-bottom: 66.9833729216%; }

.o-tabs__content {
  border-top: 0;
  background-color: #eee;
  /* crit */ }
  @media (max-width: 767.98px) {
    .o-tabs__content {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 991.98px) {
    .o-tabs__content-table {
      display: block; } }

.o-tabs__btn + .o-tabs__btn {
  margin-top: 24px; }

/* crit:start */
.c-footer {
  padding: 15px 0;
  background-color: #444444;
  color: #ffffff; }
  .c-footer .nav > li {
    font-size: 12px;
    line-height: 20px; }
  .c-footer .nav > li > a {
    color: #ffffff; }
    .c-footer .nav > li > a:hover {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.6); }
  @media (max-width: 991.98px) {
    .c-footer .nav {
      flex-direction: column-reverse !important; } }

/* crit:end */
/* crit:start */
.form-control {
  border-radius: 2px;
  color: #2c2c2c; }
  .form-control.input-validation-error {
    border-color: #e4102a; }

.form-group label {
  font-size: 14px;
  font-weight: 700;
  display: block; }

.dropdown-menu {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  font-size: 14px;
  border-radius: 4px;
  border: 0; }

.dropdown-item {
  padding: 5px 16px; }

.custom-control {
  padding-left: 2rem;
  padding-top: 8px; }
  .custom-control-label {
    font-weight: 400 !important; }
    .custom-control-label:before, .custom-control-label:after {
      width: 24px;
      height: 24px;
      border-radius: 2px;
      left: -2rem;
      top: 0px; }
    .custom-control-label:before {
      background-color: #ffffff;
      border: 2px solid #dedede; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #99c81e;
  border-color: #99c81e;
  /*crit*/ }

.custom-checkbox .custom-control-label:before {
  border-radius: 2px; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #ffffff;
  /*crit*/ }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='4' fill='%2399c81e'/%3E%3C/svg%3E");
  background-size: 70%;
  /*crit*/ }

.c-form {
  padding: 40px;
  box-shadow: 0 0 4px 4px #f2f2f2; }
  .c-form .o-btn {
    min-width: 140px; }
  .c-form .form-label {
    line-height: 45px;
    font-weight: 400; }
    .c-form .form-label__forgot {
      font-size: 14px; }
  .c-form .col-xs-1, .c-form .col-xs-2, .c-form .col-xs-3, .c-form .col-xs-4, .c-form .col-xs-5, .c-form .col-xs-6, .c-form .col-xs-7, .c-form .col-xs-8, .c-form .col-xs-9, .c-form .col-xs-10, .c-form .col-xs-11, .c-form .col-xs-12, .c-form .col-sm-1, .c-form .col-sm-2, .c-form .col-sm-3, .c-form .col-sm-4, .c-form .col-sm-5, .c-form .col-sm-6, .c-form .col-sm-7, .c-form .col-sm-8, .c-form .col-sm-9, .c-form .col-sm-10, .c-form .col-sm-11, .c-form .col-sm-12, .c-form .col-md-1, .c-form .col-md-2, .c-form .col-md-3, .c-form .col-md-4, .c-form .col-md-5, .c-form .col-md-6, .c-form .col-md-7, .c-form .col-md-8, .c-form .col-md-9, .c-form .col-md-10, .c-form .col-md-11, .c-form .col-md-12, .c-form .col-lg-1, .c-form .col-lg-2, .c-form .col-lg-3, .c-form .col-lg-4, .c-form .col-lg-5, .c-form .col-lg-6, .c-form .col-lg-7, .c-form .col-lg-8, .c-form .col-lg-9, .c-form .col-lg-10, .c-form .col-lg-11, .c-form .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px; }
  .c-form .form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0; }
  .c-form--tags {
    position: relative;
    border: 1px solid #dedede;
    border-radius: 2px; }
    .c-form--tags .input-group-append {
      padding: 5px;
      background-color: #ffffff; }
      .c-form--tags .input-group-append .o-btn--circle {
        width: 24px;
        height: 24px;
        line-height: 24px;
        background-color: #dedede;
        color: #2c2c2c;
        text-align: center;
        padding: 0;
        border-radius: 40px !important; }
  .c-form .form-group__description {
    font-style: italic; }

.alert {
  padding: 1em 0;
  margin-bottom: 0;
  font-size: 18px;
  color: #2c2c2c; }
  .alert-warning {
    background: #fef3e8;
    border-bottom: 2px solid #f47f15; }
    .alert-warning .g-icon-info {
      font-size: 28px;
      color: #f47f15;
      vertical-align: middle;
      margin-right: 10px; }
    .alert-warning .o-btn--orange {
      background-color: rgba(244, 127, 21, 0.2); }
      .alert-warning .o-btn--orange:hover, .alert-warning .o-btn--orange:focus {
        background: #ffffff;
        box-shadow: 0 2px 2px 0 rgba(244, 127, 21, 0.5); }
    .alert-warning .o-btn--white {
      border: 0;
      box-shadow: 0 2px 2px 0 rgba(244, 127, 21, 0.5); }
      .alert-warning .o-btn--white:hover, .alert-warning .o-btn--white:focus {
        background: #f47f15; }

.text-danger {
  color: #e4102a; }

.validation-summary-errors {
  font-size: 12px; }
  .validation-summary-errors ul {
    list-style: none;
    margin: 10px 0;
    padding: 0; }
    .validation-summary-errors ul li {
      list-style: none;
      margin: 0;
      padding: 0; }

.field-validation-error {
  font-size: 12px;
  color: #e4102a; }

.hasDatepicker {
  background-image: url(../img/calendar.svg);
  background-repeat: no-repeat;
  background-position: 98.5%; }

.label {
  display: inline;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  margin-right: 4px;
  min-height: 24px; }
  .label-info {
    background-color: #99c81e;
    color: #ffffff; }
  .label-green {
    background-color: #99c81e;
    color: #2c2c2c; }
  .label-grey {
    background-color: #dedede;
    color: #2c2c2c !important; }
  .label-orange {
    background-color: #f47f15;
    color: #2c2c2c !important; }

/* crit:end */
/* crit:start */
.c-header {
  padding: 15px 0;
  background-color: #fff; }
  .c-header__logo {
    max-width: 100%; }
    @media (max-width: 991.98px) {
      .c-header__logo {
        max-width: 80px; } }
  .c-header .btn-link {
    color: #444444; }
  @media (min-width: 768px) {
    .c-header__nav #topnav {
      display: block !important; } }
  .c-header__nav #topnav .nav {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    margin-left: 15px; }
    .c-header__nav #topnav .nav .fa {
      margin-right: 10px; }
    .c-header__nav #topnav .nav > li.nav-item {
      padding-left: 16px;
      padding-right: 16px; }
      .c-header__nav #topnav .nav > li.nav-item > a {
        color: #2c2c2c;
        position: relative;
        padding-left: 0;
        padding-right: 0; }
        .c-header__nav #topnav .nav > li.nav-item > a:after {
          content: "";
          display: block;
          height: 4px;
          background-color: #99c81e;
          opacity: 0;
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          transition: .5s; }
        .c-header__nav #topnav .nav > li.nav-item > a:hover {
          background-color: transparent;
          color: #2c2c2c; }
          .c-header__nav #topnav .nav > li.nav-item > a:hover:after {
            opacity: 1; }
      .c-header__nav #topnav .nav > li.nav-item.active > a:after {
        opacity: 1; }
      .c-header__nav #topnav .nav > li.nav-item:only-child > a, .c-header__nav #topnav .nav > li.nav-item:last-child > a {
        border-right: 0; }
      .c-header__nav #topnav .nav > li.nav-item .o-btn--white {
        border: 1px solid #dedede !important;
        font-weight: 500;
        padding-left: 15px;
        padding-right: 15px; }
        .c-header__nav #topnav .nav > li.nav-item .o-btn--white:hover {
          background-color: #99c81e;
          text-decoration: none;
          border-color: #99c81e !important; }
          .c-header__nav #topnav .nav > li.nav-item .o-btn--white:hover:after {
            opacity: 0; }
    @media (max-width: 767.98px) {
      .c-header__nav #topnav .nav {
        flex-direction: column !important;
        justify-content: flex-start !important;
        margin-left: -15px;
        margin-top: 20px; }
        .c-header__nav #topnav .nav > li > a {
          padding-left: 15px;
          border-bottom: 1px solid #f4f4f4; }
          .c-header__nav #topnav .nav > li > a:after {
            width: 4px;
            height: 100%; } }
  .c-header__nav-main > li:first-child > a {
    border-left: 0; }
  .c-header .navbar-toggle {
    margin-right: 0;
    padding-right: 0; }
    .c-header .navbar-toggle .icon-bar {
      background-color: #444444; }

/* crit:end */
.c-innerpage {
  background-color: #eeeeee;
  border-top: 1px solid #dedede;
  /*crit*/ }

/* crit:start */
.c-module--modal.overlapse {
  z-index: 2000;
  background: #2c2c2c; }

.c-module--modal .modal-dialog {
  max-width: 920px; }

.c-module--modal .modal-content {
  border-radius: 0;
  border: 0;
  max-height: 90vh; }

.c-module--modal .modal-scroll {
  overflow-y: scroll; }

.c-module--modal .modal-title {
  font-size: 28px; }

.c-module--modal .modal-header, .c-module--modal .modal-body, .c-module--modal .modal-footer {
  padding: 32px 40px; }

.c-module--modal .modal-header {
  border-bottom: 0;
  padding: 24px 40px; }
  .c-module--modal .modal-header .o-btn--blue {
    right: 90px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px; }
    .c-module--modal .modal-header .o-btn--blue span {
      margin-top: -2px;
      margin-right: 8px;
      vertical-align: middle; }
  .c-module--modal .modal-header .close {
    padding-right: 0; }
    .c-module--modal .modal-header .close .g-icon-close {
      font-size: 45px; }

.c-module--modal .modal-body {
  border-top: 1px solid #d8d8d8; }

.c-module--modal .modal-footer {
  border-top: 0;
  padding-bottom: 40px; }

.c-module--modal .breadcrumb {
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 0;
  margin-bottom: 0;
  font-size: 14px; }
  .c-module--modal .breadcrumb-item.active {
    color: #2c2c2c; }

/* crit:end */
.overlapse-open {
  overflow: hidden; }
  .overlapse-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

/* crit:start */
.c-nav-main {
  margin-bottom: 1.5rem; }
  .c-nav-main__list {
    display: table;
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 50px; }
    .c-nav-main__list-item {
      display: table-cell; }
      .c-nav-main__list-item a {
        height: 100%;
        padding-right: 10px;
        padding-left: 10px;
        display: block; }
        .c-nav-main__list-item a:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 100%; }
      + .c-nav-main__list-item {
        border-left: 1px dotted #000; }
      .c-nav-main__list-item:at-least(5) {
        display: none; }

/* crit:end */
.t-grey {
  background-color: #eeeeee; }
