@import "../../node_modules/magnific-popup/src/css/main";

.white-popup-block{
	background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
}
.mfp{
	&-bg,
	&-wrap{
		z-index: 99999;
	}
}
.mfp-close-btn-in .mfp-close{
	font-size: 50px;
	top: 10px;
	right: 10px;
}