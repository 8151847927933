/* crit:start */


.input-group{
  >.bootstrap-tagsinput{
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    >input{
      border:0;
    }
  }
}


.bootstrap-tagsinput {
  background-color: #fff;
  
  display: inline-block;
  //padding: 3px 6px 7px 6px;
  padding: 0px 6px 0px;
  color: #555;
  vertical-align: middle;
  border-radius: 0;
  max-width: 100%;
  line-height: 22px;
  min-height: 36px;
  cursor: text;
  
  

}
.bootstrap-tagsinput input {
	border: none;
	box-shadow: none;
	outline: none;
	background-color: transparent;
	padding: 0 6px;
	margin: 0;
	width: auto !important;
	max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: $black;
  display:inline-block;
  margin-top:5px;
  font-weight: 500;
  line-height: 24px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  padding-left: 5px;
  margin-right: -4px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
  border-left: 1px solid rgba(255,255,255,0.5);
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  //content: "x";
  font-family: 'g-icons';
  content:"\EA07";
  padding: 0px 2px;
  display: inline-block;
  transform: rotate(45deg)
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
} 
/* crit:end */
