/* crit:start */
.c-module {
	$_this: &;
	// {c-module--modal}
	&--modal {
		&.overlapse {
			z-index: 2000;
			background: rgba($black, 10%);
		}

		.modal {
			&-dialog {
				max-width: 920px;
			}

			&-content {
				border-radius: 0;
				border: 0;
				max-height: 90vh;				
			}

			&-scroll {				
				overflow-y: scroll;
			}

			&-title {
				font-size: 28px;
			}

			&-header,
			&-body,
			&-footer {
				padding: 32px 40px;
			}

			&-header {
				border-bottom: 0;
				padding: 24px 40px;

				.o-btn--blue {
					right: 90px;
					padding-left: 15px;
					padding-right: 15px;
					margin-top: 5px;

					span {
						margin-top: -2px;
						margin-right: 8px;
						vertical-align: middle;
					}
				}

				.close {
					padding-right: 0;

					.g-icon-close {
						font-size: 45px;
					}
				}
			}

			&-body {
				border-top: 1px solid #d8d8d8;
			}

			&-footer {
				border-top: 0;
				padding-bottom: 40px;
			}
		}

		.breadcrumb {
			padding-left: 40px;
			padding-right: 40px;
			border-bottom: 0;
			margin-bottom: 0;
			font-size: 14px;

			&-item.active {
				color: $black;
			}
		}
	}
}
/* crit:end */

// fix modal over modal scrolling issue
.overlapse-open{
	overflow: hidden;
	.modal{
		overflow-x: hidden;
    	overflow-y: auto;
	}
}
