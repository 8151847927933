.o-collapselist {
	$_this: &; // {{ .o-collapselist__title }}
	$collapselist-toggle-size: 16px;
	$collapselist-toggle-thickness: 2px;

	border-radius: 2px; 
	border:0;
	background-color: transparent;
	/* crit */

	&__item{ 
		margin-top: -1px;
		/* crit */

		&:first-child{
			border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
		}
		&:last-child{
			border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
		}

		.card{ 
			&-header{
					background-color: $grey-900;
					padding-left: 15px;
					padding-right: 15px;
					color:$white;
					border: 1px solid $grey-900;
					border-radius:0;

					#{$_this}__title{ 
						&-link{
							color: $white;
						}
						.o-btn--grey{
							background-color: $grey-800;
							color:$white !important;
						}
						
					} 
					#{$_this}__arrow{
						color: $green;
					}
					#{$_this}__icon{
						border-right: 2px solid $grey-800;
					}
				/* crit */

				//inactive style
				&.collapsed{
					background-color: #fff;
					color:$black;
					border: 1px solid $grey-200;
					#{$_this}__title{
						color: $black; 
						&-link{
							color: $black;
						}
					}
					#{$_this}__icon{
						border-right: 2px solid $grey-100;
					}
					/* crit */
				}

			} 

			&-body{
				padding: 32px;
				background-color: $white;
				border-radius: 4px;
				/* crit */
			}
		}
		&.no-border{
			box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.18);
			.card-header{ border:0; }
		}

		&.active{
			.card-header{
				background-color: $grey-900; 
				#{$_this}__title{
					&-link{
						color: $white;
					}
				}
				.o-btn--grey{
					background-color: $grey-800;
					color:$white !important;
				}
				#{$_this}__icon{
					border-right: 2px solid $grey-800;
				}
			}
		}
		.collapse,
		.collapsing{
			box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.22);
			margin-bottom: 16px;
		}

		.o-collapselist{
			margin-bottom: 32px;
			.collapse,
			.collapsing{
				box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.18);
				margin-bottom: 32px;
			}

		}

	}

	&__title {
		&-link {
			padding-top: 16px;
			padding-bottom: 16px;
			@include fzlh(16px, 26px);
			color: $black;
			font-weight: 500;
			display: inline-block;
			max-width: 395px;
			white-space: nowrap;
			text-overflow:ellipsis;
			overflow:hidden;
			/* crit */
			transition: .3s;

			@media(max-width: 767px){
				max-width: 100%;
			} 
		}
		.btn{
			span{
				font-size: 20px;
			}
		}
		.btn + .btn{
			margin-left: 10px;
		}
	} // {{ .o-collapselist__icon }}
	&__icon {
		color: $green;
		vertical-align: middle;
		@include fzlh(48px, 2px);
		border-right: 2px solid $grey-100;
		margin-right: 10px;
		padding-right: 12px;
		display: inline-block;
		float:left;
		/* crit */
	}
	&__arrow {
		color: $green;
		font-size: 32px;
		outline:none;
		height: 40px;
		margin-left: 10px;
		display: inline-block;
		vertical-align:middle;
		/* crit */
		transition: .3s;
		&:hover,
		&:focus,
		&:active{
			background-color: transparent;
			outline:none;
			box-shadow: none;
			color: $green;
		}
	}
	&__media {
		@include mq(md) {
			width: 401px;
			margin-top: 0;
			margin-left: 60px;
		}
		&-inner {
			padding-bottom: percentage(282/421);
		}
	}
	&__content {
		@include mq(md) {
			display: flex;
		}
	} // {{ .o-collapselist__panel }}
	&__panel {}
	// {{ .o-collapselist__body }}
	&__body {
		padding: 0 24px 60px;
		@include mq(lg) {
			max-width: calc(100% - 54px);
		}
	}
}
