// Although the template is similar to FontAwesome,
// don't use it like .fa.fa-[icon-name].
// instead just use like v-icon-[icon-name]
// e.g. .v-icon-facebook (don't need .v-icon.v-icon-facebook)

// DON'T USE "icon" as a class. Adblock Plus apparently
// has a CSS rule to hide icon-[social-icons] elements.

/* crit:start */
@font-face {
  font-family: "g-icons";
  src: url('/www_shared/assets/fonts/g-icons.eot?1598881792');
  src: url('/www_shared/assets/fonts/g-icons.eot?1598881792#iefix') format('eot'),
    url('/www_shared/assets/fonts/g-icons.woff2?1598881792') format('woff2'),
    url('/www_shared/assets/fonts/g-icons.woff?1598881792') format('woff'),
    url('/www_shared/assets/fonts/g-icons.ttf?1598881792') format('truetype'),
    url('/www_shared/assets/fonts/g-icons.svg?1598881792#g-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconfont {
  //use !important to prevent issues with browser extensions that change fonts
  font-family: 'g-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="g-icon-"], [class*=" g-icon-"], .g-icon {
  //use !important to prevent issues with browser extensions that change fonts
  @include iconfont;
}


$icon-list: (

  add: "\EA01",

  calendar: "\EA02",

  chev-down: "\EA03",

  chev-left: "\EA04",

  chev-right: "\EA05",

  chev-up: "\EA06",

  close: "\EA07",

  delete: "\EA08",

  doc-excel: "\EA09",

  doc-invoice: "\EA0A",

  doc-multi: "\EA0B",

  doc-pdf: "\EA0C",

  doc-word: "\EA0D",

  document: "\EA0E",

  download: "\EA0F",

  edit: "\EA10",

  facebook: "\EA11",

  help: "\EA12",

  info: "\EA13",

  instagram: "\EA14",

  licensor-shared: "\EA15",

  licensor: "\EA16",

  marker: "\EA17",

  payment: "\EA18",

  phone: "\EA19",

  share: "\EA1A",

  tick: "\EA1B",

  user-shared: "\EA1C",

  user: "\EA1D",

);

@each $icon, $refKey in $icon-list{
  .g-icon-#{$icon}:before{
    content: $refKey;
  }
}
/* crit:end */

//usually this use on pseudo element
@mixin icon($iconName){
  @include iconfont;
  content: icon($iconName);
}