.o-slide{
	opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    
    &.slick-initialized {
	    visibility: visible;
	    opacity: 1;    
	}

	&--files{
		.slick-arrow{
			display: none !important;
		}
		&-nav{
			border:1px solid $grey-150;
			background-color: #fff;
			display:inline-block;
			float: right;
			margin-top: 20px;
			border-radius:2px;
			.pagination{
				margin-bottom:0; 
				.page-item{
					.page-link{
						border:0;
						color: $grey-900;
						font-size: 22px;
						margin-top:4px;
						&:hover,
						&:focus{
							background-color: #fff;
							color: $green;
						}
					}
					.o-slide--files-name{
						font-weight: 500;
						font-size: 22px;
						padding-left: 0;
						padding-right: 0;
						margin-top: 2px;
					}
					&.disabled{
						.page-link{
							color: $grey-200;
						}
						.o-slide--files-name{
							color: $grey-900;
						}
					} 
				}
			}
		}
	}



}