h1,h2,h3,h4,h5,h6{
	margin: 0;
}

h1{
	font-size: 40px;
}
h2{
font-size: 36px;	
}
h3{
	font-size: 30px;
}
h4{
	font-size: 28px;
}
h5{
	font-size: 24px;
}
h6{
	font-size: 18px;
}

// Mixin are from _tools.mixin.fontstyles.scss
.b-fs1{ @include fs1; }
.b-fs2{ @include fs2; }
.b-fs3{ @include fs3; }
.b-fs4{ @include fs4; }
.b-fs4{ @include fs4; }
.b-fs5{ @include fs5; }
.b-fs6{ @include fs6;}

.b-fsbtnsmall{ @include fsbtnsmall; }
.b-fsblockquote{ @include fsblockquote; }

.b-fsbodysmall{ @include fsbodysmall; }
.b-fsbodytiny{ @include fsbodytiny; }

p{
	margin-bottom: 30px;

	&:empty{
		display: none;
	}
}

.lead{
	@include fslead;
}

small, .small{
	@include fssmall;
}
.g-icon-close{
	transform: rotate(45deg);
}
.ui-datepicker .ui-datepicker-title select{
	margin:0 auto;
	padding:0;
	display: inline-block;
	font-weight: bold;
	font-size: 16px;
	border:0;
	background-color: transparent;
}