.o-panel{
	padding: 24px;
	border: 1px solid $grey-200;
	background-color: $grey-50;

	&--list{
		li.list-group-item{
			background-color: transparent;
			padding-left: 0;
			padding-right: 0;
			border-color: $grey-200;
			padding-top: 8px;
			padding-bottom: 8px;
		}
		&-title{
			line-height: 2em;
			[class*=' g-icon-'],
			[class^=g-icon]{
				font-size: 20px;
				vertical-align: middle;
				margin-right: 8px;
				color: $grey-500;
				margin-top: -4px;
			}
			.o-btn{
				span{
					margin-right: 0;
				}
			}
			.g-icon-chev-right{
				margin-left: 5px;
				margin-right: 5px;
				margin-top: -2px;
			}
			.g-icon-info{
				color: $red;
				transform: rotate(180deg);
				margin-left: 10px;
				margin-top: -2px;
			}
		}
		&-action{
			.dd1,
			.dd2{
				position: absolute;
			}
			.dd1{right: 50px;}
			.dd2{right: 15px;}
		}

	}
	/*crit*/

}
