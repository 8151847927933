/* crit:start */
.c-nav-main {
    margin-bottom: 1.5rem;
    //{{ .c-nav-main__list }}
    &__list {
        @include nav-list;
        height: 50px;
        &-item {
            @include nav-list-item;
            a {
                height: 100%;
                padding-right: 10px;
                padding-left: 10px;
                display: block;
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    height: 100%;
                }
            }
            +& {
                border-left: 1px dotted #000;
            }
            &:at-least(5) {
                display: none;
            }
        }
    }
}
/* crit:end */