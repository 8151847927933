/* crit:start */
//overide standar form
.form{
	&-control{
		border-radius:2px;
		color: $black;
		&.input-validation-error{
			border-color: $red;
		}
	}	
	&-group{
		label{
			font-size: 14px;
			font-weight: 700;
			display: block;
		}
	}
}

.dropdown{
	&-menu{
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
		font-size: 14px;
		border-radius: 4px;
		border:0;
	}
	&-item{
		padding: 5px 16px;
	}
}

.custom{
	$_this: &;
	&-control{
		padding-left: 2rem;
		padding-top: 8px;
		&-label{
			font-weight: 400 !important;
			&:before,
			&:after{
				width: 24px;
				height: 24px;
				border-radius: 2px;
				left: -2rem;
				top:0px;
			}
			&:before{
				background-color: $white;
				border:2px solid $grey-200;
			}
		} 
	}
	&-checkbox{
		#{$_this}-control-input{
			&:checked ~ #{$_this}-control-label:before{
				background-color: $primary;
				border-color: $primary;
				/*crit*/
			}
		}
		#{$_this}-control-label{
			&:before{
				border-radius: 2px;
			}
		}
	}
	&-radio{
		#{$_this}-control-input{
			&:checked ~ #{$_this}-control-label:before{
				background-color: $white; 
				/*crit*/
			}
			&:checked~.custom-control-label:after{
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='4' fill='%2399c81e'/%3E%3C/svg%3E");
				background-size: 70%;
				/*crit*/	
			}
		} 

	}
}


.c-form{
	padding: 40px;
	box-shadow: 0 0 4px 4px #f2f2f2;
	.o-btn{
		min-width: 140px;
	}

	.form{
		&-label{
			line-height: 45px;
			font-weight: 400;
			&__forgot{
				font-size: 14px;
			}
		}
	}

	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12{
		padding-left: 5px;
		padding-right: 5px;
	}

	.form-horizontal .form-group{
		margin-left: 0;
		margin-right: 0;
	}
	&--tags{
		position: relative;
		border: 1px solid $grey-200;
		border-radius: 2px;
		.input-group-append{
		    padding: 5px;
		    background-color: $white;
		    .o-btn--circle{
		      width: 24px;
		      height: 24px;
		      line-height: 24px;
		      background-color: $grey-200;
		      color: $black;
		      text-align: center;
		      padding:0;
		      border-radius: 40px !important;
		    }
		  }
	}
	.form-group__description{
		    font-style: italic;
	}
}

// alert
.alert{
	padding:1em 0;
	margin-bottom:0;
	font-size: 18px;
	color: $black;
	&-warning{
		background: #fef3e8;
		border-bottom:2px solid $orange;
		.g-icon-info{
			font-size: 28px;
			color: $orange;
			vertical-align: middle;
			margin-right: 10px;
		}
		.o-btn--orange{
			background-color: rgba(244, 127, 21, 0.2);
			&:hover,
			&:focus{
				background: $white;
				box-shadow: 0 2px 2px 0 rgba(244, 127, 21, 0.5);
			}
		}
		.o-btn--white{
			border:0;
			box-shadow: 0 2px 2px 0 rgba(244, 127, 21, 0.5);
			&:hover,
			&:focus{
				background: rgba($orange, 20%);
			}
		}
	}
} 
.text-danger{
	color: $red;
}
.validation-summary-errors{
	font-size: 12px;
	ul{
		list-style: none;
		margin:10px 0;
		padding:0;
		li{
			list-style: none;
			margin:0;
			padding:0;
		}
	}
}


.field-validation-error{
	font-size: 12px;
	color: $red;
}


.hasDatepicker{
	background-image: url(../img/calendar.svg);
	background-repeat: no-repeat;
    background-position: 98.5%;
}

.label{
      display: inline;
      padding: 0px 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 4px;
      margin-right: 4px;
      min-height: 24px;

      &-info{
        background-color: $primary;
        color: $white;
      }
      &-green{
        background-color: $primary;
        color: $black;
      }

      &-grey{
        background-color: $grey-200;
        color: $black !important;
      }
      &-orange{
        background-color: $orange;
        color: $black !important;
      }
 }
 /* crit:end */
