
/* crit:start */
.c-footer{
	padding: 15px 0;
	background-color: $text;
	color: $white;

	
	.nav{
		>li{
			font-size: 12px;
			line-height: 20px;
			p{
				@include mq(xs){
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
		>li>a{
			color: $white;

			&:hover{
				background-color: transparent;
				color: rgba($white, 0.6);
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse !important;
		}
	}
	
}
/* crit:end */