/* crit:start */
.c-header{
	padding: 15px 0;
	background-color: #fff;
	&__logo{
		max-width: 100%;
		@include media-breakpoint-down(sm) {
			max-width: 80px;
		}
	}
	.btn-link{
		color: $text;
	}
	&__nav{
		#topnav{
			@include media-breakpoint-up(sm) {
				display: block !important;
			}
		
			.nav{
				font-size: 16px; 
				font-family: 'Roboto';
				font-weight: 500; 
				margin-left: 15px;
				.fa{
					margin-right: 10px;
				}
				>li.nav-item{
					padding-left: 16px;
					padding-right: 16px;
				
					>a{
						color: $black;
						position: relative;
						padding-left: 0;
						padding-right: 0;
						&:after{
							content:"";
							display: block;
							height: 4px;
							background-color: $primary;
							opacity:0;
							position: absolute;
							width: 100%;
							bottom:0;
							left:0;
							transition: .5s;
						}
						&:hover{
							background-color: transparent;
							color: $black;
							&:after{
								opacity:1;
								@include mq(xs){
									opacity:0;
								}
							}
						} 
					}
					&.active{
						>a{
							&:after{
								opacity: 1;
							}
						}
					}
					&:only-child,
					&:last-child{
						>a{
							border-right:0;
						}
					}
					.o-btn--white{
						border: 1px solid $grey-200 !important;
						font-weight: 500;
						padding-left: 15px;
						padding-right: 15px;
						&:hover{
							background-color: $primary;
							text-decoration: none;
							border-color: $primary !important;
							&:after{
								opacity:0;
							}
						}
					}

				}

				@include media-breakpoint-down(xs) {
					flex-direction: column !important;
					justify-content: flex-start !important;
					margin-left: -15px;
					margin-top: 20px;
					
						>li>a{
							padding-left: 15px;
							border-bottom: 1px solid $grey-100;
							&:after{
								width: 4px;
								height: 100%;
							}
						}
					
				}
			}

		} 
		&-main{
			>li{
				&:first-child{
					>a{
						border-left:0;
					}
				}
			}
		}

		@include mq(xs){
			padding-left: 0;
			padding-right: 0;
			.nav{
				margin:0;
				>li{
					border-bottom: 1px solid $border;
					>a{
						border-left:0;
					}
				}
			}
		}


	}
	.navbar-toggle{
		margin-right: 0;
		padding-right: 0;
		.icon-bar{
			background-color: $text;
		}
	}
}
/* crit:end */