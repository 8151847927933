// list-unstyled mixin is used by bootstrap, so rename it to u-list-unstyled
@mixin u-list-unstyled{
	list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin nav-list-item{
	display: table-cell;
}

@mixin nav-list{
	display: table;
	margin: 0;
	padding: 0;
	list-style-type: none;
}